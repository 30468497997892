<template>
  <div class="container-fluid">
    <div class="input-element">
      <label>Email:</label>
      <input type="email" class="input-box-1" v-model="email" placeholder="email"/>
    </div>
    <div class="buttons">
      <button type="button" class="modified-button" id="cancel-button" @click="cancelModal">
        Cancel
      </button>
      <button type="button" class="modified-button" id="send-button" @click="send">
        Send
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

const email = ref("");

// methods
const send = async () => {
  store.commit("user/SET_GUEST_EMAIL", email.value);
  await store.dispatch("projects/insertProject");
  store.commit("user/SET_GUEST_EMAIL", null);
  store.dispatch("modal/closeModal");
  router.push("/library");
}
const cancelModal = () => {
  store.dispatch("modal/closeModal");
}

</script>

<style scoped lang="scss">
.input-element {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 60%;
  margin: auto;
}
.buttons {
  .modified-button {
    width: 40%;
    margin: 1em;
  }
}
#cancel-button {
  &:hover {
    background-color: var(--ce-cancel-button-color);
  }
}
#send-button {
  &:hover {
    background-color: var(--ce-submit-button-color);
  }
}
</style>