<template>
  <div id="media-container">
    <MediaGrid :videos="seasonalVideos" :type="'seasonal'"/>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import MediaGrid from "@/components/MediaGrid.vue";

const store = useStore();

const seasonalVideos = computed(() => store.getters["adminPopularSeasonalVideos/getSeasonalVideos"]);

if (seasonalVideos.value.length === 0) {
  store.dispatch("adminPopularSeasonalVideos/fetchSeasonalVideos");
}

</script>

<style scoped>
.media-container {

}
</style>
