<template>
  <div class="container-fluid">
    <div class="payment-detail">
      <!-- <hr :style="{'width': '50%', 'margin': '3em auto'}"/> -->
      <div class="input-element" id="card-information">
        <h5>Card Information</h5>
        <span>  
          <div class="input-box-1" id="card-number"></div>
          <span class="credit-card-icons">
            <icon class="icon" icon="fa-brands fa-cc-visa" />
            <icon class="icon" icon="fa-brands fa-cc-mastercard" />
            <icon class="icon" icon="fa-brands fa-cc-amex" />
            <icon class="icon" icon="fa-brands fa-cc-discover" />
          </span>
        </span>
        <div class="card-additional-information">
          <div class="expiration-date">
            <div type="text" class="input-box-1" id="expiration-date"></div>
          </div>
          <div class="cvc">
            <div class="input-box-1" id="cvv"></div>
            <icon class="icon cvc-icon" icon="credit-card" />
          </div>
        </div>
      </div>
      <!-- <div class="input-element" id="name-on-card">
        <h5>Name on Card</h5>
        <input type="text" class="input-box-1" />
      </div> -->
      <div class="input-element">
        <h5>Postal Code</h5>
        <div class="input-box-1" id="postal-code"></div>
      </div>
      <div class="input-element">
        <span>
          <input type="checkbox" class="checkbox" v-model="iAgree"/>
          I agree to the <a href="/terms">CreativeEngine Terms</a></span>
      </div>
      <BraintreeLogo />
      <div class="buttons">
        <button type="button" class="modified-button" id="cancel-button"
          @click="closeModal">
          Cancel
        </button>
        <button type="button" class="modified-button" id="pay-button"
          @click="updatePaymentMethod">
          Update
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import BraintreeLogo from "@/components/Account/Billing/BraintreeLogo.vue";
import client from "braintree-web/client";
import hostedFields from "braintree-web/hosted-fields";
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const clientToken = computed(() => store.getters["billing/getClientToken"]);

const showInvalidFeedback = ref(false);
const buttonIsDisabled = ref(false);
const hostedFieldsInstance = ref(null);
const iAgree = ref(false);

// method
const updatePaymentMethod = async () => {
  let formIsValid = false;
  let errorMessage;
  if (hostedFieldsInstance.value) {
    const state = hostedFieldsInstance.value.getState();
    formIsValid = Object.keys(state.fields).every(
      key => state.fields[key].isValid
    );
  }
  if (!iAgree.value) {
    errorMessage = "You must agree to the term."
  }
  if (!formIsValid) {
    errorMessage = "The information you entered is invalid. Please try again.";
  }
  if (!(formIsValid && iAgree.value)) {
    store.dispatch("alert/getAlert", {
      alert: "danger",
      message: errorMessage
    })
    return;
  }
  const { nonce } = await hostedFieldsInstance.value.tokenize();
  await store.dispatch("billing/updatePaymentMethod", {
    nonce: nonce
  })
  closeModal();
}
const closeModal = () => {
  store.dispatch("modal/closeModal");
}

// created
onMounted(async () => {
  await store.dispatch("billing/generateClientToken");
  const clientInstance = await client.create({
    authorization: clientToken.value
  });
  hostedFieldsInstance.value = await hostedFields.create({
    client: clientInstance,
    styles: {
      input: {
        "font-size": "14px"
      },
      "input.invalid": {
        color: "red"
      },
      "input.valid": {
        color: "green"
      }
    },
    fields: {
      number: {
        selector: "#card-number",
        placeholder: "ex: 1234 1234 1234 1234"
      },
      cvv: {
        selector: "#cvv",
        placeholder: "CVC"
      },
      expirationDate: {
        selector: "#expiration-date",
        placeholder: "MM/YYYY"
      },
      postalCode: {
        selector: "#postal-code",
        placeholder: "ex: 12345"
      }
    }
  });
  hostedFieldsInstance.value.on("validityChange", () => {
    showInvalidFeedback.value = false;
    buttonIsDisabled.value = false;
  })
})

</script>

<style scoped lang="scss">
.input-box-1 {
  height: 50px;
  widows: 100px;
}
.container-fluid {
  display: flex;
  flex-direction: column;
  .payment-detail {
    margin: auto;
  }
}
.input-element {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 70%;
  margin: 2em auto;
  #mm {
    width: 45%;
    margin-right: 1ex;
  }
  #yyyy {
    width: 45%;
  }
  .cvc {
    width: 45%;
    .cvc-icon {
      float: right;
      margin-right: 6px;
      margin-top: -45px;
      position: relative;
      z-index: 2;
    }
  }
  .card-additional-information {
    display: flex;
    justify-content: space-between;
  }
  .credit-card-icons {
    float: right;
    margin-right: 6px;
    margin-top: -45px;
    position: relative;
    z-index: 2;
  }
}
.modified-button {
  margin: 1em;
}
.icon {
  font-size: 3ex;
  margin: 0 3px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
#cancel-button {
  width: 40%;
  &:hover {
    background-color: var(--ce-cancel-button-color);
  }
}
#pay-button {
  width: 40%;
  &:hover {
    background-color: var(--ce-submit-button-color);
  }
}
</style>