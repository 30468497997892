import axios from "axios";
import FileDownload from "js-file-download";

const state = {
  stocks: [],
  categoryCount: {},
  keywordCount: {},
  totalNumberObjects: {
    "stock": 0
  },
  loadMoreObjects: {
    "stock": 6
  }
};

const getters = {
  getAllStocks: (state, getters, rootState, rootGetters) => {
    let temp = [];
    if (Object.keys(rootGetters['templates/getCategoryFilters']).length === 0) {
      temp = [...state.stocks];
    } else {
      state.stocks.forEach(stock => {
        for (let i=0; i<stock.categories.length; i++) {
          if (rootGetters['templates/getCategoryFilters'][stock.categories] === 0) {
            temp.push(stock);
            break;
          }
        }
      })
    }
    if (rootGetters['templates/getTemplateSearch'] !== '') {
      return temp.filter(t => {
        return t.name.toLowerCase().includes(rootGetters['templates/getTemplateSearch'].toLowerCase())
      });
    }
    return temp.slice(0, state.loadMoreObjects["stock"]);
  },
  getLoadMoreObjects: state => {
    return state.loadMoreObjects;
  },
  getTotalNumberObjects: state => {
    return state.totalNumberObjects;
  },
  getYouMightAlsoLikeStocks: (state, getters, rootState) => {
    let stocks = [];
    for (let i=0; i<state.stocks.length; i++) {
      for (let j=0; j<state.stocks[i].categories.length; j++) {
        if (rootState.templates.currentVideo.categories.includes(state.stocks[i].categories[j]) &&
        state.stocks[i].id !== rootState.templates.currentVideo.id) {
          stocks.push(state.stocks[i]);
          break;
        }
      }
      if (stocks.length > 7) {
        break;
      }
    }
    return stocks;
  }
}

const mutations = {
  SET_STOCKS(state, stocks) {
    state.stocks = stocks;
  },
  SET_CATEGORY_COUNT_FROM_STOCK(state, categoryCount) {
    state.categoryCount = categoryCount;
  },
  SET_KEYWORD_COUNT_FROM_STOCK(state, keywordCount) {
    state.keywordCount = keywordCount;
  },
  SET_LOAD_MORE_OBJECTS(state, context) {
    state.loadMoreObjects[context.name] = context.number;
  },
  SET_TOTAL_NUMBER_OBJECTS(state, context) {
    state.totalNumberObjects[context.name] = context.number;
  }
}

const actions = {
  async getStocks({ commit, dispatch }) {
    dispatch("alert/startLoading", {}, { root: true });
    const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/stock`);
    commit("SET_STOCKS", response.data.filter(x => x.name !== "Birthday!"));
    commit("SET_TOTAL_NUMBER_OBJECTS", {
      name: "stock",
      number: response.data.length
    });
    await dispatch("getCategoryKeywordCountFromStock");
    dispatch("alert/stopLoading", {}, { root: true });
  },
  async getCategoryKeywordCountFromStock({ state, commit, dispatch }) {
    dispatch("alert/startLoading", {}, { root: true });
    let categories = {}, keywords = {};
    state.stocks.forEach(item => {
      item.categories.forEach(category => {
        categories[category] = categories[category] + 1 || 1;
      });
      item.keywords.forEach(keyword => {
        keywords[keyword] = keywords[keyword] + 1 || 1;
      });
    });
    commit("SET_CATEGORY_COUNT_FROM_STOCK", categories);
    commit("SET_KEYWORD_COUNT_FROM_STOCK", keywords);
    dispatch("alert/stopLoading", {}, { root: true });
  },
  async downloadStock({ dispatch, rootState }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      let response = await axios({
        url: `${process.env.VUE_APP_SERVER_URL}/api/files/stock/${rootState.templates.currentVideo.s3_id}/downloadable/${context.resolution}.${context.format.toLowerCase()}`,
        method: 'GET',
        responseType: 'blob'
      });
      FileDownload(response.data, rootState.templates.currentVideo.name + '.' + context.format.toLowerCase());
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully downloaded stock."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
    
  },
  async pushStockToBanner({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      let response = await axios.post(`${process.env.VUE_APP_SERVER_URL}/api/stock/post-stock-download`, context, {
        withCredentials: true
      });

      dispatch("alert/getAlert", {
        alert: response.data.alert,
        message: response.data.message
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: error
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}