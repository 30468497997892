import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faPhone,
  faEnvelope,
  faList,
  faTrashCan,
  faXmark,
  faSort,
  faEllipsisV,
  faPen,
  faHatWizard,
  faLink,
  faCircleCheck,
  faTriangleExclamation,
  faCircleExclamation,
  faFolderPlus,
  faFileUpload,
  faDownload,
  faDirections,
  faTrash,
  faFolder,
  faImage,
  faImages,
  faVideo,
  faFile,
  faCheck,
  faToggleOn,
  faToggleOff,
  faCircleNotch,
  faPlayCircle,
  faCircle,
  faCirclePlay,
  faCopy,
  faShareNodes,
  faSpinner,
  faExclamation,
  faPenRuler,
  faCircleXmark,
  faCreditCard,
  faCaretDown,
  faInfo,
  faCaretUp,
  faSortUp,
  faSortDown,
  faPlus,
  faMinus,
  faPalette,
  faFont
} from "@fortawesome/free-solid-svg-icons";

import {
  faCircle as faRegCircle
} from "@fortawesome/free-regular-svg-icons";

import {
  faFacebook,
  faInstagramSquare,
  faLinkedin,
  faCcVisa,
  faCcMastercard,
  faCcAmex,
  faCcDiscover,
  faCcPaypal
} from "@fortawesome/free-brands-svg-icons"

library.add(
  faPhone,
  faEnvelope,
  faList,
  faFacebook,
  faInstagramSquare,
  faLinkedin,
  faTrashCan,
  faXmark,
  faSort,
  faEllipsisV,
  faPen,
  faHatWizard,
  faLink,
  faCircleCheck,
  faTriangleExclamation,
  faCircleExclamation,
  faFolderPlus,
  faFileUpload,
  faDownload,
  faDirections,
  faTrash,
  faFolder,
  faImage,
  faImages,
  faVideo,
  faFile,
  faCheck,
  faToggleOn,
  faToggleOff,
  faCircleNotch,
  faPlayCircle,
  faCircle,
  faRegCircle,
  faCirclePlay,
  faCopy,
  faShareNodes,
  faCcVisa,
  faCcMastercard,
  faCcAmex,
  faCcDiscover,
  faCcPaypal,
  faSpinner,
  faExclamation,
  faPenRuler,
  faCircleXmark,
  faCreditCard,
  faCaretDown,
  faInfo,
  faCaretUp,
  faSortUp,
  faSortDown,
  faPlus,
  faMinus,
  faPalette,
  faFont
);

export { FontAwesomeIcon };