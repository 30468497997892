<template>
  <div class="container-fluid">
    <div class="button-container">
      <button type="button" class="modified-button" id="manage-templates-button" @click="manageTemplates">
        Manage Templates
      </button>
    </div>
    <div v-if="popularVideos.length > 0" class="row row-col-6 grid-container">
      <div class="col-md-2" v-for="(video, index) in popularVideos" :key="index" >
        <ElementComponent :video="video" />
      </div>
    </div>
    <div class="empty" v-else>Empty</div>
  </div>
</template>

<script setup>
import ElementComponent from "@/components/Admin/AdminTabs/VideoTemplates/ElementComponent.vue";
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

// getters
const templates = computed(() => store.getters["adminTemplates/getTemplates"]);
const popularVideos = computed(() => store.getters["adminPopularSeasonalVideos/getPopularVideos"]);

// methods
const manageTemplates = () => {
  router.push("/admin/managetemplate");
}

// created
store.commit("adminPopularSeasonalVideos/SET_CURRENT_PAGE", "popular");
if (templates.value.length === 0) {
  store.dispatch("adminTemplates/getTemplates");
}
if (popularVideos.value.length === 0) {
  store.dispatch("adminPopularSeasonalVideos/fetchPopularVideos");
}

</script>

<style scoped lang="scss">
.button-container {
  display: flex;
}
#manage-templates-button {
  margin: 1em;
  width: 30%;
  &:hover {
    background-color: var(--ce-add-button-color);
  }
}
</style>