
const state = {
  alert: "",
  message: "",
  loading: false,
  showAlert: false,
  alertIcon: {
    "success": "circle-check",
    "warning": "triangle-exclamation",
    "danger": "circle-exclamation"
  },
  gotoAfterLoginRouter: "/",
  loadingNumber: 0,
  timeout: null,
  loadingTimeout: 20000,
  alertTime: 10000,
  refreshPageTimer: null
}

const getters = {
  getGotoAfterLoginRouter: state => {
    return state.gotoAfterLoginRouter;
  },
  getLoadingNumber: state => {
    return state.loadingNumber;
  }
}

const mutations = {
  SET_ALERT(state, alert) {
    state.alert = alert;
  },
  SET_ALERT_TIME(state, time) {
    state.alerttime = time;
  },
  SET_MESSAGE(state, message) {
    state.message = message;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SHOW_ALERT(state) {
    state.showAlert = true;
  },
  REMOVE_ALERT(state) {
    state.showAlert = false;
    clearTimeout(state.timeout);
  },
  SET_GOTO_AFTER_LOGIN_ROUTER(state, gotoAfterLoginRouter) {
    state.gotoAfterLoginRouter = gotoAfterLoginRouter;
  },
  SET_LOADING_NUMBER(state, number) {
    state.loadingNumber = number;
  },
  SET_TIMEOUT(state, timeout) {
    state.timeout = timeout;
  },
  SET_REFRESH_PAGE_TIMER(state, timer) {
    state.refreshPageTimer = timer;
  }
}

const actions = {
  getAlert({ state, commit }, { alert, message }) {
    clearTimeout(state.timeout);
    commit("SET_ALERT", alert);
    commit("SET_MESSAGE", message);
    commit("SHOW_ALERT");
    commit("SET_TIMEOUT", setTimeout(() => {
      commit("REMOVE_ALERT");
    }, state.alertTime))
  },
  startLoading({ dispatch, state, commit }) {
    commit("SET_ALERT_TIME", 10000);
    commit("SET_LOADING", true);
    clearTimeout(state.refreshPageTimer);
    commit("SET_REFRESH_PAGE_TIMER",
        setTimeout(() => {
          if (state.loadingNumber !== 0) {
            dispatch("getAlert", {
              alert: "warning",
              message: "Please refresh the page"
            })
          }
        }, state.loadingTimeout)
    )
    commit("SET_LOADING_NUMBER", state.loadingNumber + 1);
  },
  stopLoading({ state, commit }) {
    commit("SET_LOADING_NUMBER", state.loadingNumber - 1);
    if (state.loadingNumber === 0) {
      commit("SET_LOADING", false);
      clearTimeout(state.refreshPageTimer);
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}