import axios from "axios";

const state = () => ({
  templates: [],
  filterStatus: ["Testing", "Published"],
  availableTemplateStatus: {
    "Testing": {
      color: "yellow",
    },
    "Published": {
      color: "lightBlue",
    },
    "Retired": {
      color: "grey"
    },
    "Deleted": {
      color: "red"
    },
    "Convention": {
      color: "lightGreen"
    }
  },
  selectedTemplate: {},
  newTemplateStep: "VideosAndThumbnails",
  newUpdateTemplate: {
    isCanvas: false,
    example0TemplateThumbnail: null,
    example0TemplateVideo: null,
    example1TemplateThumbnail: null,
    example1TemplateVideo: null,
    example2TemplateThumbnail: null,
    example2TemplateVideo: null,
    example3TemplateThumbnail: null,
    example3TemplateVideo: null,
    example4TemplateThumbnail: null,
    example4TemplateVideo: null,
    name: "",
    s3_id: "",
    duration: 0,
    description: "",
    renderTime: 0,
    afterEffect: "",
    frameNumber: 0,
    categories: [],
    keywords: [],
    custom_fields: {
      colorFields: [],
      textFields: [],
      imageFields: [],
      palettes: []
    }
  },
  selectingFreeTemplates: false,
  newlySelectedFreeTemplateIds: [],
  s3Files: [],
  currentProcess: ""
  // newS3Id: null,
})

const getters = {
  getTemplates: state => {
    return state.templates;
  },
  getNewTemplateStep: state => {
    return state.newTemplateStep;
  },
  getSelectedTemplate: state => {
    return state.selectedTemplate;
  },
  getS3Files: state => {
    return state.s3Files;
  },
  getTemplatesItemsOrganized: state => {
    return state.templates.filter(item => {
      return state.filterStatus.includes(item.status) && item.name !== null;
    })
  },
  filterTemplates: state => (filter) => {
    return state.templates.filter(item => {
      return item.status === filter;
    })
  },
  getNewUpdateTemplate: state => {
    return state.newUpdateTemplate;
  },
  getSelectingFreeTemplates: state => {
    return state.selectingFreeTemplates;
  },
  getNewlySelectedFreeTemplateIds: state => {
    return state.newlySelectedFreeTemplateIds;
  },
  getCurrentProcess: state => {
    return state.currentProcess;
  }
}

const mutations = {
  SET_TEMPLATES(state, templates) {
    state.templates = templates;
  },
  SET_SELECTED_TEMPLATE(state, template) {
    state.selectedTemplate = template;
  },
  SET_S3_FILES(state, s3Files) {
    state.s3Files = s3Files;
  },
  // SET_NEW_S3_ID(state, s3Id) {
  //   state.newS3Id = s3Id;
  // },
  TOGGLE_FILTER(state, filter) {
    if (state.filterStatus.includes(filter)) {
      state.filterStatus.splice(state.filterStatus.indexOf(filter), 1);
      return;
    } 
    state.filterStatus.push(filter);
  },
  SET_CURRENT_PROCESS(state, process) {
    state.currentProcess = process;
  },
  SET_NEW_TEMPLATE_STEP(state, step) {
    state.newTemplateStep = step;
  },
  SET_NEW_UPDATE_TEMPLATE(state, template) {
    let temp = {...template};
    temp.categories = temp.categories.split(',');
    temp.keywords = temp.keywords.split(',');
    temp.example0TemplateThumbnail = null,
    temp.example0TemplateVideo = null,
    temp.example1TemplateThumbnail = null,
    temp.example1TemplateVideo = null,
    temp.example2TemplateThumbnail = null,
    temp.example2TemplateVideo = null,
    temp.example3TemplateThumbnail = null,
    temp.example3TemplateVideo = null,
    temp.example4TemplateThumbnail = null,
    temp.example4TemplateVideo = null,
    state.newUpdateTemplate = temp;

  } ,
  SET_NEW_UPDATE_TEMPLATE_BY_KEY(state, context) {
    if (context.key.split('.')[0] === "custom_fields") {
      state.newUpdateTemplate.custom_fields[context.key.split('.')[1]] = context.value;
      return;
    }
    state.newUpdateTemplate[context.key] = context.value;
  },
  SET_NEW_UPDATE_TEMPLATE_DETAIL(state, context) {
    if (context.key) {
      state.newUpdateTemplate.custom_fields[context.field][context.index][context.key] = context.value;
    } else {
      state.newUpdateTemplate.custom_fields[context.field][context.index] = context.value;
    }
  },
  SET_SELECTING_FREE_TEMPLATES(state, selectingFreeTemplates) {
    state.selectingFreeTemplates = selectingFreeTemplates;
  },
  SET_NEWLY_SELECTED_FREE_TEMPLATE_IDS(state, newlySelectedFreeTemplateIds) {
    state.newlySelectedFreeTemplateIds = newlySelectedFreeTemplateIds;
  },
  UPDATE_TEMPLATE(state, context) {
    for(let i=0; i<state.templates.length; i++) {
      if (state.templates[i].s3_id === context.s3Id) {
        state.templates[i][context.key] = context.value;
        break;
      }
    }
  },
  RESET_NEW_UPDATE_TEMPLATE(state) {
    state.newUpdateTemplate = {
      isCanvas: false,
      example0TemplateThumbnail: null,
      example0TemplateVideo: null,
      example1TemplateThumbnail: null,
      example1TemplateVideo: null,
      example2TemplateThumbnail: null,
      example2TemplateVideo: null,
      example3TemplateThumbnail: null,
      example3TemplateVideo: null,
      example4TemplateThumbnail: null,
      example4TemplateVideo: null,
      name: "",
      s3_id: "",
      duration: 0,
      description: "",
      renderTime: 0,
      afterEffect: "",
      frameNumber: 0,
      categories: [],
      keywords: [],
      custom_fields: {
        colorFields: [],
        textFields: [],
        imageFields: [],
        palettes: []
      }
    }
  }
  
}

const actions = {
  async getTemplates({ commit, dispatch }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      let response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/admin/getTemplates`, {
        withCredentials: true
      });
      const templates = response.data.templates[0];
      for (let i = 0; i < templates.length; i++) {
        templates[i].custom_fields = JSON.parse(templates[i].custom_fields);
      }
      commit("SET_TEMPLATES", templates);
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      console.log(error.response.status);
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async setTemplatesToFree({ state, dispatch }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      let allFreeTemplates = state.newlySelectedFreeTemplateIds;
      await axios.put(`${process.env.VUE_APP_SERVER_URL}/api/admin/set-free-templates`, {
        templates: allFreeTemplates,
      }, {
        withCredentials: true
      })
      await dispatch("templates/getTemplates", {}, { root: true });
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully updated free templates."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async updateTemplateStatus({ commit, dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      let response = await axios.post(`${process.env.VUE_APP_SERVER_URL}/api/templates/update-template-status`, {
        s3Id: context.s3Id,
        status: context.status
      },
      {
        withCredentials: true
      })
      if (response.data[0][0].message.includes("updated")) {
        commit("UPDATE_TEMPLATE", {
          s3Id: context.s3Id,
          key: 'status',
          value: context.status
        })
      }
      dispatch("alert/getAlert", {
        alert: "success",
        message: `Successfully updated template status to '${context.status}'.`
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("alert/getAlert", {
        alert: "danger",
        message: `Something went wrong. Please try again later.`
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async getFilesFromS3({ commit }, context) {
    try {
      const response = await axios.post(`${process.env.VUE_APP_SERVER_URL}/api/admin/list-files-in-s3`, {
        parent: context.parent,
        s3Id: context.s3Id
      }, {
        withCredentials: true
      });
      let keys = [];
      for (let i=0; i<response.data.Contents.length; i++) {
        keys.push(`${process.env.VUE_APP_SERVER_URL}/api/files/` + response.data.Contents[i].Key);
      }
      await commit("SET_S3_FILES", keys);
    } catch (error) {
      console.log(error);
    }
  },
  async generateNewTemplateS3Id({ commit }) {
    try {
      const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/admin/new-template-s3-id`, {
        withCredentials: true
      });
      const s3Id = response.data.s3Id;
      // commit("SET_NEW_S3_ID", s3Id);
      commit("SET_NEW_UPDATE_TEMPLATE_BY_KEY", {
        key: "s3_id",
        value: s3Id
      })
    } catch (error) {
      console.error(error);
    }
  },
  async uploadFile({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.post(`${process.env.VUE_APP_SERVER_URL}/api/admin/upload/${context.param}`, context.data, {
        withCredentials: true
      });
      dispatch("alert/getAlert", {
        alert: "success",
        message: 'Successfully uploaded file.'
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: error
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async createNewTemplate({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.post(`${process.env.VUE_APP_SERVER_URL}/api/admin/new-add-template`, {
        s3Id: context.s3Id,
        name: context.name,
        renderTime: context.renderTime,
        duration: context.duration,
        description: context.description,
        selectedCategories: context.categories,
        selectedKeywords: context.keywords,
        imageFieldCount: context.imageFieldCount,
        textFieldCount: context.textFieldCount,
        aep: context.aep,
        frameNumber: context.frameNumber,
        customFields: context.customFields
      }, {
        withCredentials: true
      })
      dispatch("alert/getAlert", {
        alert: "success",
        message: `Successfully created new template'.`
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/stopLoading", {}, { root: true });
      dispatch("alert/getAlert", {
        alert: "danger",
        message: error
      }, { root: true })
    }
  },
  async updateTemplate({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.post(`${process.env.VUE_APP_SERVER_URL}/api/admin/update-template`, {
        s3Id: context.s3Id,
        name: context.name,
        renderTime: context.renderTime,
        duration: context.duration,
        description: context.description,
        selectedCategories: context.categories,
        selectedKeywords: context.keywords,
        imageFieldCount: context.imageFieldCount,
        textFieldCount: context.textFieldCount,
        aep: context.aep,
        frameNumber: context.frameNumber,
        customFields: context.customFields
      }, {
        withCredentials: true
      })
      dispatch("alert/getAlert", {
        alert: "success",
        message: `Successfully updated template'.`
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/stopLoading", {}, { root: true });
      dispatch("alert/getAlert", {
        alert: "danger",
        message: error
      }, { root: true })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}