<template>
  <div class="container-fluid">
    <div class="input-element">
      <label>Username or Email:</label>
      <input type="text" class="input-box-1" placeholder="Username / Email" v-model="username" />
    </div>
    <div class="buttons">
      <button type="button" class="modified-button" id="cancel-button" @click="closeModal">Cancel</button>
      <button type="button" class="modified-button" id="send-button" @click="findPassword">Send</button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";

const username = ref("");

const store = useStore();

// getters
const currentInputs = computed(() => store.getters["checkError/getCurrentInputs"]);
const errorNumbers = computed(() => store.getters["checkError/getErrorNumber"]);

// methods
const findPassword = async () => {
  store.commit("checkError/SET_ERROR_NUMBER", 0);
  for (let i=0; i<Object.keys(currentInputs.value).length; i++) {
    let input = currentInputs.value[Object.keys(currentInputs.value)[i]];
    for (let j=0; j<input.checkErrors.length; j++) {
      let checkError = input.checkErrors[j];
      store.dispatch(`checkError/check${checkError}`, {
        ...currentInputs.value[Object.keys(currentInputs.value)[i]]
      });
    }
    if (errorNumbers.value > 0) {
      break;
    }
  }
  if (errorNumbers.value === 0) {
    await store.dispatch("user/findPassword", {
      username: username.value
    })
    closeModal();
  }
}
const closeModal = () => {
  store.dispatch("modal/closeModal");
}

store.commit("checkError/SET_CURRENT_INPUTS", {
  username: {
    input: "",
    inputName: "Username or Email",
    checkErrors: ["CannotBeEmpty"]
  }
})

watch(
  () => username.value, newUsername => {
    store.commit("checkError/SET_CURRENT_INPUT_VALUE", {
      key: "username",
      value: newUsername
    });
  }
)


</script>

<style scoped lang="scss">
.input-element {
  text-align: left;
}
.modified-button {
  width: 40%;
  margin: 1em;
}
#cancel-button {
  &:hover {
    background-color: var(--ce-cancel-button-color);
  }
}
#send-button {
  &:hover {
    background-color: var(--ce-submit-button-color);
  }
}
</style>