import axios from "axios";

const state = {
  currentParam: "",
  currentImages: [],
  fileFormats: ["mp4", "wmv", "jpg"],
  fileFormatsForBannerUser: ["mp4", "jpg"],
  editing: false,
  canvasImageUploaded: false,
  currentImageIndex: 0,
  freeTemplateCustomPalettes: []
}

const getters = {
  getCurrentParam: state => {
    return state.currentParam;
  },
  getEditing: state => {
    return state.editing;
  },
  getCurrentImages: state => {
    return state.currentImages;
  },
  getCanvasImageUploaded: state => {
    return state.canvasImageUploaded;
  },
  getCurrentImageIndex: state => {
    return state.currentImageIndex;
  }
}

const mutations = {
  SET_CURRENT_PARAM(state, currentParam) {
    state.currentParam = currentParam;
  },
  SET_EDITING(state, editing) {
    state.editing = editing;
  },
  SET_PROGRESS(state, progress) {
    state.progress = progress;
  },
  SET_CURRENT_IMAGES(state, images) {
    state.currentImages = images;
  },
  SET_CANVAS_IMAGE_UPLOADED(state, uploaded) {
    state.canvasImageUploaded = uploaded;
  },
  SET_CURRENT_IMAGE_INDEX(state, index) {
    state.currentImageIndex = index;
  },
  SET_FREE_TEMPLATE_CUSTOM_PALETTES(state, palettes) {
    state.freeTemplateCustomPalettes = palettes;
  },
  RESET_WIZARD(state) {
    state.currentParam = "";
    state.progress = 0;
    state.currentImages = [];
  }
}

const actions = {
  async uploadImage({ state, commit, dispatch, rootState }, image) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      let formData = new FormData();
      formData.append("upload", image);
      let tempCurrentImages = [...state.currentImages];
      tempCurrentImages[state.currentImageIndex] = image;
      commit("SET_CURRENT_IMAGES", tempCurrentImages);
      const upload = await axios.post(`${process.env.VUE_APP_SERVER_URL}/api/files/upload`, formData, {withCredentials: true});
      if (upload && upload.data && upload.data.key) {
        let tempDataclayImages = [...rootState.dataClay.images];
        tempDataclayImages[state.currentImageIndex] = `${process.env.VUE_APP_SERVER_URL}/api/files/` + upload.data.key;
        commit("dataClay/SET_DATACLAY_VALUE", {
          key: "images",
          value: tempDataclayImages
        }, { root: true })
      }
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      console.log(error);
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}