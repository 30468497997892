import axios from "axios"

const state = {
  annually: true,
  isSubscriptions: false,
  subscriptions: [],
  currentSubscription: null,
  subscriptionHistory: [],
  paymentMethods: [],
  defaultPaymentMethod: null,
  transactionHistory: [],
  hasUnlimitedAccess: null,
  selectedSubscription: null,
  freeSubscription: {
    "name": "FREE",
    "price": {
      "monthly": "0",
      "annually": "0"
    },
    "button": "JOIN NOW",
    "buttons": {
      "monthly": "JOIN NOW",
      "annually": "JOIN NOW"
    },
    "annualDiscountAmount": 0,
    "descriptions": [
      "No Credit Card Required",
      "Unlimited Stock Videos",
      "Custom Resolutions"
    ],
    "trialDuration": null,
    "trialDurationUnit": null
  },
  availableSubscriptions: [
    "EXCLUSIVE",
    "PRO"
  ],
  currentSubscriptionInfoLoaded: false,
  clientToken: null
}

const getters = {
  getAnnually: state => {
    return state.annually;
  },
  getIsSubscriptions: state => {
    return state.isSubscriptions;
  },
  getAvailableSubscriptions: (state, getters, rootState) => {
    let allSubscriptions = [state.freeSubscription];
    state.freeSubscription.buttons.monthly = rootState.user.currentUser.id ? "SWITCH PLAN" : "JOIN NOW"
    state.freeSubscription.buttons.annually = rootState.user.currentUser.id ? "SWITCH PLAN" : "JOIN NOW"
    let exclusiveItem = {
      "name": "EXCLUSIVE",
      "price": {
        "monthly": "0",
        "annually": "0"
      },
      "button": rootState.user.currentUser.id ? "SWITCH PLAN" : "GET STARTED",
      "buttons": {
        "monthly": rootState.user.currentUser.id ? "SWITCH PLAN" : "GET STARTED",
        "annually": rootState.user.currentUser.id ? "SWITCH PLAN" : "GET STARTED" 
      },
      "annualDiscountAmount": 0,
      "descriptions": [],
      "trialDuration": {
        "monthly": 6,
        "annually": 6
      },
      "trialDurationUnit": {
        "monthly": "month",
        "annually": "month"
      }
    };
    let proItem = {
      "name": "PRO",
      "price": {
        "monthly": "0",
        "annually": "0"
      },
      "button": rootState.user.currentUser.id ? "SWITCH PLAN" : "GET STARTED",
      "buttons": {
        "monthly": rootState.user.currentUser.id ? "SWITCH PLAN" : "GET STARTED",
        "annually": rootState.user.currentUser.id ? "SWITCH PLAN" : "GET STARTED" 
      },
      "annualDiscountAmount": 0,
      "descriptions": [],
      "trialDuration": null,
      "trialDurationUnit": null
    };
    state.subscriptions.forEach(subscription => {
      if (subscription.name.split(' ')[0] === "EXCLUSIVE") {
        if (subscription.name.split(' ')[1] === "MONTHLY") {
          exclusiveItem.price.monthly = subscription.price
          if (state.currentSubscription) {
            exclusiveItem.buttons.monthly = state.currentSubscription.planName === subscription.name ? "CURRENT PLAN" : "SWITCH PLAN";
          }
        } else if (subscription.name.split(' ')[1] === "ANNUAL") {
          exclusiveItem.price.annually = subscription.price;
          if (state.currentSubscription) {
            exclusiveItem.buttons.annually = state.currentSubscription.planName === subscription.name ? "CURRENT PLAN" : "SWITCH PLAN";
          }
        }
        exclusiveItem.descriptions = subscription.description.slice(4, -5).split("</li>\r\n<li>");
      } else if (subscription.name.split(' ')[0] === "PRO") {
        if (subscription.name.split(' ')[1] === "MONTHLY") {
          proItem.price.monthly = subscription.price;
        } else if (subscription.name.split(' ')[1] === "ANNUAL") {
          proItem.price.annually = subscription.price;
        }
        proItem.descriptions = subscription.description.slice(4, -5).split("</li>\r\n<li>");
      }
    })
    exclusiveItem.annualDiscountAmount = (parseInt(exclusiveItem.price.monthly) * 12) - parseInt(exclusiveItem.price.annually);
    proItem.annualDiscountAmount = (parseInt(proItem.price.monthly) * 12) - parseInt(proItem.price.annually);
    allSubscriptions.push(exclusiveItem);
    allSubscriptions.push(proItem);
    return allSubscriptions;
  },
  getSubscriptionHistory: state => {
    return state.subscriptionHistory;
  },
  getCurrentSubscription: state => {
    return state.currentSubscription;
  },
  getPaymentMethods: state => {
    return state.paymentMethods;
  },
  getDefaultPaymentMethod: state => {
    return state.defaultPaymentMethod;
  },
  getTransactionHistoryGetter: state => {
    return state.transactionHistory;
  },
  getSelectedSubscription: state => {
    return state.selectedSubscription;
  },
  getterUnlimitedAccess: state => {
    return state.hasUnlimitedAccess;
  },
  getCurrentSubscriptionInfoLoaded: state => {
    return state.currentSubscriptionInfoLoaded;
  },
  allSubscriptions: state => {
    return state.subscriptions;
  },
  getClientToken: state => {
    return state.clientToken;
  }
}

const mutations = {
  SET_ANNUALLY(state, annually) {
    state.annually = annually;
  },
  SET_IS_SUBSCRIPTIONS(state, isSubscriptions) {
    state.isSubscriptions = isSubscriptions;
  },
  SET_ALL_SUBSCRIPTIONS(state, subscriptions) {
    state.subscriptions = subscriptions;
  },
  SET_SUBSCRIPTION_HISTORY(state, subscriptionHistory) {
    state.subscriptionHistory = subscriptionHistory;
  },
  SET_CURRENT_SUBSCRIPTION(state, subscription) {
    state.currentSubscription = subscription;
  },
  SET_PAYMENT_METHODS(state, methods) {
    state.paymentMethods = methods;
  },
  SET_DEFAULT_PAYMENT_METHOD(state, defaultPaymentMethod) {
    state.defaultPaymentMethod = defaultPaymentMethod;
  },
  SET_TRANSACTION_HISTORY(state, transactionHistory) {
    state.transactionHistory = transactionHistory;
  },
  SET_HAS_UNLIMITED_ACCESS(state, hasUnlimitedAccess) {
    state.hasUnlimitedAccess = hasUnlimitedAccess;
  },
  SET_SELECTED_SUBSCRIPTION(state, selectedSubscription) {
    state.selectedSubscription = selectedSubscription;
  },
  SET_CURRENT_SUBSCRIPTION_INFO_LOADED(state, loaded) {
    state.currentSubscriptionInfoLoaded = loaded;
  },
  SET_CLIENT_TOKEN(state, token) {
    state.clientToken = token;
  }
}

const actions = {
  async generateClientToken({ commit, dispatch }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/user-billing/client-token`, {
        withCredentials: true
      });
      commit("SET_CLIENT_TOKEN", response.data);
      
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong while creating Braintree client token. Please try again later."
      }, { root: true })
    }
  },
  async getHasUnlimitedAccess({ commit, dispatch }) {
    dispatch("alert/startLoading", {}, { root: true });
    const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/user-billing/check-wizard-access`, {
      withCredentials: true
    });
    commit("SET_HAS_UNLIMITED_ACCESS", response.data.hasAccess);
    dispatch("alert/stopLoading", {}, { root: true });
  },
  async getAllSubscriptions({ commit, dispatch }) {
    dispatch("alert/startLoading", {}, { root: true });
    const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/user-billing/plans`);
    commit("SET_ALL_SUBSCRIPTIONS", response.data);
    dispatch("alert/stopLoading", {}, { root: true });
  },
  async getUserBillingInfo({ commit, dispatch }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/user-billing/info`, {
        withCredentials: true
      });
      const data = response.data;
      let currentSubscription = null;
      let latestPaidThroughDate = new Date(0);
      let mostRecentCanceledSub = null;
      let sub = null;
      for (let i=0; i<data.subscriptions.length; i++) {
        if (
          data.subscriptions[i].status === "Active" ||
          data.subscriptions[i].status === "Pending" ||
          data.subscriptions[i].status === "Past Due" ||
          data.subscriptions[i].status === "Contract"
        ) {
          currentSubscription = data.subscriptions[i];
          break;
        } else if (
          data.subscriptions[i].status === "Canceled" &&
          data.subscriptions[i].paidThroughDate
        ) {
          const paidThrough = new Date(data.subscriptions[i].paidThroughDate);
          if (paidThrough > latestPaidThroughDate) {
            latestPaidThroughDate = paidThrough;
          }
          if (latestPaidThroughDate >= new Date()) {
            mostRecentCanceledSub = data.subscriptions[i];
          }
        } else if (
          data.subscriptions[i].status === "Canceled" &&
          data.subscriptions[i].trialPeriod
        ) {
          mostRecentCanceledSub = data.subscriptions[i];
        }
      }
      if (currentSubscription) {
        sub = currentSubscription;
      } else if (mostRecentCanceledSub) {
        sub = mostRecentCanceledSub;
      }
      commit("SET_CURRENT_SUBSCRIPTION", sub);
      const defaultPaymentMethod =
        data.paymentMethods.find(m => m.default) || null;
      commit("SET_DEFAULT_PAYMENT_METHOD", defaultPaymentMethod);
      commit("SET_PAYMENT_METHODS", data.paymentMethods);
      commit("SET_SUBSCRIPTION_HISTORY", data.subscriptions);
      commit("SET_CURRENT_SUBSCRIPTION_INFO_LOADED", true);
      dispatch("user/getBraintreeId", {}, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong while fetching user info. Please try again later."
      }, { root: true })
    }
  },
  async getTransactionHistory({ commit, dispatch }) {
    try {
      const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/user-billing/user-transactions`, {
        withCredentials: true
      });
      commit("SET_TRANSACTION_HISTORY", response.data);
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong while fetching user transactions. Please try again later."
      }, { root: true })
    }
  },
  async updatePaymentMethod({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.put(
        `${process.env.VUE_APP_SERVER_URL}/api/user-billing/update-payment-method`, {
          nonce: context.nonce
        }, {
          withCredentials: true
        }
      );
      await dispatch("getUserBillingInfo");
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully updated payment method."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong while updating payment method. Please try again later."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async deletePaymentMethod({ dispatch }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      
      await dispatch("getUserBillingInfo");
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully deleted payment method."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong while deleting payment method. Please try again later."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async deletePendingSubscription({ dispatch }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.delete(`${process.env.VUE_APP_SERVER_URL}/api/user-billing/delete-pending-subscription`, {
        withCredentials: true
      })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong while deleting pending subscription. Please try again later."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async createCustomer({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.post(`${process.env.VUE_APP_SERVER_URL}/api/user-billing/create-customer`, {
        nonce: context.nonce,
        planId: context.planId
      }, {
        withCredentials: true
      });
      axios.delete(`${process.env.VUE_APP_SERVER_URL}/api/user-billing/delete-pending-subscription`, {
        withCredentials: true
      });
      await dispatch("getTransactionHistory");
      await dispatch("getUserBillingInfo");
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Thank you for choosing us. Start creating your contents."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong while creating new customer. Please try again later."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async subscribeWithDefaultPaymentMethod({ dispatch, rootState }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.post(
        `${process.env.VUE_APP_SERVER_URL}/api/user-billing/create-subscription-w-default-payment-method`, {
          selectedPlan: rootState.checkout.selectedPlan
        }, {
          withCredentials: true
        }
      );
      await dispatch("getTransactionHistory");
      await dispatch("getUserBillingInfo");
      dispatch("alert/getAlert", {
        alert: "success",
        message: `Successfully switched to ${rootState.checkout.selectedPlan}`
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong while changing subscription plan. Please try again later."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async changeSubscription({ dispatch, rootState }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      const subscriptionId = state.currentSubscription.id;
      const soonToBePreviousPlan = state.currentSubscription.planName;
      await axios.put(
        `${process.env.VUE_APP_SERVER_URL}/api/user-billing/change-subscription`,
        { selectedPlanName: rootState.checkout.selectedPlan, subscriptionId }, {
          withCredentials: true
        }
      );
      await dispatch("getTransactionHistory");
      await dispatch("getUserBillingInfo");
      dispatch("alert/getAlert", {
        alert: "success",
        message: `Successfully switched from ${soonToBePreviousPlan} to ${rootState.checkout.selectedPlan}`
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong while changing subscription plan. Please try again later."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async updateSubscription({ dispatch, rootState }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      const subscriptionId = state.currentSubscription.id;
      const soonToBePreviousPlan = state.currentSubscription.planName;
      await axios.put(`${process.env.VUE_APP_SERVER_URL}/api/user-billing/update-subscription`, {
        selectedPlanName: rootState.checkout.selectedPlan,
        subscriptionId,
        selectedPlanPrice: rootState.checkout.price
      },{
        withCredentials: true
      });
      const from = state.currentSubscription.planName;
      const to = rootState.checkout.selectedPlan;
      if (to.toLowerCase().includes("exclusive")) {
        await axios.post(`${process.env.VUE_APP_SERVER_URL}/api/user-billing/request-plan-change`, {
          from, to
        },{
          withCredentials: true
        });
      }
      await dispatch("getTransactionHistory");
      await dispatch("getUserBillingInfo");
      dispatch("alert/getAlert", {
        alert: "success",
        message: `Successfully switched from ${soonToBePreviousPlan} to ${rootState.checkout.selectedPlan}`
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong while switching subscription plan. Please try again later."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async cancelSubscriptionPlan({ dispatch }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      const subscriptionId = state.currentSubscription.id;
      await axios.put(`${process.env.VUE_APP_SERVER_URL}/api/user-billing/cancel-subscription`, {
        subscriptionId
      }, {
        withCredentials: true
      });
      await dispatch("getUserBillingInfo");
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully canceled subscription plan."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong while canceling subscription plan. Please try again later."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}