const CryptoJS = require("crypto-js");
const secretKey = process.env.VUE_APP_MAGICINFO_CRYPTO_SECRET_KEY;
// const secretKeyServer = process.env.VUE_APP_MAGICINFO_CRYPTO_SERVER_SECRET_KEY;
const secretKeyPassword =
  process.env.VUE_APP_MAGICINFO_CRYPTO_PASSWORD_SECRET_KEY;

const encryptMagicinfoToken = token => {
  return CryptoJS.AES.encrypt(token, secretKey).toString();
};

const decryptMagicinfoToken = encryptedToken => {
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};

const encryptMagicinfoServer = token => {
  return token;
  // return CryptoJS.Rabbit.encrypt(token, secretKeyServer).toString();
};

const decryptMagicinfoServer = encryptedToken => {
  return encryptedToken;
  // const bytes = CryptoJS.Rabbit.decrypt(encryptedToken, secretKeyServer);
  // return bytes.toString(CryptoJS.enc.Utf8);
};

const encryptMagicinfoPassword = token => {
  return CryptoJS.TripleDES.encrypt(token, secretKeyPassword).toString();
};

const decryptMagicinfoPassword = encryptedToken => {
  const bytes = CryptoJS.TripleDES.decrypt(encryptedToken, secretKeyPassword);
  return bytes.toString(CryptoJS.enc.Utf8);
};

module.exports = {
  encryptMagicinfoToken,
  decryptMagicinfoToken,
  encryptMagicinfoServer,
  decryptMagicinfoServer,
  encryptMagicinfoPassword,
  decryptMagicinfoPassword
};
