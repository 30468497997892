<template>
  <h1>
    <span v-if="signup">CREATE NEW ACCOUNT</span>
    <span v-else>LOGIN</span>
  </h1>
  <div class="box">
    <div class="logo">
      <img id="logo" :src="CreativeEngine" alt="" />
      <span id="logo-title">Creative Engine</span>
    </div>
    <SignupForm v-if="signup" />
    <div v-else>
      <MFA v-if="needMFA" />
      <LoginForm v-else />
    </div>
    
  </div>
</template>

<script setup>
import CreativeEngine from "@/assets/CreativeEngine.png";
import LoginForm from "@/components/SignIn/LoginForm.vue";
import MFA from "@/components/SignIn/MFA.vue";
import SignupForm from "@/components/SignIn/SignupForm.vue";
import { ref, computed, watch, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();

const router = useRouter();
const currentRoute = router.currentRoute.value.name;

const signup = ref("login");

// getters
const needMFA = computed(() => store.getters["user/needMFA"]);

onBeforeMount(() => {
  signup.value = currentRoute === 'signup';
  store.commit("user/SET_NEED_MFA", false);
})

watch (
  () => router.currentRoute.value.name,
  (newRoute) => {
    signup.value = newRoute === 'signup';
  }
)

</script>

<style scoped lang="scss">
h1 {
  margin-top: 2em;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
}
.box {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin: 2em 0;
  width: 1000px;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 1em 0 3em 0;
}
.login-form, .signup-form {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: auto;
  margin-top: 3em;
  align-items: left;
}
.login-form > * :not(span){
  margin: 1em 0;
}
.logo {
  margin-top: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}
#logo {
  height: 2em;
  width: auto;
}
#logo-title {
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin-left: 5px;
  font-size: 25px;
}
.input-box {
  border: none;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  padding: 1em;
  margin: 1ex 0;
  &:focus {
    outline: none;
    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  }
}
span {
  text-align: left;
}
#forgot-password-text {
  text-decoration: none;
  color: black;
  &:hover {
    border-bottom: 1px solid black;
  }
}
#need-account-text {
  text-align: center;
}
button {
  border: none;
  border-radius: 50px;
  padding: 1ex;
  font-weight: bold;
  transition: 0.3s ease;
  &:hover {
    transform: scale(1.03);
  }
}
#login-button {
  background-color: black;
  color: white;
  margin: 2em 0;
}
#signup-button {
  color: black;
  &:hover {
    background-color: rgb(206, 206, 206);
  }
}
</style>