<template>
  <div class="customer-portal-new-folder">
    <div class="customer-portal-new-folder-body">
      <input class="new-folder-name-input" type="text" placeholder="folder name..." 
        v-model="newFolderNameInput"/>
    </div>
    <div class="customer-portal-new-folder-footer">
      <button type="button" class="modified-button" id="close-button" @click="closeModal">
        Cancel
      </button>
      <button type="button" class="modified-button" id="create-button" @click="createFolder"
        :disabled="newFolderNameInput === ''" >
        Create
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";

const store = useStore();

const newFolderNameInput = ref("");

// methods
const createFolder = async () => {
  await store.dispatch("customerPortal/createNewFolder", {
    newFolderName: newFolderNameInput.value, purpose: "CREATE"
  });
  store.dispatch("modal/closeModal");
}
const closeModal = () => {
  store.dispatch("modal/closeModal");
}

</script>

<style scoped lang="scss">
.customer-portal-new-folder {
  width: 100%;
}
.new-folder-name-input {
  display: flex;
  margin: 0 0 1em 1em;
  width: 50%;
  border: none;
  border-bottom: 1px solid grey;
  &:focus {
    outline: none;
  }
}
button {
  margin: 1ex;
}
#create-button {
  width: 20%;
  &:hover {
    background-color: var(--ce-add-button-color);
  }
}
#close-button {
  width: 20%;
  &:hover {
    background-color: var(--ce-cancel-button-color);
  }
}

</style>
