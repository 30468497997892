import { createStore, createLogger } from 'vuex'
import adminAnalytics from '@/store/modules/adminAnalytics';
import adminCategories from '@/store/modules/adminCategories';
import adminKeywords from '@/store/modules/adminKeywords';
import adminProjectQueue from '@/store/modules/adminProjectQueue';
import adminStocks from '@/store/modules/adminStocks';
import adminTemplates from '@/store/modules/adminTemplates';
import adminUsers from '@/store/modules/adminUsers';
import adminPopularSeasonalVideos from '@/store/modules/adminPopularSeasonalVideos';
import user from '@/store/modules/user';
import customerPortal from '@/store/modules/customerPortal';
import alert from "@/store/modules/alert";
import modal from "@/store/modules/modal";
import stocks from '@/store/modules/stocks';
import templates from '@/store/modules/templates';
import userPreferences from '@/store/modules/userPreferences';
import projects from '@/store/modules/projects';
import billing from "@/store/modules/billing";
import wizard from "@/store/modules/wizard";
import dataClay from '@/store/modules/dataClay';
import checkError from "@/store/modules/checkError";
import checkout from "@/store/modules/checkout";
import maintenance from '@/store/modules/maintenance';
import magicinfo from "@/store/modules/magicinfo";
import contact from "@/store/modules/contact";
import ticker from "@/store/modules/ticker";

const debug = false;

export default createStore({
  modules: {
    adminAnalytics,
    adminCategories,
    adminKeywords,
    adminProjectQueue,
    adminStocks,
    adminTemplates,
    adminUsers,
    adminPopularSeasonalVideos,
    user,
    customerPortal,
    alert,
    stocks,
    templates,
    modal,
    userPreferences,
    projects,
    billing,
    wizard,
    dataClay,
    checkError,
    checkout,
    maintenance,
    magicinfo,
    contact,
    ticker
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
