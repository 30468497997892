import axios from "axios";

const state = {
  stocks: [],
  filterStatus: ["Testing", "Published"],
  availableStockStatus: {
    "Testing": {
      color: "yellow",
    },
    "Published": {
      color: "lightBlue",
    },
    "Deleted": {
      color: "red"
    }
  },
  selectedStock: {
    name: "",
    duration: 0,
    description: "",
    categories: [],
    keywords: [],
    thumbnail: null,
    video: null
  },
  newStock: {
    name: "",
    duration: 0,
    description: "",
    categories: [],
    keywords: [],
    thumbnail: null,
    video: null
  },
  currentProcess: "",
  newS3Id: null
}

const getters = {
  filterStocks: state => {
    return state.stocks.filter(stock => {
      return state.filterStatus.includes(stock.status);
    })
  },
  getSelectedStock: state => {
    return state.selectedStock;
  },
  getCurrentProcess: state => {
    return state.currentProcess;
  },
  getNewStockS3Id: state => {
    return state.newS3Id;
  }
}

const mutations = {
  SET_STOCKS(state, stocks) {
    state.stocks = stocks;
  },
  SET_NEW_STOCK(state, stock) {
    state.newStock = stock;
  },
  SET_NEW_STOCK_BY_KEY(state, context) {
    state.newStock[context.key] = context.value;
  },
  SET_NEW_S3_ID(state, s3Id) {
    state.newS3Id = s3Id;
  },
  SET_CURRENT_PROCESS(state, process) {
    state.currentProcess = process;
  },
  TOGGLE_FILTER(state, filter) {
    if (state.filterStatus.includes(filter)) {
      state.filterStatus.splice(state.filterStatus.indexOf(filter), 1);
      return;
    }
    state.filterStatus.push(filter);
  },
  SET_SELECTED_STOCK(state, stock) {
    state.selectedStock = stock;
  },
  UPDATE_STOCK(state, context) {
    for(let i=0; i<state.stocks.length; i++) {
      if (state.stocks[i].s3_id === context.s3Id) {
        state.stocks[i][context.key] = context.value;
        break;
      }
    }
  },
  RESET_SELECTED_STOCK(state) {
    state.selectedStock = {
      name: "",
      duration: 0,
      description: "",
      categories: [],
      keywords: [],
      thumbnail: null,
      video: null
    }
  }
}


const actions = {
  async getStocks({ commit, dispatch }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/admin-stocks/get-stock-library`, {
        withCredentials: true
      });
      for (let i = 0; i < response.data.length; i++) {
        if (response.data[i].categories && response.data[i].categories.length) {
          response.data[i].categories = response.data[i].categories.split(",");
        }
        if (response.data[i].keywords && response.data[i].keywords.length) {
          response.data[i].keywords = response.data[i].keywords.split(",");
        }
      }
      commit("SET_STOCKS", response.data);
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      console.error(error);
    }
  },
  async getSingleStock({ state, dispatch }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.get(
        `${process.env.VUE_APP_SERVER_URL}/api/admin-stock/get-stock-edit-values/` + state.selectedStock.s3_id, {
          withCredentials: true
        }
      );
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("alert/getAlert", {
        alert: "danger",
        message: `Something went wrong. Please try again later.`
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async updateStockStatus({ commit, dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      let response = await axios.put(`${process.env.VUE_APP_SERVER_URL}/api/admin-stocks/update-stock-status`, {
        s3Id: context.s3Id,
        status: context.status
      },
      {
        withCredentials: true
      });
      if (response.data === "success") {
        commit("UPDATE_STOCK", {
          s3Id: context.s3Id,
          key: 'status',
          value: context.status
        })
      }
      dispatch("alert/getAlert", {
        alert: "success",
        message: `Successfully updated stock status to '${context.status}'.`
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("alert/getAlert", {
        alert: "danger",
        message: `Something went wrong. Please try again later.`
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async generateNewStockS3Id({ commit }) {
    try {
      const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/admin-stocks/new-stock-s3-id`, {
        withCredentials: true
      });
      const s3Id = response.data.s3Id;
      commit("SET_NEW_S3_ID", s3Id);
    } catch (error) {
      console.error(error);
    }
  },
  async addNewStock({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      const response = await axios.post(`${process.env.VUE_APP_SERVER_URL}/api/admin-stocks/add-stock`, {
        name: context.name,
        s3Id: context.s3Id,
        duration: context.duration,
        description: context.description,
        selectedCategories: context.categories,
        selectedKeywords: context.keywords
      }, {
        withCredentials: true
      })
      if (response.data.includes("success")) {
        await dispatch("getStocks");
        dispatch("alert/getAlert", {
          alert: "success",
          message: 'Successfully added stock.'
        }, { root: true });
      } else {
        dispatch("alert/getAlert", {
          alert: "danger",
          message: `Failed adding stock. Please try again later.`
        }, { root: true })
      }
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: error
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async updateStock({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      const response = await axios.post(`${process.env.VUE_APP_SERVER_URL}/api/admin-stocks/update-stock`, {
        name: context.name,
        s3Id: context.s3Id,
        duration: context.duration,
        description: context.description,
        selectedCategories: context.categories,
        selectedKeywords: context.keywords
      }, {
        withCredentials: true
      })
      if (response.data.includes("updated")) {
        await dispatch("getStocks");
        dispatch("alert/getAlert", {
          alert: "success",
          message: 'Successfully added stock.'
        }, { root: true });
      } else {
        dispatch("alert/getAlert", {
          alert: "danger",
          message: `Failed adding stock. Please try again later.`
        }, { root: true })
      }
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: error
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async uploadFile({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.post(`${process.env.VUE_APP_SERVER_URL}/api/admin/upload/${context.param}`, context.data, {
        withCredentials: true
      });
      dispatch("alert/getAlert", {
        alert: "success",
        message: 'Successfully uploaded file.'
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: error
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async deleteStock({ dispatch }, context) {
    try {
      await axios.delete(`${process.env.VUE_APP_SERVER_URL}/api/admin-stocks/delete-stock`, {
        s3Id: context.s3Id,
        withCredentials: true
      });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: error
      }, { root: true })
    }
  }
}


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}