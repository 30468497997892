<template>
  <div class="signin-form">
    <div class="selectedPlan">
      <p v-if="selectedSubscription">
        You've selected 
        <b>{{ selectedSubscription.name }}</b> <b v-if="selectedSubscription.annually && selectedSubscription.name !== 'FREE'">ANNUAL</b>
        <b v-else-if="!selectedSubscription.annually && selectedSubscription.name !== 'FREE'">MONTHLY</b> Plan
      </p>
      <button type="button" class="modified-button" id="change-plan-button"
        @click="router.push('/pricing')">Change Plan</button>
    </div>
    <div class="signup-led-display">
      <span>Do you own a Samsung LED Display?</span>
      <span>
        <span class="signin-radio-group">
          <input class="signin-radio" type="radio" id="led-display" name="led-display" v-model="inputs.isDisplayOwner" @change="checkSamsungDisplayOwner" :value="true" />
          <label>YES</label>
        </span>
        <span class="signin-radio-group">
          <input class="signin-radio" type="radio" id="led-display" name="led-display" v-model="inputs.isDisplayOwner" @change="checkSamsungDisplayOwner" :value="false" />
          <label>NO</label>
        </span>
      </span>
    </div>
    <div class="signup-banner-user">
      <span>Are you a Banner user?</span>
      <span>
        <span class="signin-radio-group">
          <input class="signin-radio" type="radio" id="banner-user" name="banner-user" :value="true" v-model="inputs.bannerUser" @change="userIsBannerUser" />
          <label>YES</label>
        </span>
        <span class="signin-radio-group">
          <input class="signin-radio" type="radio" id="banner-user" name="banner-user" :value="false" v-model="inputs.bannerUser" @change="setCurrentInputValue('bannerUser', $event)"/>
          <label>NO</label>
        </span>
      </span>
    </div>
    <div class="input-wrapper">
      <label>Username *</label>
      <input class="input-box-1" type="text" placeholder="Username *" title="Username" required v-model="inputs.username" @change="setCurrentInputValue('username', $event)"/>
    </div>
    <div class="input-wrapper">
      <label>Password *</label>
      <input class="input-box-1" type="password" placeholder="Password *" title="Password" required v-model="inputs.password" @change="setCurrentInputValue('password', $event)"/>
    </div>
    <div class="input-wrapper">
      <label>Confirm Password *</label>
      <input class="input-box-1" type="password" placeholder="Contirm Password *" title="Confirm Password" required v-model="inputs.confirmPassword" @change="setCurrentInputValue('confirmPassword', $event)"/>
    </div>
    <div class="input-wrapper">
      <label>First Name *</label>
      <input class="input-box-1" type="text" placeholder="First Name *" title="First Name" required v-model="inputs.firstName" @change="setCurrentInputValue('firstName', $event)"/>
    </div>
    <div class="input-wrapper">
      <label>Last Name *</label>
      <input class="input-box-1" type="text" placeholder="Last Name *" title="Last Name" required v-model="inputs.lastName" @change="setCurrentInputValue('lastName', $event)"/>
    </div>
    <div class="input-wrapper">
      <label>Email *</label>
      <input class="input-box-1" type="email" placeholder="Email *" title="Email" required v-model="inputs.email" @change="setCurrentInputValue('email', $event)"/>
    </div>
    <div class="input-wrapper">
      <label>Phone Number *</label>
      <input class="input-box-1" type="number" placeholder="Phone Number *" title="Phone Number" required v-model="inputs.phone" @change="setCurrentInputValue('phone', $event)"/>
    </div>
    <div class="input-wrapper">
      <label>Company *</label>
      <input class="input-box-1" type="text" placeholder="Company *" title="Company" required v-model="inputs.company" @change="setCurrentInputValue('company', $event)"/>
    </div>
    <div class="send-newsletters">
      <input type="checkbox" id="newsletter-checkbox" name="newsletter-checkbox" v-model="inputs.username" @change="setCurrentInputValue('newsLetter', $event)" />
      <label for="newsletter-checkbox">
        Please send me newsletters, offers and other updates related to CreativeEngine and Samsung.
      </label>
    </div>
    <button class="modified-button" id="signup-button" type="button"
      @click="signup">Sign Up</button>
    <p class="terms-notification">
      By signing up with CreativeEngine&#8482; you accept our
      <router-link to="/privacy" target="_blank">Privacy Policy</router-link>&#32;(including our use of cookies) and our
      <router-link to="/terms" target="_blank">Terms of Service</router-link>.
    </p>
    <span id="have-account-text">
      Already have an account?
      <router-link id="router-link" to="/login">Log in</router-link>
    </span>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

// states
const freeSubscription = computed(() => store.state.billing.freeSubscription);
// getters
const selectedSubscription = computed(() => store.getters["billing/getSelectedSubscription"]);
const currentInputs = computed(() => store.getters["checkError/getCurrentInputs"]);
const errorNumbers = computed(() => store.getters["checkError/getErrorNumber"]);

const inputs = ref({
  isDisplayOwner: null,
  bannerUser: null,
  username: "",
  password: "",
  confirmPassword: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  company: "",
  plan: selectedSubscription.value ? selectedSubscription.value.name : "FREE",
  optIn: 0,
})

// methods
const checkSamsungDisplayOwner = e => {
  if (e.target.value === "true") {
    if (selectedSubscription.value.name === 'PRO') {
      store.commit("modal/SET_NOTIFICATION_MESSAGE",
        "If you are a Samsung LED Display owner, you are eligible for the <b>EXCLUSIVE</b> plan, " +
        "which comes with a <b>Free 6 Month Trial</b> and allows full access to the Creative Engine. " + 
        "Would you like to change your plan?"  
      )
      store.dispatch("modal/showModal", {
        modalTitle: "Samsung LED Display Owner",
        modalName: "ConfirmationModal"
      });
    }
  } else {
    if (selectedSubscription.value.name === 'EXCLUSIVE') {
      store.commit("modal/SET_NOTIFICATION_MESSAGE",
        "Only Samsung LED Display owner is eligible for <b>EXCLUSIVE</b> plan."
      )
      store.dispatch("modal/showModal", {
        modalTitle: "Not Samsung LED Display Owner",
        modalName: "NotificationModal"
      });
      router.push("/pricing");
    }
  }
  setCurrentInputValue("samsungDisplayOwner", e);
}
const userIsBannerUser = e => {
  if (e.target.value) {
    store.commit("modal/SET_NOTIFICATION_MESSAGE",
      "If you have a Banner account, please log in to your account via the <a href='https://banner.prismview.com'>Banner Website</a>"  
    )
    store.dispatch("modal/showModal", {
      modalTitle: "Banner User",
      modalName: "NotificationModal"
    });
    router.push("/pricing");
  }
}

const setCurrentInputValue = (key, e) => {
  let value = e.target.value;
  store.commit("checkError/SET_CURRENT_INPUT_VALUE", {
    key: key,
    value: value
  });
}

const signup = async () => {
  store.commit("checkError/SET_ERROR_NUMBER", 0);
  for (let i=0; i<Object.keys(currentInputs.value).length; i++) {
    let input = currentInputs.value[Object.keys(currentInputs.value)[i]];
    for (let j=0; j<input.checkErrors.length; j++) {
      let checkError = input.checkErrors[j];
      store.dispatch(`checkError/check${checkError}`, {
        ...currentInputs.value[Object.keys(currentInputs.value)[i]]
      });
    }
    if (errorNumbers.value > 0) {
      break;
    }
  }
  if (errorNumbers.value === 0) {
    let response = await store.dispatch("user/signup", {
      username: inputs.value.username,
      password: inputs.value.password,
      firstName: inputs.value.firstName,
      lastName: inputs.value.lastName,
      email: inputs.value.email,
      phone: inputs.value.phone,
      company: inputs.value.company,
      isDisplayOwner: inputs.value.isDisplayOwner,
      optIn: inputs.value.optIn,
      plan: getPlanName.value
    })
    if (response) {
      router.push("/");
    }
  }
}


// computed
const getPlanName = computed(() => {
  if (selectedSubscription.value.name === "FREE") {
    return "FREE";
  }
  let name = selectedSubscription.value.annually ? "ANNUAL" : "MONTHLY";
  return `${selectedSubscription.value.name} ${name}`;
})

// create
if (!selectedSubscription.value) {
  store.commit("billing/SET_SELECTED_SUBSCRIPTION", {
    ...freeSubscription.value,
    annually: false
  })
}
store.commit("checkError/SET_CURRENT_INPUTS", {
  samsungDisplayOwner: {
    input: null,
    inputName: "Samsung Display Owner",
    checkErrors: ["CannotBeNull"]
  },
  bannerUser: {
    input: null,
    inputName: "Banner User",
    checkErrors: ["CannotBeNull"]
  },
  username: {
    input: "",
    inputName: "Username",
    checkErrors: ["CannotBeEmpty", "CannotBeShorterOrLonger"]
  },
  password: {
    input: "",
    inputName: "Password",
    checkErrors: ["CannotBeEmpty", "CannotBeShorterOrLongerPassword", "IncludeLowerCaseCharacter", "IncludeUpperCaseCharacter", "IncludeNumbers", "IncludeSpecialCharacter"]
  },
  confirmPassword: {
    input: "",
    inputName: "Confirm Password",
    matchInput: "",
    matchInputName: "Password",
    checkErrors: ["CannotBeEmpty", "DoesNotMatch"]
  },
  firstName: {
    input: "",
    inputName: "First Name",
    checkErrors: ["CannotBeEmpty", "CannotBeShorterOrLonger"]
  },
  lastName: {
    input: "",
    inputName: "Last Name",
    checkErrors: ["CannotBeEmpty", "CannotBeShorterOrLonger"]
  },
  email: {
    input: "",
    inputName: "Email",
    checkErrors: ["CannotBeEmpty"]
  },
  phone: {
    input: "",
    inputName: "Phone Number",
    checkErrors: ["CannotBeEmpty", "DoesNotMatchPhoneNumberFormat"]
  },
  company: {
    input: "",
    inputName: "Company Name",
    checkErrors: ["CannotBeEmpty", "CannotBeShorterOrLonger"]
  },
  newsletter: {
    input: false,
    inputName: "newsletter",
    checkErrors: []
  }
})

watch(
  () => inputs.value.password,
  (updatedInput) => {
    store.commit("checkError/SET_CURRENT_INPUT_SECOND_KEY_VALUE", {
      key: "confirmPassword",
      secondKey: "matchInput",
      value: updatedInput
    })
  }
)

</script>

<style scoped lang="scss">
.selectedPlan {
  display: flex;
  justify-content: center;
  #change-plan-button {
    position: absolute;
    right: 0;
    top: 0;
    margin: 2em 1em 0 0;
    width: 20%;
    &:hover {
      background-color: var(--ce-add-button-color);
    }
  }
}
#router-link {
  color: black;
  text-decoration: none;
  &:hover {
    border-bottom: 1px solid black;
  }
}
#signup-button {
  margin-top: 2em;
  color: black;
  &:hover {
    background-color: var(--ce-submit-button-color);
  }
}
.signup-led-display, .signup-banner-user {
  display: flex;
  flex-direction: column;
  text-align: left;
  .signin-radio-group {
    margin: 1em;
    .signin-radio {
      margin: 1em 1ex;
    }
  }
}
.send-newsletters {
  display: flex;
}
.terms-notification {
  margin: 2em 0;
}
.input-wrapper {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 1ex 0;
  label {
    font-size: 11px;
  }
}
#newsletter-checkbox {
  margin-right: 1ex;
}
#have-account-text {
  margin-top: 1em;
}
</style>