import axios from "axios";

const state = {
  categories: [],
  selectedCategory: null
}

const getters = {
  getCategories: state => {
    return state.categories;
  },
  getCategoryNames: state => {
    return state.categories.map(category => {
      return category.category;
    })
  },
  getCategoryIdsByNames: state => categoryNames => {
    let ids = [];
    for (let i=0; i<categoryNames.length; i++) {
      for (let j=0; j<state.categories.length; j++) {
        if (categoryNames[i] === state.categories[j].category) {
          ids.push(state.categories[j].id);
          break;
        }
      }
    }
    return ids;
  },
  getCategoriesWithPublishedCount: (state, getters, rootState) => {
    let categories = [...state.categories];
    categories.forEach(item => {
      item.published = (rootState.templates.categoryCount[item.category] || 0) + (rootState.stocks.categoryCount[item.category] || 0);
    })
    return categories;
  },
  getSelectedCategory: state => {
    return state.selectedCategory;
  }
}

const mutations = {
  SET_CATEGORIES(state, categories) {
    state.categories = categories;
  },
  SET_SELECTED_CATEGORY(state, category) {
    state.selectedCategory = category;
  }
}

const actions = {
  async getCategories({ commit, dispatch }) {
    dispatch("alert/startLoading", {}, { root: true });
    const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/media/fetch-all-categories`);
    commit("SET_CATEGORIES", response.data.categories);
    dispatch("alert/stopLoading", {}, { root: true });
  },
  async addCategory({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.post(`${process.env.VUE_APP_SERVER_URL}/api/admin/add-new-category-or-keyword`, {
        newCategory: context.newCategory
      }, {
        withCredentials: true
      });
      await dispatch("getCategories");
      dispatch("alert/getAlert", {
        alert: "success",
        message: `Successfully added category '${context.newCategory}'`
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("alert/getAlert", {
        alert: "danger",
        message: `Something went wrong. Please try again later.`
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async updateCategory({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.post(`${process.env.VUE_APP_SERVER_URL}/api/media/edit-category`, {
        id: context.id,
        category: context.newCategory
      }, {
        withCredentials: true
      })
      await dispatch("templates/getTemplates", {}, { root: true });
      await dispatch("stocks/getStocks", {}, { root: true });
      await dispatch("getCategories");
      dispatch("alert/getAlert", {
        alert: "success",
        message: `Successfully udpated category to '${context.newCategory}'`
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("alert/getAlert", {
        alert: "danger",
        message: `Something went wrong. Please try again later.`
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async deleteCategory({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      const response = await axios.post(`${process.env.VUE_APP_SERVER_URL}/api/media/delete-category`, {
        id: context.id
      }, {
        withCredentials: true
      });
      if (response.data === "deleted") {
        await dispatch("getCategories");
      }
      dispatch("alert/getAlert", {
        alert: "success",
        message: `Successfully deleted`
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("alert/getAlert", {
        alert: "danger",
        message: `Something went wrong. Please try again later.`
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}