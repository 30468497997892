<template>
  <div class="container-fluid">
    <div class="buttons">
      <button type="button" class="modified-button" id="add-category-button"
        @click="addOrUpdateCategory(null)">
        Add Category
      </button>
    </div>
    <table class="table table-hover table-striped table-bordered">
      <thead>
        <tr>
          <th scope="col" v-for="(head, index) in tableHead" :key="index">
            {{ Helper.formatTabText(head) }}
            <span v-if="Helper.formatTabText(head) !== 'Action'" id="sorting-icon"  @click="sort(head)">
              <icon v-if="sorting.name === head && sorting.sort === 'up'" class="icon" icon="sort-up" />
              <icon v-else-if="sorting.name === head && sorting.sort === 'down'" class="icon" icon="sort-down" />
              <icon v-else class="icon" icon="sort" />
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data, index) in sortedCategories" :key="index">
          <td v-for="(head, index_y) in tableHead" :key="index_y">
            <span v-if="head === 'action'" class="action-column">
              <icon class="icon" icon="pen" @click="addOrUpdateCategory(data)"/>
              <icon class="icon" icon="xmark" @click="deleteCategory(data)"/>
            </span>
            <span v-else>{{ data[head] }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="empty" v-if="categoriesWithPublishedCount.length === 0">Empty</div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import Helper from "@/helpers";

const store = useStore();
const tableHead = ref(["id", "category", "published", "action"]);
const sorting = ref({
  name: "",
  sort: ""
});

// getters
const categoriesWithPublishedCount = computed(() => store.getters["adminCategories/getCategoriesWithPublishedCount"]);

// computed
const sortedCategories = computed(() => {
  let temp = [...categoriesWithPublishedCount.value];
  if (sorting.value.name !== "") {
    temp.sort((a,b) => {
      if (sorting.value.sort === "up") {
        if (sorting.value.name === "published" || sorting.value.name === "id") {
          return a[sorting.value.name.toLowerCase()] - b[sorting.value.name.toLowerCase()];
        }
        return a[sorting.value.name.toLowerCase()].toLowerCase() > b[sorting.value.name.toLowerCase()].toLowerCase() ? 1 : -1;
      }
      if (sorting.value.name === "published" || sorting.value.name === "id") {
        return b[sorting.value.name.toLowerCase()] - a[sorting.value.name.toLowerCase()];
      }
      return b[sorting.value.name.toLowerCase()].toLowerCase() > a[sorting.value.name.toLowerCase()].toLowerCase() ? 1 : -1;
    })
  }
  return temp;
})

// methods
const addOrUpdateCategory = data => {
  let modalTitle = "Add Category";
  let modalName = "UpdateCategoryKeyword";
  if (data !== null) {
    modalTitle = "Update Category"
    store.commit("adminCategories/SET_SELECTED_CATEGORY", data);
  }
  store.dispatch("modal/showModal", {
    modalTitle: modalTitle,
    modalName: modalName
  })
}
const deleteCategory = data => {
  store.dispatch("adminCategories/deleteCategory", {
    id: data.id
  });
}
const sort = head => {
  if (sorting.value.name === "" || sorting.value.name !== head) {
    sorting.value = {
      name: head,
      sort: "up"
    }
    return;
  }
  if (sorting.value.sort === "up") {
    sorting.value.sort = "down";
    return;
  }
  sorting.value = {
    name: "",
    sort: ""
  };
}

// created
if (categoriesWithPublishedCount.value.length === 0) {
  store.dispatch("adminCategories/getCategories");
}

</script>

<style scoped lang="scss">
.icon {
  cursor: pointer;
}
.buttons {
  float: left;
}
.action-column {
  display: flex;
  justify-content: space-evenly;
}
#add-category-button {
  margin: 1ex;
  &:hover {
    background-color: var(--ce-add-button-color);
  }
}
#sorting-icon {
  float: right
}
</style>