<template>
  <div class="container-fluid">
    <div class="button">
      <button type="button" class="btn btn-outline-dark">Download Recent Newsletter Opt-Ins</button>
    </div>
    <table class="table table-hover table-striped table-bordered">
      <thead>
        <tr>
          <th scope="col" v-for="(head, index) in tableHead" :key="index">
            {{ head }}
            <span v-if="head !== 'Edit' && head !== 'Link'" id="sorting-icon"  @click="sort(head)">
              <icon v-if="sorting.name === head && sorting.sort === 'up'" class="icon" icon="sort-up" />
              <icon v-else-if="sorting.name === head && sorting.sort === 'down'" class="icon" icon="sort-down" />
              <icon v-else class="icon" icon="sort" />
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data, index) in sortedUsers" :key="index">
          <td v-for="(head, index_y) in tableHead" :key="index_y">
            <span v-if="head === 'Edit'" class="action-column">
              <icon class="icon" icon="pen" @click="seeUserDetail(data)"/>
            </span>
            <span v-else-if="head === 'Link'" class="action-column">
              <icon class="icon" icon="link" @click="userBespoke(data)"/>
            </span>
            <span v-else>{{ data[head.toLowerCase()] }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="empty" v-if="getterFormattedUsers.length === 0">Empty</div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();
const tableHead = ref(["Username", "Name", "Email", "Company", "Braintree_Id", "Edit", "Link"]);
const sorting = ref({
  name: "",
  sort: ""
});

// state
const stateUsers = computed(() => store.state.adminUsers.users);

// getters
const getterFormattedUsers = computed(() => store.getters["adminUsers/formattedUsers"]);

// computed
const sortedUsers = computed(() => {
  let temp = [...getterFormattedUsers.value];
  if (sorting.value.name !== "") {
    temp.sort((a,b) => {
      if (sorting.value.sort === "up") {
        if (sorting.value.name === "Braintree_Id") {
          if (a[sorting.value.name.toLowerCase()] === undefined || a[sorting.value.name.toLowerCase()] === null) {
            a[sorting.value.name.toLowerCase()] = '';
          }
          if (b[sorting.value.name.toLowerCase()] === undefined || b[sorting.value.name.toLowerCase()] === null) {
            b[sorting.value.name.toLowerCase()] = '';
          }
          return a[sorting.value.name.toLowerCase()] - b[sorting.value.name.toLowerCase()];
        }
        return a[sorting.value.name.toLowerCase()].toLowerCase() > b[sorting.value.name.toLowerCase()].toLowerCase() ? 1 : -1;
      }
      if (sorting.value.name === "Braintree_Id") {
        if (a[sorting.value.name.toLowerCase()] === undefined || a[sorting.value.name.toLowerCase()] === null) {
          a[sorting.value.name.toLowerCase()] = '';
        }
        if (b[sorting.value.name.toLowerCase()] === undefined || b[sorting.value.name.toLowerCase()] === null) {
          b[sorting.value.name.toLowerCase()] = '';
        }
        return b[sorting.value.name.toLowerCase()] - a[sorting.value.name.toLowerCase()];
      }
      return b[sorting.value.name.toLowerCase()].toLowerCase() > a[sorting.value.name.toLowerCase()].toLowerCase() ? 1 : -1;
    })
  }
  return temp;
})

// methods
const seeUserDetail = data => {
  store.commit("adminUsers/SET_SELECTED_USER", data);
  Object.keys(data).forEach(key => {
    sessionStorage.setItem(key, data[key]);
  })
  router.push(`/admin/users/${data.ceID}`)
}
const userBespoke = async data => {
  store.commit("adminPopularSeasonalVideos/SET_CURRENT_PAGE", 'bespoke');
  store.commit("adminUsers/SET_SELECTED_USER", data);
  await store.dispatch("adminUsers/getBespokeTemplatesByUserId", {
    userId: data.ceID
  })
  router.push("/admin/managetemplate");
}
const sort = head => {
  if (sorting.value.name === "" || sorting.value.name !== head) {
    sorting.value = {
      name: head,
      sort: "up"
    }
    return;
  }
  if (sorting.value.sort === "up") {
    sorting.value.sort = "down";
    return;
  }
  sorting.value = {
    name: "",
    sort: ""
  };
}

// created
if (stateUsers.value.length === 0) {
  store.dispatch("adminUsers/getUsers");
}
</script>

<style scoped>
.icon {
  cursor: pointer;
}
.button {
  float: left;
  margin-bottom: 1em;
}
#sorting-icon {
  float: right
}
</style>