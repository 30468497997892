<template>
  <div class="container-fluid">
    <div class="message">
      <p id="message"></p>
    </div>
    <div class="buttons">
      <button type="button" class="modified-button" id="cancel-button"
        @click="closeModal">Cancel</button>
      <button type="button" class="modified-button" id="confirm-button"
        @click="confirm">Confirm</button>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

const modalTitle = computed(() => store.getters["modal/getModalTitle"]);
const notificationMessage = computed(() => store.getters["modal/getNotificationMessage"]);
const defaultPaymentMethod = computed(() => store.getters["billing/getDefaultPaymentMethod"]);
const currentSubscription = computed(() => store.getters["billing/getCurrentSubscription"]);
const selectedPlan = computed(() => store.getters["checkout/getSelectedPlan"])
const selectedAction = computed(() => store.getters["checkout/getAction"]);

const confirm = async () => {
  if (modalTitle.value === "Samsung LED Display Owner") {
    router.push("/pricing");
  }
  else if (modalTitle.value === "Switch Plan") {
    console.log(selectedPlan.value);
    if (!currentSubscription.value || !currentSubscription.value.id) {
      store.commit("checkout/SET_ACTION", "create")
    } else if (selectedPlan.value.split(' ')[0] === 'FREE') {
      store.dispatch("billing/cancelSubscriptionPlan");
    } else if (selectedPlan.value.split(' ')[1] === currentSubscription.value.planName.split(' ')[1]){
      store.commit("checkout/SET_ACTION", "update")
    } else {
      store.commit("checkout/SET_ACTION", "change")
    }
    if (!defaultPaymentMethod.value) {
      router.push("/checkout");
    } else {
      if (selectedAction.value === "update") {
        store.dispatch("billing/updateSubscription");
      } else if (selectedAction.value === "change") {
        store.dispatch("billing/changeSubscription");
      } else if (selectedAction.value === "create") {
        store.dispatch("billing/subscribeWithDefaultPaymentMethod")
      }
    }
  }
  else if (modalTitle.value === "Canceling Subscription") {
    store.dispatch("billing/cancelSubscriptionPlan");
  }
  else if (modalTitle.value === "Pending Subscription") {
    await store.dispatch("billing/deletePendingSubscription");
    store.commit("modal/SET_NOTIFICATION_MESSAGE", 
      "You may select your subscription from 'Pricing' tab later."
    )
    store.dispatch("modal/showModal", {
      modalTitle: "Updating Subscription",
      modalName: "NotificationModal"
    })
    return;
  }
  else if (modalTitle.value === "Banner User, Are you sure?") {
    await store.dispatch("projects/deleteProjects");
  }
  store.dispatch("modal/closeModal");
}

const closeModal = () => {
  store.dispatch("modal/closeModal");
}

onMounted(() => {
  let msg = document.getElementById("message");
  msg.innerHTML = notificationMessage.value;
})

</script>

<style scoped lang="scss">
.message {
  text-align: left;
}
.buttons {
  #cancel-button {
    margin: 1em;
    width: 30%;
    &:hover {
      background-color: var(--ce-cancel-button-color);
    }
  }
  #confirm-button {
    margin: 1em;
    width: 30%;
    &:hover {
      background-color: var(--ce-submit-button-color);
    }
  }
}
</style>