<template>
  <div class="customer-portal-element-list">
    <table
      class="table table-hover table-striped"
    >
      <thead>
        <tr>
          <th v-for="(head, index) in tableHead" :key="index">
            <span class="element-list-header">
              {{ Helper.formatTabText(head) }}
              <span v-if="head !== 'selected' && head !== 'new-project'" id="sorting-icon"  @click="sort(head)">
                <icon v-if="sorting.name === head && sorting.sort === 'up'" class="icon" icon="sort-up" />
                <icon v-else-if="sorting.name === head && sorting.sort === 'down'" class="icon" icon="sort-down" />
                <icon v-else class="icon" icon="sort" />
              </span>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data, index) in sortedItems" :key="index">
          <td v-for="(head, indexY) in tableHead" :key="indexY"
            :class="{'disabled': data.status === 'Processing', 'selected-item': dataIsSelected(data)}">
            <span v-if="head === 'selected'" class="toggle-button" id="icon" @click="toggleSelected(data)">
              <icon :icon="data.selected ? 'toggle-on' : 'toggle-off'" />
            </span>
            <span v-else-if="head === 'new-project'" class="toggle-button" id="icon" @click="copyToNewProject(data)">
              <icon :icon="'copy'" />
            </span>
            <div v-else class="item" @click="clickItem(data)">
              <span class="element-list-image-slot" v-if="head === 'name'">
                <img v-if="data.status === 'Completed'" class="element-image" :src="getImageSrc(data.type)" alt=""/>
                <icon v-else-if="data.status === 'Processing'" class="element-image" icon="spinner" />
                <icon v-else-if="data.status === 'Error'" class="element-image" icon="exclamation" />
                <icon v-else-if="data.status === 'Draft'" class="element-image" icon="pen-ruler" />
                &nbsp;&nbsp;{{ data.name }}
              </span>
              <span v-else-if="head === 'compatible-displays'" class="element-list-data">
                {{ displayResolutionNameObject[data['matrix']] }}
              </span>
              <span v-else class="element-list-data">
                {{ data[head] }}
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="center-item" v-if="getFormattedProjects.length === 0">
      <h3><i>Empty</i></h3>
    </div>
  </div>
</template>

<script setup>
import FileImage from "@/assets/fileImage.png";
import ImageImage from "@/assets/imageIcon.png";
import VideoImage from "@/assets/videoIcon.png";
import FolderImage from "@/assets/folder.png";
import { ref, computed } from 'vue';
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Helper from "@/helpers";

const store = useStore();
const router = useRouter();
// data
const tableHead = ref(["name", "id", "date", "compatible-displays", "status", "new-project", "selected"]);
const sorting = ref({
  name: "id",
  sort: "down"
});

// getters
const displayResolutionNameObject = computed(() => store.getters["userPreferences/displayResolutionNameObject"]);
const getFormattedProjects = computed(() => store.getters["projects/getFormattedProjects"])
const username = computed(() => store.getters["user/getUsername"]);
const someItemsSelected = computed(() => store.getters["projects/someItemsSelected"]);
const selectedProjects = computed(() => store.getters["projects/getSelectedProjects"]);
const getProjectDataClay = computed(() => store.getters["projects/getProjectDataClay"]);
const currentVideo = computed(() => store.getters["templates/getCurrentVideo"]);
const isBannerUser = computed(() => store.getters["user/isBannerUser"]);

// computed
const sortedItems = computed(() => {
  let temp = [...getFormattedProjects.value];
  if (sorting.value.name !== "") {
    temp.sort((a,b) => {
      if (sorting.value.sort === "up") {
        if (sorting.value.name === "id") {
          return a[sorting.value.name.toLowerCase()] - b[sorting.value.name.toLowerCase()];
        }
        if (sorting.value.name ==="compatible-displays") {
          return displayResolutionNameObject.value[a['matrix']] > displayResolutionNameObject.value[b['matrix']] ? 1 : -1;
        }
        if (sorting.value.name === "date") {
          return new Date(a[sorting.value.name]) - new Date(b[sorting.value.name]);
        }
        return a[sorting.value.name.toLowerCase()].toLowerCase() > b[sorting.value.name.toLowerCase()].toLowerCase() ? 1 : -1;
      }
      if (sorting.value.name === "id") {
        return b[sorting.value.name.toLowerCase()] - a[sorting.value.name.toLowerCase()];
      }
      if (sorting.value.name ==="compatible-displays") {
        return displayResolutionNameObject.value[b['matrix']] > displayResolutionNameObject.value[a['matrix']] ? 1 : -1;
      }
      if (sorting.value.name === "date") {
        return new Date(b[sorting.value.name]) - new Date(a[sorting.value.name]);
      }
      return b[sorting.value.name.toLowerCase()].toLowerCase() > a[sorting.value.name.toLowerCase()].toLowerCase() ? 1 : -1;
    })
  }
  return temp;
})

// methods
const clickItem = data => {
  if (isBannerUser.value) {
    store.commit("modal/SET_NOTIFICATION_MESSAGE",
      `Your project is available on <a href="${process.env.VUE_APP_BANNER_DOMAIN}/dashboard/content" target="_blank">Banner</a> (Dashboard -> Content Library).<br>`
    )
    store.dispatch("modal/showModal", {
      modalTitle: "Banner User",
      modalName: "NotificationModal"
    })
    return;
  }
  let file = {...data,
    media: getMedia(data)
  }
  store.commit("projects/SET_CURRENT_FILE", file);
  store.dispatch("modal/showModal", {
     modalTitle: data.name, 
     modalName: "CustomerPortalFileDetail"
    }
  )
}
const getMedia = data => {
  let format = data.format;
  if (format === "wmv") {
    format = "mp4";
  }
  return `${process.env.VUE_APP_SERVER_URL}/api/files/projects/${encodeURIComponent(username.value)}/${data.id}.${format}`;
}
const dataIsSelected = data => {
  let dataId = data.id;
  for (let i=0; i<Object.keys(selectedProjects.value).length; i++) {
    if (Object.keys(selectedProjects.value)[i] === dataId) {
      return true;
    }
  }
  return false;
}
const toggleSelected = data => {
  let tempSelectedItems = JSON.parse(JSON.stringify(selectedProjects.value));
  tempSelectedItems[data.id] ? 
  delete tempSelectedItems[data.id] :
  tempSelectedItems[data.id] = data;
  data.selected = !data.selected;
  store.commit("projects/SET_SELECTED_PROJECTS", tempSelectedItems);
  store.commit("projects/SET_SELECTING", !!someItemsSelected.value);
}
const copyToNewProject = async data => {
  store.commit("projects/SET_COPIED_PROJECT_ID", data.id);
  let { dataClay, templateId } = getProjectDataClay.value;
  await store.dispatch("templates/setCurrentVideoById", templateId);
  sessionStorage.clear();
  Object.keys(currentVideo.value).forEach(key => {
    sessionStorage.setItem(key, currentVideo.value[key]);
  })
  Object.keys(dataClay).forEach(key => {
    if (key === "texts") {
      dataClay[`${key}`].forEach((value, index) => {
        store.commit("dataClay/SET_DATACLAY_VALUE", {
          key: key,
          index: index,
          value: value
        })
      })
    } else {
      store.commit("dataClay/SET_DATACLAY_VALUE", {
        key: key,
        value: dataClay[`${key}`]
      })
    }
  })
  router.push("/wizard/fileStep");
  window.scrollTo(0, 0);
}
const getImageSrc = type => {
  let imageSrc = FileImage;
  if (type === "image") {
    imageSrc = ImageImage;
  } else if (type === "video") {
    imageSrc = VideoImage;
  } else if (type === "folder") {
    imageSrc = FolderImage;
  }
  return imageSrc;
}
const sort = head => {
  if (sorting.value.name === "" || sorting.value.name !== head) {
    sorting.value = {
      name: head,
      sort: "up"
    }
    return;
  }
  if (sorting.value.sort === "up") {
    sorting.value.sort = "down";
    return;
  }
  sorting.value = {
    name: "",
    sort: ""
  };
}


</script>

<style scoped lang="scss">
.icon {
  cursor: pointer;
}
#icon {
  display: flex;
  margin-left: 1em;
}
.item {
  cursor: pointer;
}
.selected-item {
  background-color: rgb(255, 255, 154);
}
.disabled {
  opacity: 0.4;
  pointer-events: none;
}
.customer-portal-element-list {
  min-height: 60ex;
}
.center-item {
  position: absolute;
  left: 50%;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.toggle-button {
  cursor: pointer;
  display: flex;
}
.element-image {
  height: 20px;
  width: 25px;
}
.element-list-header, .element-list-data {
  display: flex;
  // justify-content: space-between;
}
.element-list-image-slot {
  cursor: pointer;
  float: left;
  padding-left: 1em;
}
#sorting-icon {
  margin-left: 1em;
}

</style>