import axios from "axios";

const state = () => ({
  credentials: {},
  currentUser: {},
  hasBraintreeId: false,
  guestEmail: null,
  optIn: 0,
  needMFA: false,
  mfaCode: "",
  mfaCountDownTime: 90,
  hasPendingSubscription: false
})

const getters = {
  isLoggedIn(state) {
    return Object.keys(state.currentUser).length !== 0;
  },
  isAdmin: state => {
    return state.currentUser.role === "admin";
  },
  isBannerUser: state => {
    return state.currentUser.role === "banner";
  },
  isContractUser: state => {
    return state.currentUser.role === "contract";
  },
  needMFA: state => {
    return state.needMFA;
  },
  getMFACode: state => {
    return state.mfaCode;
  },
  getUsername(state) {
    return state.currentUser.username;
  },
  getCurrentUser(state) {
    return state.currentUser;
  },
  getCompanyName: state => {
    return state.currentUser.company;
  },
  hasCustomerPortalAccess: state => {
    return state.currentUser.hasCustomerPortalAccess;
  },
  hasBraintreeId: state => {
    return state.hasBraintreeId;
  },
  getGuestEmail: state => {
    return state.guestEmail;
  },
  getMFACountDownTime: state => {
    return state.mfaCountDownTime;
  },
  getCredentials: state => {
    return state.credentials;
  },
  getHasPendingSubscription: state => {
    return state.hasPendingSubscription;
  }
}

const mutations = {
  SET_USER(state, user) {
    state.currentUser = user;
  },
  SET_NEED_MFA(state, mfa) {
    state.needMFA = mfa;
  },
  UPDATE_USER(state, value) {
    state.currentUser = { ...state.currentUser, ...value };
  },
  CLEAR_USER() {
    state.currentUser = {};
  },
  SET_HAS_BRAINTREE_ID(state, bool) {
    state.hasBraintreeId = bool;
  },
  UPDATE_USER_VALUE(state, data) {
    for (const key of Object.keys(data)) {
      state.currentUser[key] = data[key];
    }
  },
  SET_GUEST_EMAIL(state, email) {
    state.guestEmail = email;
  },
  SET_OPT_IN(state, bool) {
    state.optIn = bool;
  },
  SET_MFA_CODE(state, mfaCode) {
    state.mfaCode = mfaCode;
  },
  SET_CREDENTIALS(state, credentials) {
    state.credentials = credentials;
  },
  SET_HAS_PENDING_SUBSCRIPTION(state, hasPendingSubscription) {
    state.hasPendingSubscription = hasPendingSubscription;
  }
}

const actions = {
  async activateKey({ dispatch }, data) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      let response = await axios.post(`${process.env.VUE_APP_SERVER_URL}/auth/activate-key`, data, {
        withCredentials: true
      });
      dispatch("alert/getAlert", {
        alert: response.data.alert,
        message: response.data.message
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
      return true;
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger", message: "Something went wrong. Please try again later. If the issue continues, please contact the admin." }, {
          root: true 
        }
      );
      dispatch("alert/stopLoading", {}, { root: true });
      return false;
    }
  },
  async checkMFA({ commit }, context) {
    try {
      let response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/auth/check-mfa`, {
        params: {
          username: context.username
        },
        withCredentials: true
      });
      commit("SET_NEED_MFA", response.data.mfa);
    } catch (error) {
      console.log(error);
    }
  },
  async sendMFA({ state, dispatch }) {
    try {
      await axios.get(`${process.env.VUE_APP_SERVER_URL}/auth/send-mfa-code`, {
        params: {
          mfaCode: state.mfaCode,
          username: state.credentials.username
        },
        withCredentials: true
      });
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Please check your email for MFA code."
      }, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger", message: "Something went wrong. Please try again later." }, {
          root: true 
        }
      );
    }
  },
  async login({ commit, dispatch, rootGetters }, data) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.post(`${process.env.VUE_APP_SERVER_URL}/auth/login`, data, {
        withCredentials: true
      });
      await dispatch("getUser");
      // pending Sub
      let response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/user-billing/pending-subscription`, {
        withCredentials: true
      })
      if (response.data.length > 0) {
        await dispatch("billing/getAllSubscriptions", {}, { root: true });
        let subs = rootGetters["billing/getAvailableSubscriptions"];
        let selected = subs[0];
        for (let i=0; i<subs.length; i++) {
          if (response.data[0].plan.split(' ')[0] === subs[i].name) {
            commit("billing/SET_SELECTED_SUBSCRIPTION", subs[i], { root: true });
            selected = subs[i];
            break;
          }
        }
        let period = response.data[0].plan.split(' ')[1] === "ANNUAL" ? "annually" : "monthly";
        let checkouts = {
          "selectedPlan": response.data[0].plan,
          "price": response.data[0].plan.split(' ')[1] === "ANNUAL" ? selected.price.annually : selected.price.monthly,
          "trialDuration": selected.trialDuration ? selected.trialDuration[period] : 0,
          "trialDurationUnit": selected.trialDurationUnit ? selected.trialDurationUnit[period] : ""
        }
        Object.keys(checkouts).forEach(key => {
          commit("checkout/SET_CHECKOUT_BY_KEY", {
            key: key,
            value: checkouts[key]
          }, { root: true })
        })
        commit("checkout/SET_ACTION", "create", { root: true });
        commit("alert/SET_GOTO_AFTER_LOGIN_ROUTER", "/checkout", { root: true });
        commit("SET_HAS_PENDING_SUBSCRIPTION", true);
      }
      // pending Sub
      dispatch("alert/stopLoading", {}, { root: true });
      commit("SET_CREDENTIALS", {});
      commit("SET_MFA_CODE", "");
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger", 
        message: "Invalid username or password. Please try again." 
      }, {
          root: true 
        }
      );
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async getUser({ commit, dispatch }) {
    try {
      const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/auth/getUser`, {
        withCredentials: true
      });
      const currentUser = response.data;
      if (currentUser.role === "admin") {
        await dispatch("adminUsers/getHotbotNumberObject", {}, { root: true });
      }
      if (currentUser.username) {
        const rawPhone = currentUser.phone;
        const phone = rawPhone.replace(/\+/, "");
        const formattedUser = { ...currentUser, phone };
        commit("SET_USER", formattedUser)
      } else {
        commit("SET_USER", {});
      }
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger", message: "Cannot retrieve this user. Please try again later." }, {
          root: true 
        }
      );
      commit("SET_USER", {});
    }
  },
  async signup({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      const data = {
        username: context.username,
        password: context.password,
        firstName: context.firstName,
        lastName: context.lastName,
        email: context.email,
        phone: "+" + context.phone,
        company: context.company,
        isDisplayOwner: context.isDisplayOwner,
        optIn: context.optIn,
        plan: context.plan
      };
      const response = await axios.post(`${process.env.VUE_APP_SERVER_URL}/auth/signup`, data, {
        withCredentials: true
      });
      if (response.status === 200) {
        dispatch("alert/getAlert", {
          alert: "success",
          message: "Please check your inbox and confirm your email address."
        }, { root: true });
        dispatch("alert/stopLoading", {}, { root: true });
        return true;
      } else {
        dispatch("alert/getAlert", {
          alert: "danger", message: "There was a problem while creating new user. Please try again later." }, {
            root: true 
          }
        );
        dispatch("alert/stopLoading", {}, { root: true });
        return false;
      }
    } catch (error) {
      let message = "";
      if (error.response.data.code === "UsernameExistsException") {
        message = "This username exists already. Please choose another username.";
      } else if (error.response.data.code === "EmailExistsException") {
        message = "Sorry but that email already exists"
      } else if (error.response.data.code === "InvalidParameterException") {
        message = error.response.data.message;
      }
      dispatch("alert/getAlert", {
        alert: "danger", message: message }, {
          root: true 
        }
      );
      dispatch("alert/stopLoading", {}, { root: true });
      return false;
    }
  },
  async confirmUser({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.post(`${process.env.VUE_APP_SERVER_URL}/auth/confirm`, {
        username: context.username,
        confirmationCode: context.confirmationCode
      }, {
        withCredentials: true
      })
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Your email was successfully confirmed. Please login to start using your account."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger", message: "There was a problem confirming new user. Please try again later." }, {
          root: true 
        }
      );
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async getBraintreeId({ commit }) {
    const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/auth/check-braintree-id`, {
      withCredentials: true
    });
    const hasBraintreeId = response.data.hasBraintreeId;
    commit("SET_HAS_BRAINTREE_ID", hasBraintreeId);
  },
  async findPassword({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      let response = await axios.post(`${process.env.VUE_APP_SERVER_URL}/auth/forgot-password`, { 
        username: context.username 
      }, {
        withCredentials: true
      });
      dispatch("alert/getAlert", {
        alert: response.data.alert,
        message: response.data.message
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger", message: error }, {
          root: true 
        }
      );
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async updateMFA({ dispatch }, context) {
    try {
      await axios.put(`${process.env.VUE_APP_SERVER_URL}/auth/update-mfa`, {
        mfa: context.mfa
      }, {
        withCredentials: true
      })
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger", message: "Failed to update user info. Please try again later." }, {
          root: true 
        }
      );
    }
  },
  async updateUserInfo({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.post(`${process.env.VUE_APP_SERVER_URL}/auth/update`, {
        firstName: context.firstName,
        lastName: context.lastName,
        email: context.email,
        phone: context.phone,
        company: context.company
      }, {
        withCredentials: true
      });
      await dispatch("updateMFA", { mfa: context.mfa });
      await dispatch("adminUsers/updateHotbotNumber", { hotbotNumber: context.hotbotNumber }, { root: true })
      await dispatch("getUser");
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully updated user info."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger", message: "Failed to update user info. Please try again later." }, {
          root: true 
        }
      );
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async removeHasCustomerPortal({ dispatch }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.put(`${process.env.VUE_APP_SERVER_URL}/auth/remove-customer-portal-access`, {
        withCredentials: true
      });
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Your access to Customer Portal is removed."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async setNewPassword({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      let response = await axios.post(`${process.env.VUE_APP_SERVER_URL}/auth/confirm-forgot-password`, {
        username: context.username,
        password: context.password,
        confirmationCode: context.confirmationCode
      });
      dispatch("alert/getAlert", {
        alert: response.data.alert,
        message: response.data.message
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
      if (response.data.alert === "success") {
        return true;
      }
    } catch (error) {
      let message;
      if (error.response.data.includes("UserNotFoundException")) {
        message = "This username does not exist.";
      } else if (error.response.data.includes("LimitExceededException")) {
        message = "You have used all your attempts. Please try again later."
      } else {
        message = error
      }
      dispatch("alert/getAlert", {
        alert: "danger",
        message: message
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
      return false;
    }
    
  },
  async logout({ commit, dispatch }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.post(`${process.env.VUE_APP_SERVER_URL}/auth/logout`, {}, {
        withCredentials: true
      });
      commit("SET_USER", {});
      commit("adminUsers/SET_HOTBOT_NUMBER_OBJECT", {}, { root: true });
      commit("billing/SET_CURRENT_SUBSCRIPTION_INFO_LOADED", false, { root: true });
      commit("projects/SET_PROJECTS", [], { root: true });
      dispatch("magicinfo/disconnectMagicinfo", {}, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger", message: "Failed to logout. Please try again later." }, {
          root: true 
        }
      );
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}