import axios from "axios";

const state = {

}

const getters = {

}

const mutations = {

}

const actions = {
  async resetTexting({ dispatch }) {
    try {
      console.log('a');
      dispatch("alert/startLoading", {}, { root: true });
      await axios.put(`${process.env.VUE_APP_SERVER_URL}/api/ticker/reset-ticker`, {}, {
        withCredentials: true
      })
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully reset text."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}