<template>
  <div class="col-lg-4" id="step" :style="{backgroundColor: step.backgroundColor}">
    <div id="step__text" class="row">
      <span id="step__number">{{step.id}}</span>
      <div class="col d-flex flex-column justify-content-center">
        <img
          class="mx-auto"
          id="step__icon"
          v-bind:src="step.icon"
          :style="{width: step.size, height: step.size}"
        >
        <h5 class="mx-auto">{{step.title}}</h5>
        <p class="mx-auto text-center">{{step.subtext}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["step"],
  setup() {
    
  }
};
</script>

<style scoped>
* {
  font-weight: bold;
}
img {
  color: black;
}
.row {
  padding: 0;
  margin: 0;
}
#step {
  height: 170px;
  padding: 0;
}
#step__icon {
  height: 50px;
  width: 50px;
}
#step__number {
  font-size: 20px;
  margin-bottom: -3ex;
  margin-left: -5ex;
  color: #000000;
  opacity: 0.4;
}
#step__text {
  max-width: 205px;
  padding: 20px 0 0;
  margin: 0 auto 0 auto;
}
h5 {
}
p {
  color: black;
  font-size: 12px;
}
</style>
