const state = {
  projectName: "",
  fileFormat: "",
  matrix: [],
  target: [],
  colors: [],
  fileRowID: "1",
  fullDate: "",
  renderStatus: "ready",
  frameNumber: 0,
  texts: [],
  images: [],
  videos: [],
  attach: "0",
  aep: "",
  bannerToken: "",
  magicinfoToken: "",
  magicinfoServer: "",
  magicinfoUsername: null,
  magicinfoOrganizationId: null,
  customerName: "",
  customerEmail: "",
  customerKey: "",
  rgb: null,
  refreshed: true
}

const getters = {
  getDataClay: state => {
    return {
      projectName: state.projectName,
      fileFormat: state.fileFormat,
      matrix: state.matrix,
      target: state.target,
      colors: state.colors,
      fileRowId: state.fileRowID,
      fullDate: state.fullDate,
      renderStatus: state.renderStatus,
      frameNumber: state.frameNumber,
      texts: state.texts,
      images: state.images,
      videos: state.videos,
      attach: state.attach,
      aep: state.aep,
      bannerToken: state.bannerToken,
      magicinfoToken: state.magicinfoToken,
      magicinfoServer: state.magicinfoServer,
      magicinfoUsername: state.magicinfoUsername,
      magicinfoOrganizationId: state.magicinfoOrganizationId,
      customerName: state.customerName,
      customerEmail: state.customerEmail,
      customerKey: state.customerKey,
      rgb: state.rgb
    }
  },
  getRefreshed: state => {
    return state.refreshed;
  }
}

const mutations = {
  RESET_DATACLAY(state) {
    state.projectName = "";
    state.fileFormat = "";
    state.matrix = [];
    state.target = [];
    state.colors = [];
    state.fileRowID = "1";
    state.fullDate = "";
    state.renderStatus = "ready";
    state.frameNumber = 0;
    state.texts = [];
    state.images = [];
    state.videos = [];
    state.attach = "0";
    state.aep = "";
    state.bannerToken = "";
    state.magicinfoToken = "";
    state.magicinfoServer = "";
    state.magicinfoUsername = null;
    state.magicinfoOrganizationId = null;
    state.customerName = "";
    state.customerEmail = "";
    state.customerKey = "";
    state.rgb = null;
  },
  RESET_MAGICINFO_DATACLAY(state) {
    state.magicinfoToken = "";
    state.magicinfoServer = null;
    state.magicinfoUsername = null;
    state.magicinfoOrganizationId = null;
  },
  SET_INITIAL_DATACLAY(state, { user, template }) {
    state.customerName = user.firstName;
    state.customerEmail = user.email;
    state.customerKey = user.id;
    state.aep = template.aep;
    state.frameNumber = template.frame_number;
  },
  SET_DATACLAY_VALUE(state, { key, index, value }) {
    if (index === undefined) {
      state[key] = value;
    } else {
      state[key][index] = value;
    }
  },
  SET_TARGET_SIZE(state, matrices) {
    if (matrices[0] === undefined) {
      return;
    }
    if (typeof matrices != "object") {
      matrices = [matrices];
    }
    let targets = [];
    for (let i = 0; i < matrices.length; i++) {
      const greatestCommonDenominator = (a, b) => {
        return b == 0 ? a : greatestCommonDenominator(b, a % b);
      };
      const [width, height] = [matrices[i].width, matrices[i].height];
      const gcd = greatestCommonDenominator(width, height);
      // const aspectRatio = width / gcd + ":" + height / gcd;
      const hByGCD = height / gcd;
      const wByGCD = width / gcd;
      const result = Number((wByGCD / hByGCD).toFixed(2));
      let target;
      if (width >= 450) {
        switch (true) {
          case result <= 1.16:
            target = "1920x1920";
            break;
          case result > 1.16 && result <= 1.56:
            target = "1920x1440";
            break;
          case result > 1.56 && result <= 1.89:
            target = "1920x1080";
            break;
          case result > 1.89 && result <= 2.18:
            target = "1920x960";
            break;
          case result > 2.18 && result <= 2.53:
            target = "1920x818";
            break;
          case result > 2.53 && result <= 3.04:
            target = "1920x710";
            break;
          case result > 3.04 && result <= 3.69:
            target = "1920x568";
            break;
          case result > 3.69 && result <= 4.4:
            target = "1920x480";
            break;
          case result > 4.4:
            target = "1920x356";
            break;
          default:
            console.log("Error");
            break;
        }
      } else {
        switch (true) {
          case result <= 1.16:
            target = "432x432";
            break;
          case result > 1.16 && result <= 1.67:
            target = "432x324";
            break;
          case result > 1.67 && result <= 2.18:
            target = "432x216";
            break;
          case result > 2.18 && result <= 2.53:
            target = "432x184";
            break;
          case result > 2.53 && result <= 3.04:
            target = "432x160";
            break;
          case result > 3.04 && result <= 3.69:
            target = "432x128";
            break;
          case result > 3.69 && result <= 4.4:
            target = "432x108";
            break;
          case result > 4.4:
            target = "432x80";
            break;
          default:
            console.log("Error");
            break;
        }
      }
      targets.push(target);
    }
    state.target = targets;
  },
  SET_REFRESHED(state, refreshed) {
    state.refreshed = refreshed;
  }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}