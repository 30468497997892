<template>
  <nav class="navbar" :class="currentRouteName === 'home' ? 'navbar-container' : 'navbar-container-non-homepage'">
    <div class="container-fluid">
      <BrandLogo />
      <button v-if="windowWidth < 800" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <icon icon="list" style="color: white" />
      </button>
      <div :class="[windowWidth < 800 ? 'collapse navbar-collapse' : 'navbar-list-container']" id="navbarSupportedContent">
        <ul :class="[windowWidth < 800 ? 'navbar-list-collapse' : 'navbar-list']">
          <li class="nav-item" v-if="isLoggedIn && isAdmin">
            <router-link class="nav-link" to="/admin/templates">Admin</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/pricing">Pricing</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/library">Library</router-link>
          </li>
          <li class="nav-item" v-if="isLoggedIn">
            <router-link class="nav-link" :to="`/customer-portal/${currentUsername}`">My Portal</router-link>
          </li>
          <li v-if="windowWidth > 800">
            |
          </li>
          <li class="nav-item" v-if="!isLoggedIn">
            <a class="nav-link" href="/login">Sign In</a>
          </li>
          <li class="nav-item dropdown" v-if="isLoggedIn">
            <span>
              Account
            </span>
            <div class="dropdown-content">
              <router-link to="/account/profile">Profile</router-link>
              <router-link to="/account/preferences">Preferences</router-link>
              <router-link to="/account/billing">Billing</router-link>
              <router-link to="/account/redeem-key">Redeem</router-link>
              <router-link @click="logout" to="/">Logout</router-link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import BrandLogo from "@/components/BrandLogo.vue";

const store = useStore();
const router = useRouter();

const windowWidth = ref(null);
let currentRouteName = ref("");

// states
const currentUsername = computed(() => store.state.user.currentUser.username);
// getters
const isLoggedIn = computed(() => store.getters["user/isLoggedIn"]);
const isAdmin = computed(() => store.getters["user/isAdmin"]);
// methods
const logout = async () => {
  store.commit("billing/SET_CURRENT_SUBSCRIPTION", null);
  await store.dispatch("user/logout");
}

windowWidth.value = window.innerWidth;
window.addEventListener("resize", () => {
  windowWidth.value = window.innerWidth;
});

watch(
  () => router.currentRoute.value.name, newPath => {
    currentRouteName.value = newPath;
  }
)

// created
currentRouteName.value = router.currentRoute.value.name;


</script>

<style scoped lang="scss">
.navbar-container {
  font-weight: bold;
  color: white;
  width: 100%;
  position: absolute;
  z-index: 1;
  // border-bottom: 0.2px solid rgb(255, 255, 255);
  // background-color: rgb(1, 1, 1);
}
.navbar-container-non-homepage {
  font-weight: bold;
  color: rgb(255, 255, 255);
  width: 100%;
  background-color: rgb(1, 1, 1);
  // position: absolute;
  // z-index: 1;
}
.container-fluid {
  width: 80%;
  display: flex;
  margin: auto;
}
.navbar-list-container {
  margin-top: auto;
}
.navbar-list {
  display: flex;
  list-style-type: none;
  align-items: center;
  font-family: Gill Sans, sans-serif;
  .nav-item {
    padding: 0 1em;
    font-size: 14px;
    &:hover {
      color: rgb(163, 163, 221);
    }
  }
  .nav-item:last-child {
    padding-right: 0;
  }
}

.dropdown {
  cursor: pointer;
}
.navbar-list-collapse {
  list-style-type: none;
  a {
    &:hover {
      background-color: #ddd;
    }
  }
}
.dropdown-content {
  display: none;
  position: absolute;
  left: 0;
  min-width: 115px;
  background-color: white;
  border: 1px solid rgb(220, 217, 217);
  z-index: 1;
}
.dropdown-content a {
  color: black;
  padding: 12px;
  text-decoration: none;
  display: block;
  &:hover {
    background-color: #ddd;
  }
}
.dropdown {
  &:hover {
    .dropdown-content {
      display: block;
    }
  }
}
@media only screen and (max-width: 800px) {
  .dropdown-content {
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    min-width: 115px;
    background-color: white;
    border: 1px solid rgb(220, 217, 217);
  }
}
</style>