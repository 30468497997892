import axios from "axios";
import jsFileDownLoad from 'js-file-download';
import FormData from "form-data";

const state = {
  rootPath: "",
  thumbnailRootPath: process.env.VUE_APP_S3_CUSTOMER_PORTAL_THUMBNAIL_FOLDER,
  currentPath: "",
  currentPathForMovingObjects: "",
  isAdmin: "",
  search: "",
  isEligibleUser: false,
  upDownloading: false,
  moving: false,
  selecting: false,
  upDownloadingProgress: 0,
  upDownloadingSize: 0,
  allItems: [],
  selectedItems: {},
  folderItemsForMovingObject: [],
  currentFilters: [],
  currentView: "Lists",
  itemsPerPage: 20,
  currentPage: 1,
  currentMaxKeys: 500,
  statusDismissCountDown: 0,
  currentFile: {},
  newFolderName: "",
  newItems: {},
  imageTypes: ["jpg", "jpeg", "png"],
  videoTypes: ["mp4", "wmv", "avi"],
  itemsPerPageList: [
    {name: 10},
    {name: 20},
    {name: 50}
  ],
  isMyPortal: false
}

const getters = {
  formattedAllItems: state => {
    let allItems = [];
    Object.keys(state.allItems).forEach(fileName => {
      let item = state.allItems[fileName];
      item.selected = state.allItems[fileName].selected ? true : false;
      allItems.push(item);
    })
    if (state.currentFilters.length !== 0) {
      allItems = allItems.filter(item => state.currentFilters.includes(item.type));
    }
    if (state.search) {
      return allItems.filter(
        item => item.name.toLowerCase().includes(state.search.toLowerCase())
      )
    }
    let currentPageFirstItem = (state.currentPage - 1) * state.itemsPerPage;
    return allItems.slice(currentPageFirstItem, currentPageFirstItem + state.itemsPerPage);
  },
  getTotalNumberOfItemsOnTable: state => {
    let allItems = [];
    Object.keys(state.allItems).forEach(fileName => {
      let item = state.allItems[fileName];
      allItems.push(item);
    })
    if (state.currentFilters.length !== 0) {
      allItems = allItems.filter(item => state.currentFilters.includes(item.type));
    }
    if (state.search) {
      return allItems.filter(
        item => item.name.toLowerCase().includes(state.search.toLowerCase())
      )
    }
    return allItems.length;
  },
  getTotalNumberOfPaginatioinNumber: (state, getters) => {
    return Math.ceil(getters.getTotalNumberOfItemsOnTable / state.itemsPerPage)
  },
  filePath: state => {
    let path = state.currentPath.split('/');
    if (state.isAdmin) {
      path = path.slice(0, -1);
    } else {
      path = path.slice(1, -1);
    }
    path[0] = "Home";
    return path;
  },
  someItemsSelected: state => {
    return Object.keys(state.selectedItems).length > 0;
  },
  getIsMyPortal: state => {
    return state.isMyPortal;
  },
  getUpDownloading: state => {
    return state.upDownloading;
  },
  getUpDownloadingProgress: state => {
    return state.upDownloadingProgress;
  },
  getCurrentPath: state => {
    return state.currentPath;
  },
  getRootPath: state => {
    return state.rootPath;
  }
}

const mutations = {
  SET_ROOT_PATH(state, rootPath) {
    state.rootPath = rootPath;
  },
  SET_CURRENT_PATH(state, currentPath) {
    state.currentPath = currentPath;
  },
  SET_CURRENT_PAGE(state, page) {
    state.currentPage = page;
  },
  SET_CURRENT_FILE(state, file) {
    state.currentFile = file;
  },
  SET_CURRENT_VIEW(state, view) {
    state.currentView = view;
  },
  SET_IS_ADMIN(state, bool) {
    state.isAdmin = bool;
  },
  SET_SEARCH(state, search) {
    state.search = search;
  },
  SET_ALL_ITEMS(state, items) {
    state.allItems = items;
  },
  SET_NEW_FOLDER_NAME(state, newFolderName) {
    state.newFolderName = newFolderName;
  },
  SET_NEW_ITEMS(state, newItems) {
    state.newItems = newItems;
  },
  SET_ITEMS_PER_PAGE(state, itemsPerPage) {
    state.itemsPerPage = itemsPerPage;
  },
  SET_MOVING(state, moving) {
    state.moving = moving;
  },
  SET_SELECTING(state, selecting) {
    state.selecting = selecting;
  },
  SET_SELECTED_ITEMS(state, items) {
    state.selectedItems = items;
  },
  TOGGLE_CURRENT_FILTERS(state, filter) {
    let formattedFilter = filter.toLowerCase().slice(0, -1);
    if (state.currentFilters.includes(formattedFilter)) {
      state.currentFilters.splice(state.currentFilters.indexOf(formattedFilter), 1);
      return;
    }
    state.currentFilters.push(formattedFilter);
  },
  SET_UP_DOWNLOADING(state, upDownloading) {
    state.upDownloading = upDownloading;
  },
  SET_UP_DOWNLOADING_PROGRESS(state, progress) {
    state.upDownloadingProgress = progress;
  },
  SET_UP_DOWNLOADING_SIZE(state, size) {
    state.upDownloadingSize = size;
  },
  SET_CURRENT_PATH_FOR_MOVING_OBJECTS(state, currentPathForMovingObjects) {
    state.currentPathForMovingObjects = currentPathForMovingObjects;
  },
  SET_FOLDER_ITEMS_FOR_MOVING_OBJECTS(state, folderItemsForMovingObjects) {
    state.folderItemsForMovingObjects = folderItemsForMovingObjects;
  },
  UNSELECT_ALL(state) {
    Object.keys(state.allItems).forEach(fileName => {
      state.allItems[fileName].selected = false;
    })
  },
  SET_IS_MY_PORTAL(state, isMyPortal) {
    state.isMyPortal = isMyPortal;
  },
  RESET_CURRENT_FILTERS(state) {
    state.currentFilters = [];
  },
  RESET_PAGINATION(state) {
    state.currentPage = 1;
    state.itemsPerPage = 20;
  }
}

const actions = {
  async getItems({ state, commit, dispatch }, { folderName, startAfter }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      let path;
      if (folderName === undefined) {
        path = state.currentPath;
      } else if (folderName === ".") {
        path = state.rootPath;
      } else if (folderName === -1) {
        path = state.currentPath.split("/").slice(0, -2).join("/")+"/";
      } else {
        path = folderName;
      }
      commit("SET_CURRENT_PATH", path);
      let params = {
        path: path,
        maxKeys: state.currentMaxKeys
      }
      if (startAfter) {
        params = {
          ...params,
          startAfter: startAfter
        }
      }
      let response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/customer-portal`, {
        params: params,
        withCredentials: true
      });
      let allItems = {};
      let allRetrievedItems = response.data.filter(x => {
        if (x.Prefix) {
          return x.Prefix.slice(path.length) !== state.newFolderName+"/";
        } else {
          return !Object.keys(state.newItems).includes(x.Key.slice(path.length));
        }
      })
      if (state.newFolderName !== "") {
        allItems[state.newFolderName] = {
          name: state.newFolderName + "/",
          type: "folder",
          imageSrc: "",
          size: 0,
          path: path,
          selected: false,
          newItem: true
        }
      }
      if (Object.keys(state.newItems).length !== 0) {
        for (let fileName in state.newItems) {
          let fileType = fileName.split(".").at(-1);
          let type = "file";
          if (state.imageTypes.includes(fileType)) {
            type = "image";
          } else if (state.videoTypes.includes(fileType)) {
            type = "video";
          }
          allItems[fileName] = {
            name: fileName,
            type: type,
            imageSrc: "",
            size: state.newItems[fileName],
            path: path,
            selected: false,
            newItem: true
          }
        }
      }
      for (let item of allRetrievedItems) {
        let name = item.Prefix ? item.Prefix.slice(path.length) : item.Key.slice(path.length);
        let size = item.Size;
        let fileType = name.split(".").at(-1);
        let type = "file";
        if (state.imageTypes.includes(fileType)) {
          type = "image";
        } else if (state.videoTypes.includes(fileType)) {
          type = "video";
        } else if (fileType.at(-1) === "/") {
          type = "folder";
          size = 0;
        }
        if (type !== "folder" && size === 0) {
          continue;
        }
        allItems[name] = {
          name: name,
          type: type,
          imageSrc: "",
          size: size,
          path: path,
          selected: false,
          newItem: false
        }
      }
      dispatch("alert/stopLoading", {}, { root: true });
      commit("SET_ALL_ITEMS", allItems);
      commit("SET_NEW_FOLDER_NAME", "");
      commit("SET_NEW_ITEMS", []);
      dispatch("unselectAndCancel");
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Failed to load folder. Please try again later."
      }, { root: true });
    }
  },
  async getFolderItemsForMovingObjects({ state, commit, dispatch }, { folderName, purpose }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      let path;
      if (folderName === undefined) {
        path = state.currentPathForMovingObjects;
      } else if (folderName === ".") {
        path = state.rootPath;
      } else {
        path = folderName;
      }
      commit("SET_CURRENT_PATH_FOR_MOVING_OBJECTS", path);
      let params = {
        path: path,
        maxKeys: 100,
        purpose: purpose
      }
      let response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/customer-portal`, {
        params: params,
        withCredentials: true
      });
      let allFolders = [];
      response.data.forEach((folder) => {
        let item = {
          name: folder.Prefix.slice(path.length),
          path: path
        }
        allFolders.push(item);
      });
      commit("SET_FOLDER_ITEMS_FOR_MOVING_OBJECTS", allFolders);
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Failed to load folder. Please try again later."
      }, { root: true });
    }
  },
  async createNewFolder({ state, commit, dispatch }, { newFolderName, purpose }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.post(`${process.env.VUE_APP_SERVER_URL}/api/customer-portal/create-new-folder`, {
        folderName: purpose === "CREATE" ? `${state.currentPath}${newFolderName}/` : `${state.currentPathForMovingObjects}${newFolderName}`
      }, {
        withCredentials: true
      });
      commit("SET_NEW_FOLDER_NAME", newFolderName);
      if (purpose === "CREATE") {
        await dispatch("getItems", {});
        // dispatch("unselectAndCancel");
        dispatch("alert/getAlert", {
          alert: "success",
          message: `Created folder ${newFolderName}`
        }, { root: true });
      } else {
        await dispatch("getFolderItemsForMovingObjects", {purpose: "MOVE_FROM"})
      }
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Failed to create new folder. Please try again later."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async uploadItems({ state, commit, dispatch }, files) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      commit("SET_UP_DOWNLOADING", true);
      let totalRequests = Object.keys(files).length;
      let totalSizeLoaded = 0;
      let newItems = {};
      for (const key of Object.keys(files)) {
        const formData = new FormData();
        formData.append("keyPath", key);
        for (const file of files[key]) {
          formData.append("file", file.file);
          newItems[file.file.name] = file.file.size;
        }
        await axios.post(`${process.env.VUE_APP_SERVER_URL}/api/customer-portal/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: async progressEvent => {
            const percentage = Math.round(
              ((totalSizeLoaded + progressEvent.loaded) * 100) / state.upDownloadingSize
            );
            commit("SET_UP_DOWNLOADING_PROGRESS", percentage);
            if (progressEvent.loaded === progressEvent.total) {
              totalSizeLoaded += progressEvent.loaded;
              totalRequests -= 1;
              if (totalRequests === 0) {
                commit("SET_UP_DOWNLOADING_PROGRESS", 100);
                setTimeout(() => {
                  commit("SET_UP_DOWNLOADING", false);
                  commit("SET_UP_DOWNLOADING_PROGRESS", 0)
                }, 500)
              }
            }
          },
          withCredentials: true
        })
      }
      commit("SET_NEW_ITEMS", newItems);
      await dispatch("getItems", {});
      dispatch("alert/getAlert", {
        alert: "success",
        message: "successully uploaded files."
      }, { root: true });
      commit("SET_UP_DOWNLOADING", false);
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Failed to upload files. Please try again later."
      }, { root: true });
      commit("SET_UP_DOWNLOADING", false);
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async downloadItems({ state, commit, dispatch }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      let allSelected = [];
      let totalComplete = 0;
      let numberOfFiles = 0;
      let totalSize = 0;
      commit("SET_UP_DOWNLOADING", true);
      for (let item of Object.values(state.selectedItems)) {
        if (item.type === "folder") {
          let allSelectedInFolder = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/customer-portal`, {
            params: {
              path: `${item.path}${item.name}`,
              maxKeys: state.currentMaxKeys,
              purpose: "DELETE"
            },
            withCredentials: true
          });
          for (let i of allSelectedInFolder.data) {
            totalSize += i.Size;
            numberOfFiles += 1;
            let type = "folder";
            if (i.Key.split("/").at(-1)) {
              type = state.videoTypes.includes(i.Key.split(".").at(-1)) ? "video" : "other";
            }
            let item = {
              name: i.Key.split("/").at(-1),
              path: i.Key.split("/").slice(0, -1).join("/")+"/",
              type: type
            }
            allSelected.push(item);
          }
        } else {
          allSelected.push(item);
          numberOfFiles += 1;
          totalSize += item.size;
        }
      }
      commit("SET_UP_DOWNLOADING_SIZE", totalSize);
      for (let item of allSelected) {
        if (item.type !== "folder") {
          let currentFileSize = 0;
          let response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/customer-portal/${item.path}${item.name}`, {
            responseType: "blob",
            onDownloadProgress: progressEvent => {
              currentFileSize = totalComplete + progressEvent.loaded;
              const percentage = Math.round(
                (currentFileSize * 100) / state.upDownloadingSize
              );
              commit("SET_UP_DOWNLOADING_PROGRESS", percentage);
              if (progressEvent.loaded >= progressEvent.total) {
                totalComplete += progressEvent.total;
                numberOfFiles -= 1;
                if (numberOfFiles === 0) {
                  commit("SET_UP_DOWNLOADING_PROGRESS", 100);
                  setTimeout(() => {
                    commit("SET_UP_DOWNLOADING", false);
                    commit("SET_UP_DOWNLOADING_PROGRESS", 0)
                  }, 500)
                }
              }
            },
            withCredentials: true
          })
          jsFileDownLoad(response.data, `${item.name}`);
        }
      }
      dispatch("alert/getAlert", {
        alert: "success",
        message: "successully downloaded files."
      }, { root: true });
      dispatch("unselectAndCancel");
      commit("SET_UP_DOWNLOADING", false);
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Failed to download files. Please try again later."
      }, { root: true });
      commit("SET_UP_DOWNLOADING", false);
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async moveItems({ state, commit, dispatch }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      commit("SET_MOVING", true);
      let allSelected = [];
      for (let item of Object.values(state.selectedItems)) {
        if (item.type === "folder") {
          let allSelectedInFolder = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/customer-portal`, {
            params: {
              path: `${item.path}${item.name}`,
              maxKeys: state.currentMaxKeys,
              purpose: "MOVE_TO"
            },
            withCredentials: true
          });
          for (let i of allSelectedInFolder.data) {
            let name = i.Key.split("/").at(-1);
            let path = i.Key.split("/").slice(0, -1).join("/")+"/";
            let type = "other";
            if (state.videoTypes.includes(i.Key.split(".").at(-1))) {
              type = "video";
            } else if (i.Key.slice(-1) === "/" && i.Size === 0) {
              type = "folder";
            }
            let currentPath = path.split("/");
            let extraPath = "";
            for (let i = currentPath.length-2; i >= 0; i--) {
              if (currentPath[i]+"/" === item.name) {
                break;
              }
              extraPath = currentPath[i] + "/" + extraPath;
            }
            let newItem = {
              type: type,
              from: path + name,
              to: state.currentPathForMovingObjects + item.name + extraPath + name
            }
            allSelected.push(newItem);
          }
        } else {
          let newItem = {
            ...item,
            from: item.path + item.name,
            to: state.currentPathForMovingObjects + item.name
          }
          allSelected.push(newItem);
        }
      }
      await axios.put(`${process.env.VUE_APP_SERVER_URL}/api/customer-portal/move-files`, {
        files: allSelected },
        {
          withCredentials: true
      });
      await dispatch("deleteItems");
      dispatch("unselectAndCancel");
      dispatch("alert/getAlert", {
        alert: "success",
        message: "successully moved files."
      }, { root: true });
      commit("SET_MOVING", false);
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Failed to move files. Please try again later."
      }, { root: true });
      commit("SET_MOVING", false);
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async deleteItems({ state, dispatch }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      let allSelected = [];
      for (let item of Object.values(state.selectedItems)) {
        if (item.type === "folder") {
          let allSelectedInFolder = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/customer-portal`, {
            params: {
              path: `${item.path}${item.name}`,
              maxKeys: state.currentMaxKeys,
              purpose: "DELETE"
            },
            withCredentials: true
          });
          for (let i of allSelectedInFolder.data) {
            let item = {
              name: i.Key.split("/").at(-1),
              path: i.Key.split("/").slice(0, -1).join("/")+"/",
              type: state.videoTypes.includes(i.Key.split(".").at(-1)) ? "video" : "other"
            }
            allSelected.push(item);
          }
        } else {
          allSelected.push(item);
        }
      }
      console.log(allSelected);
      await axios.delete(`${process.env.VUE_APP_SERVER_URL}/api/customer-portal/delete`, {
        data: { files: allSelected },
        withCredentials: true
      });
      await dispatch("getItems", {});
      dispatch("unselectAndCancel");
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully deleted selected files."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Failed to delete selected files. Please try again later."
      }, { root: true });
    }
  },
  unselectAndCancel({ commit }) {
    commit("SET_MOVING", false);
    commit("SET_SELECTING", false);
    commit("SET_UP_DOWNLOADING", false);
    commit("SET_UP_DOWNLOADING_PROGRESS", 0);
    commit("SET_UP_DOWNLOADING_SIZE", 0);
    commit("SET_SELECTED_ITEMS", {});
    commit("RESET_CURRENT_FILTERS");
    commit("SET_SEARCH", "");
    commit("UNSELECT_ALL");
    commit("SET_CURRENT_PATH_FOR_MOVING_OBJECTS", "");
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}