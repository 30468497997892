<template>
  <div class="customer-portal-file-detail">
    <div class="customer-portal-file-detail-body">
      <img v-if="currentFile.type === 'image'"
        :src="currentFile.media"
        :alt="`${currentFile.name}`"
        class="image-object"
      />
      <video
        v-else-if="currentFile.type === 'video'"
        autoplay
        controls
        :src="currentFile.media"
        class="image-object"
        preload="none"
      />
      <div v-else><i>Preview Not Available</i></div>
    </div>
    <div class="customer-portal-file-detail-footer">
      <button type="button" class="modified-button" id="close-button" @click="closeModal">
        Close
      </button>
      <button type="button" class="modified-button" id="download-button" @click="downloadFile">
        Download
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import jsFileDownLoad from 'js-file-download';
// setup
const store = useStore();
const currentFile = ref({});
// states
const currentCustomerPortalFile = computed(() => store.state.customerPortal.currentFile);
const currentPath = computed(() => store.state.customerPortal.currentPath);
// getters
const currentUsername = computed(() => store.getters["user/getUsername"]);
const currentProjectFile = computed(() => store.getters["projects/getCurrentFile"]);
const isMyPortal = computed(() => store.getters["customerPortal/getIsMyPortal"]);
// methods
const downloadFile = async () => {
  let response;
  let fileName;
  if (!isMyPortal.value) {
    fileName = currentCustomerPortalFile.value.name;
    response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/customer-portal/${currentPath.value}${currentCustomerPortalFile.value.name}`, {
      responseType: "blob",
      withCredentials: true 
      }
    );
  } else {
    let format = currentProjectFile.value.format;
    // if (format === "wmv") {
    //   format = "mp4";
    // }
    fileName = `${currentProjectFile.value.name}.${format}`;
    response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/files/projects/${encodeURIComponent(currentUsername.value)}/${currentProjectFile.value.id}.${format}`, {
      responseType: "blob",
      withCredentials: true 
      }
    );
  }
  jsFileDownLoad(response.data, `${fileName}`);
};
const closeModal = () => {
  store.commit("customerPortal/SET_CURRENT_FILE", {});
  store.commit("projects/SET_CURRENT_FILE", {});
  store.dispatch("modal/closeModal");
}

// created
if (!isMyPortal.value) {
  currentFile.value = currentCustomerPortalFile.value;
} else {
  currentFile.value = currentProjectFile.value;
}
</script>

<style scoped lang="scss">
.customer-portal-file-detail {
  .customer-portal-file-detail-body {
    .image-object {
      max-height: 500px;
      max-width: 100%;
      margin-bottom: 1em;
    }
  }
}
.modified-button {
  margin: 0 1ex 1ex 1ex;
}
#download-button {
  width: 40%;
  &:hover {
    background-color: var(--ce-add-button-color);
  }
}
#close-button {
  width: 40%;
  &:hover {
    background-color: var(--ce-cancel-button-color);
  }
}
</style>