<template>
  <div class="container-fluid">
    <div class="info">
      <div class="element" v-for="(key, index) in Object.keys(currentProject)" :key="index">
        <div v-if="key === 'data_clay'">
          <b>{{ key }}:</b>
          <p class="element" v-for="(dKey, dIndex) in Object.keys(JSON.parse(currentProject[key]))" :key="dIndex">
            &ensp;&ensp;&ensp;<b>{{ dKey }}</b>:&nbsp; <i>{{ JSON.parse(currentProject[key])[dKey] }}</i>
          </p>
        </div>
        <div v-else>
          <b>{{ key }}</b>:&nbsp; <i>{{ currentProject[key] }}</i>
        </div>
      </div>
    </div>
    <div class="buttons">
      <button type="button" class="modified-button" id="cancel-button" @click="closeModal">Close</button>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const currentProject = computed(() => store.getters["adminUsers/getCurrentProject"]);

const closeModal = () => {
  store.dispatch("modal/closeModal");
}
</script>

<style scoped lang="scss">
.container-fluid {
  height: 700px;
  max-width: 1000px;
  overflow: scroll;
}
.info {
  text-align: left;
  .element {
    margin: 1em;
    display: flex;
    flex-wrap: wrap;
  }
}
.buttons {
  margin: auto;
}
#cancel-button {
  width: 60%;
  margin: 1em;
  &:hover {
    background-color: var(--ce-cancel-button-color);
  }
}
</style>