<template>
  <div class="card-container card mt-2 mb-3">
    <video
      class="video-card"
      :class="selecting && idIncluded ? 'selected' : 'video'"
      controlslist="nodownload nofullscreen"
      :src="getVideoUrl"
      @mouseover="playVideo"
      @mouseout="stopVideo"
      :poster="getPosterUrl"
      loop="loop"
      preload="none"
      muted
      oncontextmenu="return false"
      @click="selectVideo"
    />
  </div>
  <div class="card-bottom">
    <p class="video-name" v-if="props.video.name.length < 16">{{ props.video.name }}</p>
    <p class="video-name" v-else>{{ props.video.name.slice(0, 16) }}...</p>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const videoType = ref("");
const interval = ref(null);
const props = defineProps({
  video: Object
})

const store = useStore();
const router = useRouter();

const getSelectingFreeTemplates = computed(() => store.getters["adminTemplates/getSelectingFreeTemplates"]);
const getSelectingBespokeTemplates = computed(() => store.getters["adminUsers/getSelectingBespokeTemplates"]);
const getSelectingTemplates = computed(() => store.getters["adminPopularSeasonalVideos/getSelectingTemplates"]);

const currentPage = computed(() => store.getters["adminPopularSeasonalVideos/getCurrentPage"]);
const currentVideo = computed(() => store.getters["templates/getCurrentVideo"]);

const getNewlySelectedFreeTemplateIds = computed(() => store.getters["adminTemplates/getNewlySelectedFreeTemplateIds"]);
const getNewlySelectedTemplateIds = computed(() => store.getters["adminPopularSeasonalVideos/getNewlySelectedTemplateIds"]);
const getNewlySelectedBespokeTemplateIds = computed(() => store.getters["adminUsers/getNewlySelectedBespokeTemplateIds"]);

const freeTemplateIds = computed(() => store.getters["templates/getFreeTemplateIds"]);
const popularVideoIds = computed(() => store.getters["adminPopularSeasonalVideos/getPopularVideoIds"]);
const seasonalVideoIds = computed(() => store.getters["adminPopularSeasonalVideos/getSeasonalVideoIds"]);
const selectedUserBespokeTemplateIds = computed(() => store.getters["adminUsers/getSelectedUserBespokeTemplateIds"]);

// methods
const getVideoUrl = computed(() => {
  return (
    `${process.env.VUE_APP_SERVER_URL}/api/files/` +
    videoType.value +
    "/" +
    props.video.s3_id +
    "/main/360/" +
    props.video.s3_id +
    "_360.mp4"
  );
})
const getPosterUrl = computed(() => {
  return (
    `${process.env.VUE_APP_SERVER_URL}/api/files/` +
    videoType.value +
    "/" +
    props.video.s3_id +
    "/poster/" +
    "poster.jpg"
  );
})
const selecting = computed(() => {
  return getSelectingFreeTemplates.value || getSelectingTemplates.value || getSelectingBespokeTemplates.value;
})
const idIncluded = computed(() => {
  if (currentPage.value === "free") {
    return getNewlySelectedFreeTemplateIds.value.includes(props.video.id)
  }
  if (currentPage.value === "bespoke") {
    return getNewlySelectedBespokeTemplateIds.value.includes(props.video.id);
  }
  return getNewlySelectedTemplateIds.value.includes(props.video.id);
})
const selectVideo = async () => {
  if (selecting.value) {
    toggleSelectTemplate();
    return;
  }
  await store.dispatch("templates/setCurrentVideoById", props.video.id);
  sessionStorage.clear();
  Object.keys(currentVideo.value).forEach(key => {
    sessionStorage.setItem(key, currentVideo.value[key]);
  })
  router.push(`/video/${props.video.id}`);
  window.scrollTo(0, 0);
}
const toggleSelectTemplate = () => {
  let temp = [];
  if (currentPage.value === "free") {
    temp = [...getNewlySelectedFreeTemplateIds.value];
  } else if (currentPage.value === "bespoke") {
    temp = [...getNewlySelectedBespokeTemplateIds.value];
  } else {
    temp = [...getNewlySelectedTemplateIds.value];
  }
  if (temp.includes(props.video.id)) {
    temp.splice(temp.indexOf(props.video.id), 1);
  } else {
    temp.push(props.video.id);
  }
  if (currentPage.value === "free") {
    store.commit("adminTemplates/SET_NEWLY_SELECTED_FREE_TEMPLATE_IDS", temp);
  } else if (currentPage.value === "bespoke") {
    store.commit("adminUsers/SET_NEWLY_SELECTED_BESPOKE_TEMPLATE_IDS", temp);
  } else {
    store.commit("adminPopularSeasonalVideos/SET_NEWLY_SELECTED_TEMPLATE_IDS", temp);
  }
}
const playVideo = (e) => {
  if (e.target.readyState === 0) {
    e.target.load();
  }
  interval.value = setInterval(() => {
    if (e.target.readyState === 4) {
      e.target.play();
      clearInterval(interval.value);
    }
  }, 1);
}
const stopVideo = (e) => {
  if (interval.value != null) {
    clearInterval(interval.value);
  }
  e.target.pause();
}

// created
if (Object.keys(props.video).includes("aep")) {
  videoType.value = "templates";
} else {
  videoType.value = "stock";
}

if (currentPage.value === "free") {
  store.commit("adminTemplates/SET_NEWLY_SELECTED_FREE_TEMPLATE_IDS", freeTemplateIds.value);
} else if (currentPage.value === "popular") {
  store.commit("adminPopularSeasonalVideos/SET_NEWLY_SELECTED_TEMPLATE_IDS", popularVideoIds.value);
} else if (currentPage.value === "seasonal") {
  store.commit("adminPopularSeasonalVideos/SET_NEWLY_SELECTED_TEMPLATE_IDS", seasonalVideoIds.value);
} else if (currentPage.value === "bespoke") {
  store.commit("adminUsers/SET_NEWLY_SELECTED_BESPOKE_TEMPLATE_IDS", selectedUserBespokeTemplateIds.value);
}

onBeforeUnmount(() => {
  store.commit("adminTemplates/SET_NEWLY_SELECTED_FREE_TEMPLATE_IDS", []);
  store.commit("adminPopularSeasonalVideos/SET_NEWLY_SELECTED_TEMPLATE_IDS", []);
  store.commit("adminUsers/SET_NEWLY_SELECTED_BESPOKE_TEMPLATE_IDS", []);
})

</script>

<style scoped lang="scss">
.card-container {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  &:hover {
    transform: scale(1.04);
  }
  position: relative;
  overflow: hidden;
  height: auto;
  width: 100%;
  float: left;
  padding-top: 56%;
}
.video-card {
  // width: 100%;
  // max-height: 135px;
  object-fit: inherit;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  filter: saturate(100%);
}

.video {
  cursor: pointer;
  border-radius: 10px 10px 0 0;
}
.selected {
  cursor: pointer;
  border-radius: 10px 10px 0 0;
  padding: 1em;
  background-color: var(--ce-selected-button-color);
}
.video-name {
  padding: 0.9rem 1.3rem;
  padding: 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  word-wrap: break-word;
}
</style>