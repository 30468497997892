<template>
  <progress class="progress" :value="progress" max="100"> {{ progress }} </progress>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";

const store = useStore();

let progress = ref(null);

const customerPortalProgress = computed(() => store.getters["customerPortal/getUpDownloadingProgress"]);
const projectsProgress = computed(() => store.getters["projects/getDownloadingProgress"]);
const isMyPortal = computed(() => store.getters["customerPortal/getIsMyPortal"]);

if (!isMyPortal.value) {
  progress.value = customerPortalProgress.value;
} else {
  progress.value = projectsProgress.value;
}

watch(() =>
  progress.value, newProgress => {
    console.log(newProgress);
  }
)

</script>

<style scoped lang="scss">
.progress {
  position: fixed;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  width: 50%;
}
</style>