<template>
  <div class="customer-portal-large-icon-list">
    <div class="my-row">
      <div class="my-col"
        v-for="(item, index) in getFormattedProjects" :key="index"
        :class="{'object-selected': selectedItems[item.id]}"
      >
        <icon v-show="item.type === 'video'" icon="circle-play" class="play-circle"/>
        <img
          v-if="item.type !== 'video'"
          class="object-image"
          :class="{'object-image-selected': selectedItems[item.id], 'disabled' : item.status === 'Processing'}"
          :src="getItemImageSrc(item)"
          :alt="item.name"
          @click="clickItem(item)"
        />
        <video
          v-else
          allowfullscreen
          class="object-image"
          :class="{'object-image-selected': selectedItems[item.id], 'disabled' : item.status === 'Processing'}"
          type="video" control
          preload="none"
          autoplay
          :poster="item.status === 'Processing' ? LoadingIcon : VideoIcon"
          @click="clickItem(item)"
        >
          <source type="vieo/mp4">
          <source type="vieo/webm">
        </video>
        <!-- <icon v-show="selecting" class="select-circle" :icon="selectedItems[item.name] ? 'circle' : 'fa-regular fa-circle'" /> -->
        <span v-if="item.name.length < 15"> {{ item.name }} </span>
        <span v-else>{{ item.name.slice(0, 15) }}...</span>
      </div>
    </div>
    <div v-if="getFormattedProjects.length === 0" class="center-item">
      <h3><i>Empty</i></h3>
    </div>
  </div>
</template>

<script setup>
import LoadingIcon from "@/assets/Loading-icon.png";
import VideoIcon from "@/assets/videoIcon.png";
import { computed } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
// setup
const store = useStore();
// const router = useRouter();
// states
// const rootPath = computed(() => store.state.customerPortal.rootPath);
// const thumbnailRootPath = computed(() => store.state.customerPortal.thumbnailRootPath);
const selecting = computed(() => store.state.projects.selecting);
// const isAdmin = computed(() => store.state.customerPortal.isAdmin);
const username = computed(() => store.getters["user/getUsername"]);
// getters
const getFormattedProjects = computed(() => store.getters["projects/getFormattedProjects"]);
const selectedItems = computed(() => store.getters["projects/getSelectedProjects"]);
// methods
const getItemImageSrc = item => {
  if (item.status === 'Processing') {
    return LoadingIcon;
  }
  return `${process.env.VUE_APP_SERVER_URL}/api/files/projects/${encodeURIComponent(username.value)}/${item.id}.${item.format}`;
}
const clickItem = async item => {
  if (selecting.value) {
    let tempSelectedItems = JSON.parse(JSON.stringify(selectedItems.value));
    tempSelectedItems[item.id] ? 
    delete tempSelectedItems[item.id] : 
    tempSelectedItems[item.id] = item;
    store.commit("projects/SET_SELECTED_PROJECTS", tempSelectedItems);
    return;
  }
  let file = {
    name: item.name,
    path: item.path,
    type: item.type,
    format: item.format,
    id: item.id,
    media: `${process.env.VUE_APP_SERVER_URL}/api/files/projects/${encodeURIComponent(username.value)}/${item.id}.${item.format}`
  }
  store.commit("projects/SET_CURRENT_FILE", file);
  store.dispatch("modal/showModal", {
     modalTitle: file.name, 
     modalName: "CustomerPortalFileDetail"
    }
  )
}


</script>

<style scoped lang="scss">
.my-row {
  display: grid;
  grid-template-columns: minmax(150px, max-content) repeat(auto-fill, 150px);
}
.my-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1ex;
}
.customer-portal-large-icon-list {
  min-height: 35ex;
}
.select-circle {
  position: absolute;
}
.play-circle {
  position: absolute;
  color: white;
  transform: translate(0%, 130%);
  font-size: 2em;
}
.center-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.object {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1ex 0;
}
.object-selected {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
}
.disabled {
  opacity: 0.4;
  pointer-events: none;
}
.object-image {
  width: 15ex;
  height: 12ex;
  cursor: pointer;
  flex-basis: fit-content;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  }
}
.object-image-selected {
  padding: 2ex;
}
</style>