<template>
  <div
    v-if="showAlert"
    :class="`alert alert-${alert} d-flex align-items-center fade show`" :role="`${alert}`"
  >
    <div class="xmark">
      <icon icon="xmark" id="xmark" @click="closeAlert"/>
    </div>
    <h4 class="alert-heading">
      <span id="icon"><icon :icon="alertIcon[alert]" /></span>
      <span v-if="alert === 'danger'">Error</span>
      <span v-else>{{ alert[0].toUpperCase() + alert.slice(1) }}</span>
    </h4>
    <div class="alert-body">
      <div>
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

// states
const alert = computed(() => store.state.alert.alert);
const alertIcon = computed(() => store.state.alert.alertIcon);
const message = computed(() => store.state.alert.message);
const showAlert = computed(() => store.state.alert.showAlert);

// methods
const closeAlert = () => {
  store.commit("alert/REMOVE_ALERT");
}

</script>

<style scoped lang="scss">
#icon {
  margin-right: 5px;
}
.xmark {
  width: 100%;
  #xmark {
    float: right;
    cursor: pointer;
  }
}
.alert {
  display: flex;
  flex-direction: column;
  margin: 0;
}
.alert-heading {
  display: flex;
  align-items: center;
}
</style>