<template>
  <div class="container-fluid">
    <div class="category-name">
      <input class="category-name-input" type="text" placeholder="name..." 
        v-model="categoryKeywordName"/>
      <p v-if="categoryKeywordAlreadyExists" :style="{color: 'red'}">'{{ categoryKeywordName }}'' already exists. Please try another name.</p>
    </div>
    <div class="buttons">
      <button type="button" class="modified-button" id="cancel-button" @click="closeModal">
        Cancel
      </button>
      <button v-if="currentRoute === 'categories'" type="button" class="modified-button" id="submit-button" 
        @click="updateCategory">
        <span v-if="selectedCategory">Update</span>
        <span v-else>Create</span>
      </button>
      <button v-else-if="currentRoute === 'keywords'" type="button" class="modified-button" id="submit-button" 
        @click="updateKeyword">
        <span v-if="selectedKeyword">Update</span>
        <span v-else>Create</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

const categoryKeywordName = ref("");
const categoryKeywordAlreadyExists = ref(false);
const currentRoute = ref("");

// getters
const categories = computed(() => store.getters["adminCategories/getCategoryNames"]);
const keywords = computed(() => store.getters["adminKeywords/getKeywordNames"]);
const selectedCategory = computed(() => store.getters["adminCategories/getSelectedCategory"]);
const selectedKeyword = computed(() => store.getters["adminKeywords/getSelectedKeyword"]);

// methods
const updateCategory = async () => {
  if (checkIfCategoryNameExists()) {
    categoryKeywordAlreadyExists.value = true;
    return;
  }
  let storeAction = "addCategory";
  if (selectedCategory.value) {
    storeAction = "updateCategory";
  }
  await store.dispatch(`adminCategories/${storeAction}`, {
    id: selectedCategory.value ? selectedCategory.value.id : null,
    newCategory: categoryKeywordName.value
  })
  categoryKeywordAlreadyExists.value = false;
  closeModal();
}
const updateKeyword = async () => {
  if (checkIfKeywordNameExists()) {
    categoryKeywordAlreadyExists.value = true;
    return;
  }
  let storeAction = "addKeyword";
  if (selectedKeyword.value) {
    storeAction = "updateKeyword";
  }
  await store.dispatch(`adminKeywords/${storeAction}`, {
    id: selectedKeyword.value ? selectedKeyword.value.id : null,
    newKeyword: categoryKeywordName.value
  })
  categoryKeywordAlreadyExists.value = false;
  closeModal();
}
const closeModal = () => {
  store.commit("adminCategories/SET_SELECTED_CATEGORY", null);
  store.commit("adminKeywords/SET_SELECTED_KEYWORD", null);
  store.dispatch("modal/closeModal");
}
const checkIfCategoryNameExists = () => {
  for (let i=0; i<categories.value.length; i++) {
    if (categories.value[i].toLowerCase() === categoryKeywordName.value.toLowerCase()) {
      return true;
    }
  }
  return false;
}
const checkIfKeywordNameExists = () => {
  for (let i=0; i<keywords.value.length; i++) {
    if (keywords.value[i].toLowerCase() === categoryKeywordName.value.toLowerCase()) {
      return true;
    }
  }
  return false;
}

// created
if (selectedCategory.value) {
  categoryKeywordName.value = selectedCategory.value.category;
}
if (selectedKeyword.value) {
  categoryKeywordName.value = selectedKeyword.value.keyword;
}

currentRoute.value = router.currentRoute.value.path.split("/").at(-1);

watch(() => 
  categoryKeywordName.value, () => {
    categoryKeywordAlreadyExists.value = false;
  }
)

</script>

<style scoped lang="scss">
.category-name-input {
  display: flex;
  margin: 0 0 1em 1em;
  width: 50%;
  border: none;
  border-bottom: 1px solid grey;
  &:focus {
    outline: none;
  }
}
button {
  margin: 1ex;
}
#submit-button {
  width: 20%;
  &:hover {
    background-color: var(--ce-add-button-color);
  }
}
#cancel-button {
  width: 20%;
  &:hover {
    background-color: var(--ce-cancel-button-color);
  }
}
</style>