import axios from "axios";

const state = {
  displays: [],
  socialSizes: [],
  currentDisplay: {
    name: "",
    width: 0,
    height: 0,
    id: "",
    user_id: "",
    isMagicinfo: 0
  },
  colors: [],
  currentColor: {},
  currentProcess: ""
}

const getters = {
  getDisplays: state => {
    return state.displays;
  },
  getCurrentDisplay: state => {
    return state.currentDisplay;
  },
  getSocialMediaDisplays: state => {
    return state.socialSizes;
  },
  getColors: state => {
    return state.colors
  },
  displayResolutionNameObject: state => {
    let displays = {};
    state.displays.forEach(display => {
      displays[`${display.width}x${display.height}`] = display.name;
    })
    return displays;
  },
  getSocialMediaSize: state => {
    let socialSize = state.socialSizes[0];
    let temp = {};
    if (socialSize) {
      temp[`${socialSize.width}x${socialSize.height}`] = `(${socialSize.height}p)`;
    }
    return temp;
  },
  getCurrentProcess: state => {
    return state.currentProcess;
  },
  currentDisplayNameExists: state => {
    for (let i=0; i<state.displays.length; i++) {
      if (state.currentDisplay.name === state.displays[i].name) {
        return true;
      }
    }
    return false;
  },
  currentDisplayResolutionExists: state => {
    let temp = [...state.displays];
    if (state.currentProcess === 'update') {
      temp = temp.filter(x => x.id !== state.currentDisplay.id);
    }
    for (let i=0; i<temp.length; i++) {
      if (parseInt(state.currentDisplay.width) === parseInt(temp[i].width) && parseInt(state.currentDisplay.height) === parseInt(temp[i].height)) {
        return true;
      }
    }
    return false;
  }
}


const mutations = {
  GET_USER_PREFERENCES(state, preferences) {
    state.displays = preferences.displays;
    state.colors = preferences.colors;
    state.socialSizes = preferences.socialSizes;
  },
  SET_CURRENT_DISPLAY(state, display) {
    state.currentDisplay = display;
  },
  SET_CURRENT_COLOR(state, color) {
    state.currentColor = color;
  },
  UPDATE_CURRENT_DISPLAY(state, data) {
    state.currentDisplay = { ...state.currentDisplay, ...data };
  },
  UPDATE_CURRENT_COLOR(state, hexCode) {
    state.currentColor.hexCode = hexCode;
  },
  UPDATE_COLORS(state, updatedColors) {
    state.colors = state.colors.map(color => {
      return color.id === updatedColors.id
      ? { id: color.id, hexCode: updatedColors.hexCode }
      : color;
    });
  },
  CLEAR_CURRENT_DISPLAY(state) {
    state.currentDisplay = {
      name: "",
      width: 0,
      height: 0,
      id: "",
      user_id: "",
      isMagicinfo: 0
    };
  },
  CLEAR_CURRENT_COLOR(state) {
    state.currentColor = {};
  },
  SET_CURRENT_PROCESS(state, currentProcess) {
    state.currentProcess = currentProcess;
  }
}

const actions = {
  async getUserPreferences({ commit }) {
    const { data } = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/user-preferences`, {
      withCredentials: true
    });
    commit("GET_USER_PREFERENCES", data);
  },
  async addDisplay({ state, dispatch }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      const display = state.currentDisplay;
      await axios.post(`${process.env.VUE_APP_SERVER_URL}/api/user-preferences/add-display`, display, {
        withCredentials: true
      });
      await dispatch("getUserPreferences");
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully added display."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async updateDisplay({ state, dispatch }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      const display = state.currentDisplay;
      await axios.put(`${process.env.VUE_APP_SERVER_URL}/api/user-preferences/edit-display`, display, {
        withCredentials: true
      });
      await dispatch("getUserPreferences");
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully updated display."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async deleteDisplay({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.delete(`${process.env.VUE_APP_SERVER_URL}/api/user-preferences/delete-display`, {
        data: { id: context.id },
        withCredentials: true
      });
      await dispatch("getUserPreferences");
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully deleted display."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async addColor({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      const hexCode = context.hexCode;
      await axios.post(`${process.env.VUE_APP_SERVER_URL}/api/user-preferences/add-color`, {
        hexCode: hexCode
      }, {
        withCredentials: true
      });
      await dispatch("getUserPreferences");
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully added color."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async updateColor({ commit, dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.put(`${process.env.VUE_APP_SERVER_URL}/api/user-preferences/edit-color`, {
        id: context.id,
        hexCode: context.hexCode
      }, {
        withCredentials: true
      });
      await commit("UPDATE_COLORS", context);
      await dispatch("getUserPreferences");
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully updated color."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async deleteColor({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      for (let i=0; i<context.colorIds.length; i++) {
        await axios.delete(`${process.env.VUE_APP_SERVER_URL}/api/user-preferences/delete-color`, {
          data: { id: context.colorIds[i] },
          withCredentials: true
        })
      }
      await dispatch("getUserPreferences");
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully deleted color."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}