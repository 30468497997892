import axios from "axios";

const state = {
  userList: [],
  allSubscribedUsers: [],
  projects: [],
  inProgress: [],
  stocksDownloaded: []
}

const mutations = {
  SET_SUBSCRIBED_USERS(state, users) {
    state.allSubscribedUsers = users;
  },
  SET_ANALYSIS_PROJECTS(state, projects) {
    state.projects = projects;
  },
  SET_ANALYSIS_USER(state, userList) {
    state.userList = userList;
  },
  SET_IN_PROGRESS(state, inProgress) {
    state.inProgress = inProgress;
  },
  SET_STOCKS_DOWNLOADED(state, stocks) {
    state.stocksDownloaded = stocks;
  }

}

const actions = {
  async getSubscribedUsers({ commit, dispatch }) {
    dispatch("alert/startLoading", {}, { root: true });
    let response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/admin-analytics/subscription-analytics`, {
      withCredentials: true
    });
    dispatch("alert/stopLoading", {}, { root: true });
    commit("SET_SUBSCRIBED_USERS", response.data);
  },
  async getAnalysisData({ commit, dispatch }) {
    dispatch("alert/startLoading", {}, { root: true });
    let projects = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/admin-analytics/projects`, {
      withCredentials: true
    });
    commit("SET_ANALYSIS_PROJECTS", projects.data);
    const userDataFromDB = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/admin-analytics/user-list`);
    commit("SET_ANALYSIS_USER", userDataFromDB.data);
    const projectsInProgress = await axios.get(
      `${process.env.VUE_APP_SERVER_URL}/api/admin-analytics/projects-in-progress`
    );
    commit("SET_IN_PROGRESS", projectsInProgress.data);
    const stocksDownloaded = await axios.get(
      `${process.env.VUE_APP_SERVER_URL}/api/admin-analytics/get-downloaded-stock`
    );
    commit("SET_STOCKS_DOWNLOADED", stocksDownloaded.data);
    dispatch("alert/stopLoading", {}, { root: true });
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}