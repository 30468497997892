import axios from "axios";

const state = () => ({
  queuedProjects: [],
  queuedOrder: []
})

const mutations = {
  SET_QUEUE_PROJECTS(state, projects) {
    state.queuedProjects = projects;
  },
  SET_QUEUED_ORDER(state, order) {
    state.queuedOrder = order;
  }
}

const actions = {
  async getQueuedProjects({ commit, dispatch }) {
    dispatch("alert/startLoading", {}, { root: true });
    let response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/admin-projects/queued-projects`, {
      withCredentials: true
    });
    const projects = response.data;
    let queued_order = [];
    response.data.forEach(x => {
      queued_order.push(x.queued_order);
    })
    commit("SET_QUEUED_ORDER", queued_order);
    commit("SET_QUEUE_PROJECTS", projects);
    dispatch("alert/stopLoading", {}, { root: true });
  },
  async sortProjects({ state, dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.put(`${process.env.VUE_APP_SERVER_URL}/api/admin-projects/sort-queued-projects`, {
        new_ordered_ids: context.new_ordered_ids,
        new_ordered_orders: state.queuedOrder
      }, {
        withCredentials: true
      });
      await dispatch("getQueuedProjects");
      dispatch("alert/getAlert", {
        alert: "success",
        message: "successully sorted projects."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Failed to sort projects. Please try again later."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async cancelProject({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.put(`${process.env.VUE_APP_SERVER_URL}/api/admin-projects/set-project-to-canceled`, {
        projectId: context.id
      }, {
        withCredentials: true
      });
      await dispatch("getQueuedProjects");
      dispatch("alert/getAlert", {
        alert: "success",
        message: "successully canceled project."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: error
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}