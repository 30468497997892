<template>
  <div>
    <form @submit.prevent="login" class="signin-form">
      <input class="input-box-1" type="text" placeholder="Username *" v-model="userInputs.username" />
      <input class="input-box-1" type="password" placeholder="Password *" v-model="userInputs.password" />
      <span id="forgot-password-text" @click="findPassword">Forgot your password?</span>
      <button  class="modified-button" id="login-button" type="submit">Log in</button>
    </form>
    <span id="need-account-text">Need an account?</span>
    <hr/>
    <button class="modified-button" id="signup-button" type="button" @click="router.push('/signup')">Create New Account</button>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

const userInputs = ref({
  username: "",
  password: ""
})

// states
const currentUserId = computed(() => store.state.user.currentUser.id);

// getters
const gotoAfterLoginRouter = computed(() => store.getters["alert/getGotoAfterLoginRouter"]);
const needMFA = computed(() => store.getters["user/needMFA"]);
// const isMagicinfoAutologin = computed(() => store.getters["magicinfo/getAutologin"]);
// const magicinfoNeedMFA = computed(() => store.getters["magicinfo/getNeedMagicinfoMFA"]);

// methods
const login = async () => {
  store.commit("user/SET_CREDENTIALS", {
    username: userInputs.value.username,
    password: userInputs.value.password
  })
  // await store.dispatch("magicinfo/isMagicinfoAutologin", {
  //   username: userInputs.value.username
  // });
  // if (isMagicinfoAutologin.value) {
  //   store.commit("magicinfo/SET_AUTOLOGINING", true);
  //   await store.dispatch("magicinfo/autoLogin", {
  //     username: userInputs.value.username
  //   })
  //   if (magicinfoNeedMFA.value) {
  //     store.dispatch("modal/showModal", {
  //       modalTitle: "MagicINFO",
  //       modalName: "MagicinfoLoginModal"
  //     })
  //   }
  //   return;
  // }
  await store.dispatch("user/checkMFA", {
    username: userInputs.value.username
  });
  if (!needMFA.value) {
    await store.dispatch("user/login", {
      username: userInputs.value.username,
      password: userInputs.value.password
    });
    if (currentUserId.value) {
      router.push(`${gotoAfterLoginRouter.value}`);
    }
  }
}
const findPassword = () => {
  store.dispatch("modal/showModal", {
    modalTitle: "Find Password",
    modalName: "ForgotPasswordModal"
  })
}

</script>

<style scoped lang="scss">
span {
  text-align: left;
}
#login-button {
  background-color: black;
  color: white;
  margin: 2em auto;
  width: 600px;
}
#signup-button {
  color: black;
  width: 600px;
  &:hover {
    background-color: rgb(206, 206, 206);
  }
}
#forgot-password-text {
  cursor: pointer;
  color: black;
  width: fit-content;
  &:hover {
    border-bottom: 1px solid black;
  }
}
#need-account-text {
  text-align: center;
}
</style>