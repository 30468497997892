<template>
  <div class="container-fluid">
    <nav>
      <div class="nav nav-tabs" :class="[isVertical ? 'flex-column' : '']" id="nav-tab" role="tablist">
        <button
          v-for="(tab, index) in tabs" :key="index"
          class="nav-link" :class="[currentTab === tab ? 'active' : '']" :id="`nav-${tab}-tab`" data-bs-toggle="tab"
          :data-bs-target="`#${tab}-tab`" 
          type="button" role="tab" :aria-controls="`${tab}-tab`" :aria-selected="currentTab === tab ? 'true' : 'false'"
          @click="router.push('/admin/' + tab);"
        >
          {{ Helper.formatTabText(tab) }}
        </button>
      </div>
    </nav>
    <h1 class="title">{{ Helper.formatTabText(currentTab) }}</h1>
    <div class="tab-content" id="nav-tabContent">
      <div v-if="currentTab === 'templates'"
        class="tab-pane fade show"
        :class="[currentTab === 'templates' ? 'active' : '']"
        id="templates-tab" role="tabpanel" aria-labelledby="nav-templates-tab"
      >
        <TemplatesTab />
      </div>
      <div v-if="currentTab === 'stocks'"
        class="tab-pane fade show"
        :class="[currentTab === 'stocks' ? 'active' : '']"
        id="stocks-tab" role="tabpanel" aria-labelledby="nav-stocks-tab"
      >
        <StocksTab />
      </div>
      <div v-if="currentTab === 'categories'"
        class="tab-pane fade show"
        :class="[currentTab === 'categories' ? 'active' : '']"
        id="categories-tab" role="tabpanel" aria-labelledby="nav-categories-tab"
      >
        <CategoriesTab />
      </div>
      <div v-if="currentTab === 'keywords'"
        class="tab-pane fade show"
        :class="[currentTab === 'keywords' ? 'active' : '']"
        id="keywords-tab" role="tabpanel" aria-labelledby="nav-keywords-tab"
      >
        <KeywordsTab />
      </div>
      <div v-if="currentTab === 'users'"
        class="tab-pane fade show"
        :class="[currentTab === 'users' ? 'active' : '']"
        id="users-tab" role="tabpanel" aria-labelledby="nav-users-tab"
      >
        <UsersTab />
      </div>
      <div v-if="currentTab === 'project-queue'"
        class="tab-pane fade show"
        :class="[currentTab === 'project-queue' ? 'active' : '']"
        id="project-queue-tab" role="tabpanel" aria-labelledby="nav-project-queue-tab"
      >
        <ProjectQueueTab />
      </div>
      <div v-if="currentTab === 'popular-videos'"
        class="tab-pane fade show"
        :class="[currentTab === 'popular-videos' ? 'active' : '']"
        id="popular-videos-tab" role="tabpanel" aria-labelledby="nav-popular-videos-tab"
      >
        <PopularVideosTab />
      </div>
      <div v-if="currentTab === 'seasonal-videos'"
        class="tab-pane fade show"
        :class="[currentTab === 'seasonal-videos' ? 'active' : '']"
        id="seasonal-videos-tab" role="tabpanel" aria-labelledby="nav-seasonal-videos-tab"
      >
        <SeasonalVideosTab />
      </div>
      <div v-if="currentTab === 'maintenance'"
        class="tab-pane fade show"
        :class="[currentTab === 'maintenance' ? 'active' : '']"
        id="maintenance-tab" role="tabpanel" aria-labelledby="nav-maintenance-tab"
      >
        <MaintenanceTab />
      </div>
      <div v-if="currentTab === 'customer-portal'"
        class="tab-pane fade show"
        :class="[currentTab === 'customer-portal' ? 'active' : '']"
        id="customer-portal-tab" role="tabpanel" aria-labelledby="nav-customer-portal-tab"
      >
        <CustomerPortalViewVue />
      </div>
    </div>
  </div>
</template>

<script setup>
import TemplatesTab from "@/components/Admin/AdminTabs/TemplatesTab.vue";
import StocksTab from "@/components/Admin/AdminTabs/StocksTab.vue";
import CategoriesTab from "@/components/Admin/AdminTabs/CategoriesTab.vue";
import KeywordsTab from "@/components/Admin/AdminTabs/KeywordsTab.vue";
import UsersTab from "@/components/Admin/AdminTabs/UsersTab.vue";
import ProjectQueueTab from "@/components/Admin/AdminTabs/ProjectQueueTab.vue";
import PopularVideosTab from "@/components/Admin/AdminTabs/PopularVideosTab.vue";
import SeasonalVideosTab from "@/components/Admin/AdminTabs/SeasonalVideosTab.vue";
import MaintenanceTab from "@/components/Admin/AdminTabs/Maintenance/MaintenanceTab.vue";
import CustomerPortalViewVue from "@/views/CustomerPortalView.vue";
import Helper from "@/helpers";

import { ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";

const tabs = ref([
  "templates",
  "stocks",
  "categories",
  "keywords",
  "users",
  "project-queue",
  "popular-videos",
  "seasonal-videos",
  "maintenance",
  "canvas",
  "customer-portal"
]);
const router = useRouter();
const route = useRoute();
const isVertical = ref(false);
const currentTab = ref(route.params.tab[0]);


watch (
  () => route.params.tab,
  (newTab) => {
    currentTab.value = newTab[0];
  }
)

</script>

<style scoped lang="scss">
.nav-link {
  color: rgb(212, 209, 209);
  &:hover {
    color: black;
  }
}
.title {
  margin: 1ex;
}
.nav {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  margin-top: 3em;
  display: flex;
  justify-content: center;
}
</style>