<template>
  <div class="row row-col-6 grid-container">
    <div class="col-md-2" v-for="(video, index) in props.videos" :key="index" >
      <MediaCard :video="video" />
    </div>
    <div class="button">
      <button type="button" class="modified-button" id="load-more-button"
        v-show="props.videos.length < formatTotalNumberOfObjects && formatTotalNumberOfObjects >= loadMoreObjects[props.type]"
        @click="loadMore(props.type)">Load More</button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps } from "vue";
import { useStore } from "vuex";
import MediaCard from "@/components/MediaCard.vue";

const props = defineProps({
  videos: Array,
  type: String
})

const store = useStore();

const loadMoreObjects = computed(() => store.getters["templates/getLoadMoreObjects"]);
const totalNumberObjects = computed(() => store.getters["templates/getTotalNumberObjects"]);
const loadMoreObjectsStock = computed(() => store.getters["stocks/getLoadMoreObjects"]);
const totalNumberObjectsStock = computed(() => store.getters["stocks/getTotalNumberObjects"]);

const formatTotalNumberOfObjects = computed(() => {
  if (props.type === "stock") {
    return totalNumberObjectsStock.value["stock"];
  }
  return totalNumberObjects.value[props.type];
})

const loadMore = (videoClass) => {
  if (videoClass === "stock") {
    store.commit("stocks/SET_LOAD_MORE_OBJECTS", {
      name: "stock",
      number: loadMoreObjectsStock.value["stock"] + 18
    })
  } else {
    store.commit("templates/SET_LOAD_MORE_OBJECTS", { 
      name: videoClass,
      number: loadMoreObjects.value[videoClass] + 18
    })
  }
}

const windowWidth = ref(null);

windowWidth.value = window.innerWidth;
window.addEventListener("resize", () => {
  windowWidth.value = window.innerWidth;
});


</script>

<style scoped lang="scss">
.grid-container {
  margin: 0 5%;
  #load-more-button {
    width: 40%;
    margin: 2em 0;
    &:hover {
      background-color: var(--ce-selected-button-color);
    }
  }
}
</style>