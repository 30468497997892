const state = {
  step: "",
  username: "",
  password: "",
  confirmPassword: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  company: "",
  selectedPlan: "",
  billingCycle: "",
  price: null,
  trialDuration: 0,
  trialDurationUnit: "",
  displayOwnRequired: null,
  isDisplayOwner: null,
  optIn: false,
  fieldsAreValid: null,
  signupSubmitted: false,
  errorMessage: "",
  action: ""
}

const getters = {
  getSelectedPlan: state => {
    return state.selectedPlan;
  },
  getPrice: state => {
    return state.price;
  },
  getAction: state => {
    return state.action;
  },
  getTrialDuration: state => {
    return state.trialDuration;
  },
  getTrialDurationUnit: state => {
    return state.trialDurationUnit;
  }
}

const mutations = {
  SET_CHECKOUT_BY_KEY(state, context) {
    state[context.key] = context.value;
  },
  SET_ACTION(state, action) {
    state.action = action;
  },
  SET_TRIAL_DURATION(state, duration) {
    state.trialDuration = duration;
  },
  SET_TRIAL_DURATION_UNIT(state, durationUnit) {
    state.trialDurationUnit = durationUnit;
  },
  RESET_CHECKOUT(state) {
    state.step = "",
    state.username = "",
    state.password = "",
    state.confirmPassword = "",
    state.firstName = "",
    state.lastName = "",
    state.email = "",
    state.phone = "",
    state.company = "",
    state.selectedPlan = "",
    state.billingCycle = "",
    state.price = null,
    state.trialDuration = 0,
    state.trialDurationUnit = "",
    state.displayOwnRequired = null,
    state.isDisplayOwner = null,
    state.optIn = false,
    state.fieldsAreValid = null,
    state.signupSubmitted = false,
    state.errorMessage = "",
    state.action = ""
  }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}