<template>
  <div class="container-fluid">
    <form @submit.prevent="submit">
      <div class="input-element">
        <label>Username:</label>
        <input type="text" class="input-box-1" placeholder="Username" v-model="inputs.username" @change="setCurrentInputValue('username', $event)" />
      </div>
      <div class="input-element">
        <label>New Password:</label>
        <input type="password" class="input-box-1" placeholder="New Password" v-model="inputs.newPassword" @change="setCurrentInputValue('newPassword', $event)" />
      </div>
      <div class="input-element">
        <label>Confirm Password:</label>
        <input type="password" class="input-box-1" placeholder="Confirm Password" v-model="inputs.confirmPassword" @change="setCurrentInputValue('confirmPassword', $event)" />
      </div>
      <div class="buttons">
        <button class="modified-button" type="button" id="cancel-button" @click="cancel">Cancel</button>
        <button class="modified-button" type="submit" id="submit-button">Submit</button>
      </div>
    </form>
    <div class="countdown">
      <span v-if="!expired">{{ getCountDownTime }}</span>
      <span v-else>Time expired. Please try again.</span>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

const currentInputs = computed(() => store.getters["checkError/getCurrentInputs"]);
const errorNumbers = computed(() => store.getters["checkError/getErrorNumber"]);

const inputs = ref({
  username: "",
  newPassword: "",
  confirmPassword: "",
  confirmationCode: ""
})

const countdownTime = ref(300);
let countdownInter;
let expired = ref(false);


// computed
const getCountDownTime = computed(() => {
  let mins = Math.floor(countdownTime.value / 60).toString();
  let secs = (countdownTime.value % 60).toString();
  mins = mins.length === 1 ? '0' + mins : mins;
  secs = secs.length === 1 ? '0' + secs : secs;
  return `${mins}:${secs}`
})


// methods
const setCurrentInputValue = (key, e) => {
  let value = e.target.value;
  store.commit("checkError/SET_CURRENT_INPUT_VALUE", {
    key: key,
    value: value
  });
}
const submit = async () => {
  store.commit("checkError/SET_ERROR_NUMBER", 0);
  for (let i=0; i<Object.keys(currentInputs.value).length; i++) {
    let input = currentInputs.value[Object.keys(currentInputs.value)[i]];
    for (let j=0; j<input.checkErrors.length; j++) {
      let checkError = input.checkErrors[j];
      store.dispatch(`checkError/check${checkError}`, {
        ...currentInputs.value[Object.keys(currentInputs.value)[i]]
      });
    }
    if (errorNumbers.value > 0) {
      break;
    }
  }
  if (errorNumbers.value === 0 && !expired.value) {
    let response = await store.dispatch("user/setNewPassword", {
      username: inputs.value.username,
      password: inputs.value.newPassword,
      confirmationCode: inputs.value.confirmationCode
    })
    if (response) {
      store.dispatch("modal/closeModal");
      router.push("/login");
    }
  }
}
const cancel = () => {
  store.dispatch("modal/closeModal");
}


watch(
  () => inputs.value.newPassword,
  (updatedInput) => {
    store.commit("checkError/SET_CURRENT_INPUT_SECOND_KEY_VALUE", {
      key: "confirmPassword",
      secondKey: "matchInput",
      value: updatedInput
    })
  }
)


store.commit("checkError/SET_CURRENT_INPUTS", {
  username: {
    input: "",
    inputName: "Username",
    checkErrors: ["CannotBeEmpty"]
  },
  newPassword: {
    input: "",
    inputName: "New Password",
    checkErrors: ["CannotBeEmpty", "CannotBeShorterOrLonger"]
  },
  confirmPassword: {
    input: "",
    inputName: "Confirm Password",
    matchInput: "",
    matchInputName: "Password",
    checkErrors: ["CannotBeEmpty", "DoesNotMatch"]
  },
})

// create
expired.value = false;
inputs.value.confirmationCode = router.currentRoute.value.params.confirmationCode;
countdownInter = setInterval(() => {
  countdownTime.value -= 1;
  if (countdownTime.value === 0) {
    inputs.value.confirmationCode = "";
    expired.value = true;
    clearInterval(countdownInter);
  }
}, 1000);






</script>

<style lang="scss" scoped>
.input-element {
  text-align: left;
}
.modified-button {
  width: 30%;
  margin: 1em;
}
</style>