<template>
  <table class="table table-hover table-striped table-bordered">
    <thead>
      <tr>
        <th scope="col" v-for="(head, index) in tableHead" :key="index">
          <span v-if="head === 'converted_created_at'">Created At</span>
          <span v-else>{{ head[0].toUpperCase() + head.slice(1) }}</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr class="item" v-for="(data, index_x) in stateQueuedProjects" :key="index_x" @dragover="$event.preventDefault()" @dragstart="startDragging(data, index_x)" @drop="drop">
        <td @drop="dropSelectedItem($event)" v-for="(head, index_y) in tableHead" :key="index_y" :draggable="head === 'sort' && !computedSetDisableProjectsInQueue.includes(data.id)">
          <icon class="icon" v-if="head === 'cancel'" icon="xmark" @click="cancelProject(data.id)"/>
          <icon v-else-if="head === 'sort'" :class="computedSetDisableProjectsInQueue.includes(data.id) ? 'disabled' : 'icon'" icon="sort"/>
          <span v-else :class="computedSetDisableProjectsInQueue.includes(data.id) ? 'disabled' : ''">{{ data[head.toLowerCase()] }}</span>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="empty" v-if="stateQueuedProjects.length === 0">Empty</div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";

const droppedItem = ref({
  id: 0,
  index: 0,
  location: 0
});
const closestItem = ref({
  id: 0,
  index: 0,
  location: 0
});
const sorting = ref(false);

const store = useStore();
const tableHead = ref(["id", "name", "matrix", "username", "converted_created_at", "cancel", "sort"]);

// states
const stateQueuedProjects = computed(() => store.state.adminProjectQueue.queuedProjects);

// methods
const startDragging = (data, index) => {
  droppedItem.value = { id: data.id, index: index, location: 0};
}
const dropSelectedItem = e => {
  droppedItem.value.location = e.target.getBoundingClientRect().y;
}
const drop = async () => {
  sorting.value = true;
  getClosestItemFromDrop();
  if (computedSetDisableProjectsInQueue.value.includes(closestItem.value.id)) {
    return;
  }
  let sortedArray = getSortedArrayFromDrop();
  let newOrderedIds = sortedArray.map(item => {
    return item.id;
  });
  await store.dispatch("adminProjectQueue/sortProjects", {
    new_ordered_ids: newOrderedIds
  });
  sorting.value = false;
}
const getClosestItemFromDrop = () => {
  closestItem.value = { id: stateQueuedProjects.value[0].id, index: 0, location: 0};
  let items = document.querySelectorAll(".item");
  let closest = Number.POSITIVE_INFINITY;
  [...items].forEach((x, i) => {
    let item = { id: stateQueuedProjects.value[i].id, index: i, location: x.getBoundingClientRect().y};
    closestItem.value = Math.abs(droppedItem.value.location - item.location) < closest ? item : closestItem.value;
    closest = Math.abs(droppedItem.value.location - item.location) < closest ? Math.abs(droppedItem.value.location - item.location) : closest;
  })
}
const getSortedArrayFromDrop = () => {
  let top2Bottom = droppedItem.value.index > closestItem.value.index ? false : true;
  let first = top2Bottom ? stateQueuedProjects.value.slice(0, closestItem.value.index+1) : stateQueuedProjects.value.slice(0, closestItem.value.index);
  let second = stateQueuedProjects.value[droppedItem.value.index];
  let third = top2Bottom ? stateQueuedProjects.value.slice(closestItem.value.index+1) : stateQueuedProjects.value.slice(closestItem.value.index);
  top2Bottom ? first.splice(droppedItem.value.index, 1) : third.splice(droppedItem.value.index-closestItem.value.index, 1);
  return [...first.concat(second).concat(third)];
}
const cancelProject = projectId => {
  store.dispatch("adminProjectQueue/cancelProject", {
    id: projectId
  })
}

// computed
const computedSetDisableProjectsInQueue = computed(() => {
  if (stateQueuedProjects.value.length === 0) {
    return [];
  }
  if (stateQueuedProjects.value.length === 1) {
    return [stateQueuedProjects.value[0].id];
  }
  let firstElement = stateQueuedProjects.value[0];
  let secondElement;
  if (firstElement.id % 2 === 1) {
    secondElement = stateQueuedProjects.value.filter(x => x.id % 2 === 0)[0];
  } else {
    secondElement = stateQueuedProjects.value.filter(x => x.id % 2 === 1)[0];
  }
  return secondElement ? [firstElement.id, secondElement.id] : [firstElement.id];
})

// created
store.dispatch("adminProjectQueue/getQueuedProjects");
// if (stateQueuedProjects.value.length === 0) {
  
// }


</script>

<style scoped lang="scss">
.icon {
  cursor: pointer;
}
.disabled {
  pointer-events: none;
  opacity: 0.3;
}
</style>