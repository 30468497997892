const state = {
  currentInputs: {},
  errorNumber: 0,
  pass: false,
  minLength: 3,
  minLengthPassword: 8,
  maxLength: 40
}

const getters= {
  getCurrentInputs: state => {
    return state.currentInputs;
  },
  getErrorNumber: state => {
    return state.passNumber;
  },
  getPass: state => {
    return state.pass;
  }
}

const mutations = {
  SET_CURRENT_INPUTS(state, inputs) {
    state.currentInputs = inputs;
  },
  SET_CURRENT_INPUT_VALUE(state, context) {
    state.currentInputs[context.key]["input"] = context.value;
  },
  SET_CURRENT_INPUT_SECOND_KEY_VALUE(state, context) {
    state.currentInputs[context.key][context.secondKey] = context.value;
  },
  SET_PASS(state, pass) {
    state.pass = pass;
  },
  SET_ERROR_NUMBER(state, number) {
    state.passNumber = number;
  },
  ADD_CURRENT_INPUTS(state, context) {
    state.currentInputs[`${context.key}`] = context.value;
  },
  REMOVE_CURRENT_INPUT(state ,input) {
    delete state.currentInputs[`${input}`];
  }
}

const actions = {
  async checkCannotBeEmpty({ commit, dispatch }, context) {
    if (context.input.length === 0) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: `${context.inputName} cannot be empty.`
      }, { root: true });
      commit("SET_ERROR_NUMBER", state.errorNumber + 1);
    }
  },
  async checkShouldBeNumber({ commit, dispatch }, context) {
    for (let i=0; i<context.input.length; i++) {
      if (context.input.charCodeAt(i) < 48 || context.input.charCodeAt(i) > 57) {
        dispatch("alert/getAlert", {
          alert: "danger",
          message: `${context.inputName} should be number.`
        }, { root: true });
        commit("SET_ERROR_NUMBER", state.errorNumber + 1);
        break;
      }
    }
  },
  async checkIncludeLowerCaseCharacter({ commit, dispatch }, context) {
    if (!context.input.match(/[a-z]/)) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: `${context.inputName} should contain at least one lower case character.`
      }, { root: true });
      commit("SET_ERROR_NUMBER", state.errorNumber + 1);
    }
  },
  async checkIncludeUpperCaseCharacter({ commit, dispatch }, context) {
    if (!context.input.match(/[A-Z]/)) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: `${context.inputName} should contain at least one upper case character.`
      }, { root: true });
      commit("SET_ERROR_NUMBER", state.errorNumber + 1);
    }
  },
  async checkIncludeNumbers({ commit, dispatch }, context) {
    if (!context.input.match(/[0-9]/)) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: `${context.inputName} should contain at least one number.`
      }, { root: true });
      commit("SET_ERROR_NUMBER", state.errorNumber + 1);
    }
  },
  async checkIncludeSpecialCharacter({ commit, dispatch }, context) {
    if (!context.input.match(/[!@#$%^&*()_+\-=\]{};':"\\|,.<>?]+/)) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: `${context.inputName} should contain at least one special character.`
      }, { root: true });
      commit("SET_ERROR_NUMBER", state.errorNumber + 1);
    }
  },
  async checkCannotBeZero({ state, commit, dispatch }, context) {
    if (parseInt(context.input) === 0) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: `${context.inputName} cannot be 0.`
      }, { root: true });
      commit("SET_ERROR_NUMBER", state.errorNumber + 1);
    }
  },
  async checkCannotBeNull({ commit, dispatch }, context) {
    if (!context.input) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: `${context.inputName} cannot be null.`
      }, { root: true });
      commit("SET_ERROR_NUMBER", state.errorNumber + 1);
    }
  },
  async checkStringLength({ commit, dispatch }, context) {
    if (!(context.input.length >= context.minLength && context.input.length <= context.maxLength)) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: `${context.inputName} should be between ${context.minLength} and ${context.maxLength} long.` 
      }, { root: true });
      commit("SET_ERROR_NUMBER", state.errorNumber + 1);
    }
  },
  async checkCannotBeShorterOrLonger({ state, commit, dispatch }, context) {
    if (!(context.input.length >= state.minLength && context.input.length <= state.maxLength)) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: `The length of ${context.inputName} should be between ${state.minLength} and ${state.maxLength}.` 
      }, { root: true });
      commit("SET_ERROR_NUMBER", state.errorNumber + 1);
    }
  },
  async checkCannotBeShorterOrLongerPassword({ state, commit, dispatch }, context) {
    if (!(context.input.length >= state.minLengthPassword && context.input.length <= state.maxLength)) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: `The length of ${context.inputName} should be between ${state.minLengthPassword} and ${state.maxLength}.` 
      }, { root: true });
      commit("SET_ERROR_NUMBER", state.errorNumber + 1);
    }
  },
  async checkDoesNotMatch({ state, commit, dispatch }, context) {
    if (context.input !== context.matchInput) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: `${context.inputName} should match with ${context.matchInputName}.` 
      }, { root: true });
      commit("SET_ERROR_NUMBER", state.errorNumber + 1);
    }
  },
  async checkDoesNotMatchEmailFormat({ state, commit, dispatch }, context) {
    /* eslint-disable-next-line */
    let regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!context.input.match(regExp)) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: `Invalid email address. Please try again.` 
      }, { root: true });
      commit("SET_ERROR_NUMBER", state.errorNumber + 1);
    }
  },
  async checkDoesNotMatchPhoneNumberFormat({ state, commit, dispatch }, context) {
    if (!(context.input.match(/\d/g).length === 10)) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: `Invalid phone number. Please try again with a valid 10 digit number (ex: 2068888888)` 
      }, { root: true });
      commit("SET_ERROR_NUMBER", state.errorNumber + 1);
    }
  },
  async checkShouldBeAnImage({ state, commit, dispatch }, context) {
    if (typeof(context.input) === "string") {
      if (context.input.substr(context.input.length - 3) !== "jpg") {
        dispatch("alert/getAlert", {
          alert: "danger",
          message: `${context.inputName} should be an image.` 
        }, { root: true });
        commit("SET_ERROR_NUMBER", state.errorNumber + 1);
      }
    } else if(!context.input.type.match(/image*/)) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: `${context.inputName} should be an image.` 
      }, { root: true });
      commit("SET_ERROR_NUMBER", state.errorNumber + 1);
    }
  },
  async checkShouldBeAVideo({ state, commit, dispatch }, context) {
    if (typeof(context.input) === "string") {
      if (context.input.substr(context.input.length - 3) !== "mp4") {
        dispatch("alert/getAlert", {
          alert: "danger",
          message: `${context.inputName} should be an image.` 
        }, { root: true });
        commit("SET_ERROR_NUMBER", state.errorNumber + 1);
      }
    } else if(!context.input.type.match(/video*/)) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: `${context.inputName} should be a video.` 
      }, { root: true });
      commit("SET_ERROR_NUMBER", state.errorNumber + 1);
    }
  },
  checkPass({ state, commit }) {
    if (state.errorNumber > 0) {
      commit("SET_PASS", false);
    } else {
      commit("SET_PASS", true);
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}