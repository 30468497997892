<template>
  <div class="container-fluid">
    <div class="maintenance">
      <span class="maintenance-buttons">
        <button type="button" @click="ongoingMaintenance = 'maintenance'"
          class="btn btn-dark"
          :class="[ongoingMaintenance === 'maintenance' ? 'disable' : '']">
          Create Maintenance Period
        </button>
        <button type="button" @click="resetTexting" class="btn btn-dark">
          Reset Texting
        </button>
        <button type="button" @click="ongoingMaintenance = 'key'"
          class="btn btn-dark"
          :class="[ongoingMaintenance === 'key' ? 'disable' : '']">
          Generate Key
        </button>
      </span>
    </div>
    <h4 v-if="ongoingMaintenance === 'maintenance'">Create Maintenance Period</h4>
    <h4 v-else-if="ongoingMaintenance === 'key'">Generate Key</h4>
    <div class="inputs">
      <CreateMaintenance v-if="ongoingMaintenance === 'maintenance'" @cancel="ongoingMaintenance = ''" />
      <GenerateKey v-else-if="ongoingMaintenance === 'key'" @cancel="ongoingMaintenance = ''" />
    </div>
  </div>
</template>

<script setup>
import CreateMaintenance from "./CreateMaintenance.vue";
import GenerateKey from "./GenerateKey.vue";
import { ref } from "vue";
import { useStore } from "vuex";

const store = useStore();

const ongoingMaintenance = ref("maintenance");

const resetTexting = () => {
  store.dispatch("ticker/resetTexting");
}

</script>

<style scoped>
.maintenance-buttons {
  display: flex;
  justify-content: space-evenly;
  margin: 2em 0;
}
</style>