<template>
  <div class="customer-portal-element-list">
    <table
      class="table table-hover table-striped"
    >
      <thead>
        <tr>
          <th v-for="(head, index) in tableHead" :key="index">
            <span class="element-list-header">
              {{ Helper.formatTabText(head) }}
              <span v-if="head !== 'selected'" id="sorting-icon"  @click="sort(head)">
                <icon v-if="sorting.name === head && sorting.sort === 'up'" class="icon" icon="sort-up" />
                <icon v-else-if="sorting.name === head && sorting.sort === 'down'" class="icon" icon="sort-down" />
                <icon v-else class="icon" icon="sort" />
              </span>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data, index_x) in sortedItems" :key="index_x">
          <td v-for="(head, index_y) in tableHead" :key="index_y"
            :class="{'selected-item': dataIsSelected(data), 'new-item': data.newItem}">
            <span class="element-list-image-slot" v-if="head === 'name'" @click="clickItem(data)">
              <img class="element-image" :src="getImageSrc(data.type)" />
              &nbsp;&nbsp;{{ data.name }}
            </span>
            <span class="element-list-data" v-else-if="head === 'size'">
              {{ data[head.toLowerCase()] === 0 ? '---' : formattedSize(data[head.toLowerCase()]) }}
            </span>
            <span class="toggle-button" v-else-if="head === 'selected'" @click="toggleSelected(data)">
              <icon :icon="data[head.toLowerCase()] ? 'toggle-on' : 'toggle-off'" />
            </span>
            <span class="element-list-data" v-else>
              {{ data[head.toLowerCase()] }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="center-item" v-if="formattedAllItems.length === 0">
      <h3><i>Empty</i></h3>
    </div>
  </div>
</template>

<script setup>
import FileImage from "@/assets/fileImage.png";
import ImageImage from "@/assets/imageIcon.png";
import VideoImage from "@/assets/videoIcon.png";
import FolderImage from "@/assets/folder.png";
import { computed, ref } from 'vue';
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Helper from "@/helpers";

const store = useStore();
const router = useRouter();
const tableHead = ref(["name", "type", "size", "selected"]);
const sorting = ref({
  name: "",
  sort: ""
});
// states
const selectedItems = computed(() => store.state.customerPortal.selectedItems);
const isAdmin = computed(() => store.state.customerPortal.isAdmin);
const rootPath = computed(() => store.state.customerPortal.rootPath);
const currentPath = computed(() => store.state.customerPortal.currentPath);
// getters
const formattedAllItems = computed(() => store.getters["customerPortal/formattedAllItems"]);
const someItemsSelected = computed(() => store.getters["customerPortal/someItemsSelected"]);
// computed
const sortedItems = computed(() => {
  let temp = [...formattedAllItems.value];
  if (sorting.value.name !== "") {
    temp.sort((a,b) => {
      if (sorting.value.sort === "up") {
        if (sorting.value.name === "size") {
          return a[sorting.value.name.toLowerCase()] - b[sorting.value.name.toLowerCase()];
        }
        return a[sorting.value.name.toLowerCase()].toLowerCase() > b[sorting.value.name.toLowerCase()].toLowerCase() ? 1 : -1;
      }
      if (sorting.value.name === "size") {
        return b[sorting.value.name.toLowerCase()] - a[sorting.value.name.toLowerCase()];
      }
      return b[sorting.value.name.toLowerCase()].toLowerCase() > a[sorting.value.name.toLowerCase()].toLowerCase() ? 1 : -1;
    })
  }
  return temp;
})
// methods
const dataIsSelected = data => {
  let dataName = data.name;
  for (let i=0; i<Object.keys(selectedItems.value).length; i++) {
    if (Object.keys(selectedItems.value)[i] === dataName) {
      return true;
    }
  }
  return false;
}
const formattedSize = size => {
  if (size === 0) return "----";
  let formattedSize = `${(size).toFixed(2)} B`;
  if (size > 1024 && size <= 1024000) {
    formattedSize = `${(size / 1024).toFixed(2)} KB`;
  } else if (size > 1024000 && size <= 1073741824) {
    formattedSize = `${(size / 1024000).toFixed(2)} MB`;
  } else if (size > 1073741824) {
    formattedSize = `${(size / 1073741824).toFixed(2)} GB`;
  }
  return formattedSize;
}
const toggleSelected = data => {
  let tempSelectedItems = JSON.parse(JSON.stringify(selectedItems.value));
  tempSelectedItems[data.name] ? 
  delete tempSelectedItems[data.name] :
  tempSelectedItems[data.name] = data;
  data.selected = data.selected ? false : true;
  store.commit("customerPortal/SET_SELECTED_ITEMS", tempSelectedItems);
  store.commit("customerPortal/SET_SELECTING", someItemsSelected.value ? true : false);
  return;
}
const clickItem = async item => {
  if (item.type === "folder") {
    if (isAdmin.value) {
      router.push(`/admin/customer-portal/${item.path.slice(rootPath.value.length)}${item.name}`);
    } else {
      router.push(`/customer-portal/${item.path.slice(rootPath.value.split("/")[0])}${item.name}`);
    }
    await store.dispatch("customerPortal/getItems", { folderName: `${item.path}${item.name}`, resetSelected: false });
    store.commit("customerPortal/SET_CURRENT_PAGE", 1);
    return;
  }
  let file = {
    name: item.name,
    path: item.path,
    type: item.type,
    size: item.size,
    media: `${process.env.VUE_APP_SERVER_URL}/api/customer-portal/${currentPath.value}${item.name}`
  }
  store.commit("customerPortal/SET_CURRENT_FILE", file);
  store.dispatch("modal/showModal", {
     modalTitle: file.name, 
     modalName: "CustomerPortalFileDetail"
    }
  )
}
const getImageSrc = type => {
  let imageSrc = FileImage;
  if (type === "image") {
    imageSrc = ImageImage;
  } else if (type === "video") {
    imageSrc = VideoImage;
  } else if (type === "folder") {
    imageSrc = FolderImage;
  }
  return imageSrc;
}
const sort = head => {
  if (sorting.value.name === "" || sorting.value.name !== head) {
    sorting.value = {
      name: head,
      sort: "up"
    }
    return;
  }
  if (sorting.value.sort === "up") {
    sorting.value.sort = "down";
    return;
  }
  sorting.value = {
    name: "",
    sort: ""
  };
}

</script>

<style scoped lang="scss">
.icon {
  cursor: pointer;
}
.new-item {
  background-color: rgb(201, 255, 201); 
}
.selected-item {
  background-color: rgb(255, 255, 154);
}
.customer-portal-element-list {
  min-height: 60ex;
}
.center-item {
  position: absolute;
  left: 50%;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.toggle-button {
  cursor: pointer;
  float: left;
  margin-left: 1em;
}
.element-image {
  height: 20px;
  width: 25px;
}
.element-list-header, .element-list-data {
  display: flex;
  justify-content: space-between;
}
.element-list-image-slot {
  cursor: pointer;
  display: flex;
}
#sorting-icon {
  float: right
}

</style>