<template>
  <div class="container-fluid">
    <h5>Current Status:</h5>
    <button class="modified-button disabled" 
      :style="{backgroundColor: currentlyUpdating === 'template' ? getStatusList[selectedTemplate.status].color : getStatusList[selectedStock.status].color}">
      <span v-if="currentlyUpdating === 'template'">{{ selectedTemplate.status }}</span>
      <span v-else-if="currentlyUpdating === 'stock'">{{ selectedStock.status }}</span>
    </button>
    <h5>Change to:</h5>
    <div class="others">
      <button class="modified-button" v-for="(status, index) in filteredStatus" :key="index"
        :style="{backgroundColor: getStatusList[status].color}"
        @click="updateStatus(status)">
        {{ status }}
      </button>
    </div>
    <hr />
    <button type="button" class="modified-button" id="cancel-button"
      @click="closeUpdatingModal">
      Cancel
    </button>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

// states
const availableTemplateStatus = computed(() => store.state.adminTemplates.availableTemplateStatus);
const availableStockStatus = computed(() => store.state.adminStocks.availableStockStatus);
// getters
const selectedTemplate = computed(() => store.getters["adminTemplates/getSelectedTemplate"]);
const selectedStock = computed(() => store.getters["adminStocks/getSelectedStock"]);
const currentlyUpdating = computed(() => store.getters["modal/getCurrentlyUpdating"]);

// methods
const updateStatus = async (status) => {
  if (currentlyUpdating.value === 'template') {
    await store.dispatch("adminTemplates/updateTemplateStatus", {
      s3Id: selectedTemplate.value.s3_id,
      status: status
    });
  } else if (currentlyUpdating.value === 'stock') {
    await store.dispatch("adminStocks/updateStockStatus", {
      s3Id: selectedStock.value.s3_id,
      status: status
    });
  }
  
  store.dispatch("modal/closeModal");
}
const closeUpdatingModal = () => {
  store.commit("modal/SET_CURRENTLY_UPDATING", null);
  store.dispatch('modal/closeModal')
}

// computed
const getStatusList = computed(() => {
  if (currentlyUpdating.value === 'template') {
    return availableTemplateStatus.value;
  }
  return availableStockStatus.value;
})
const filteredStatus = computed(() => {
  if (currentlyUpdating.value === 'template') {
    return Object.keys(availableTemplateStatus.value).filter(status => {
      return status !== selectedTemplate.value.status;
    })
  }
  return Object.keys(availableStockStatus.value).filter(status => {
    return status !== selectedStock.value.status;
  })
})

</script>

<style scoped lang="scss">
.disabled {
  pointer-events: none;
  opacity: 0.4;
}
.modified-button {
  width: 80%;
  margin: 1ex auto;
}
.others {
  display: flex;
  flex-direction: column;
}
#cancel-button {
  &:hover {
    background-color: var(--ce-cancel-button-color);
  }
}
</style>