<template>
  <div class="braintree-image">
    <a
      href="https://www.braintreegateway.com/merchants/txbv2hqmv5x68gwv/verified"
      target="_blank"
    >
      <img
        src="https://s3.amazonaws.com/braintree-badges/braintree-badge-wide-light.png"
      >
    </a>
  </div>
</template>

<script setup>
</script>

<style scoped lang="scss">
.braintree-image {
  margin: 1em;
  img {
    width: 300px;
    height: 50px;
  }
}
</style>