<template>
  <div class="container-fluid">
    <MovingObjectsContainer v-if="storeMoving" />
    <!-- <ToggleButton v-if="router.currentRoute.value.name !== 'admin'" 
      :leftText="'Projects'" :rightText="'Customized'" :toggle="'customerPortal'"/> -->
    <div class="customer-portal">
      <SidebarComponent :isMyPortal="isMyPortal" v-show="formattedHasCustomerPortalAccess"/>
      <div class="middle-body">
        <HeaderComponent :isMyPortal="isMyPortal" v-show="formattedHasCustomerPortalAccess"/>
        <div class="middle-body-element-list">
          <ProgressBarComponent v-if="upDownloading || downloading" />
          <div v-if="!isMyPortal && formattedHasCustomerPortalAccess">
            <PortalSmallIconListComponent v-if="currentView === 'Lists'"/>
            <PortalLargeIconListComponent v-else-if="currentView === 'Large Icons'" :isMyPortal="isMyPortal"/>
          </div>
          <div v-else>
            <ProjectsSmallIconListComponent v-if="currentView === 'Lists' && isMyPortal" />
            <ProjectsLargeIconListComponent v-else-if="currentView === 'Large Icons' && isMyPortal" :isMyPortal="isMyPortal" />
          </div>
          <PaginationComponent v-show="totalNumberOfItemsAndProjectsOnTable > 0 && formattedHasCustomerPortalAccess"/>
        </div>
      </div>
    </div>
    <div class="no-customer-portal-access" v-show="!formattedHasCustomerPortalAccess">
      <p>
        You currently do not have the access to Customer Portal.
        Please contact us using the information below to gain access.
        <br>
        <br>Samsung Electronics America
        <br>Logan Utah Office
        <br>1651 North 1000 West
        <br>Logan UT, 84321
        <br>USA
        <br>
        <br>creativegroup@sea.samsung.com
        <br>1-800-741-6721
      </p>
    </div>
  </div>
</template>

<script setup>
import MovingObjectsContainer from '@/components/CustomerPortal/MovingObjectsContainer.vue';
import SidebarComponent from "@/components/CustomerPortal/SidebarComponent.vue";
import HeaderComponent from "@/components/CustomerPortal/HeaderComponent.vue";
import ProgressBarComponent from "@/components/CustomerPortal/ProgressBarComponent.vue";
import PortalSmallIconListComponent from "@/components/CustomerPortal/SmallIconListComponent.vue";
import PortalLargeIconListComponent from "@/components/CustomerPortal/LargeIconListComponent.vue";
import PaginationComponent from "@/components/CustomerPortal/PaginationComponent.vue";
import ProjectsSmallIconListComponent from "@/components/Projects/SmallIconListComponent.vue";
import ProjectsLargeIconListComponent from "@/components/Projects/LargeIconListComponent.vue";
// import ToggleButton from "@/components/ToggleButton.vue";

import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();
const test = ref("");

// states
const storeMoving = computed(() => store.state.customerPortal.moving);
const storeIsAdmin = computed(() => store.state.customerPortal.isAdmin);
const storeRootPath = computed(() => store.state.customerPortal.rootPath);
const currentView = computed(() => store.state.customerPortal.currentView);

// getters
const totalNumberOfItemsOnTable = computed(() => store.getters["customerPortal/getTotalNumberOfItemsOnTable"]);
const totalNumberOfProjectsOnTable = computed(() => store.getters["projects/getNumberOfProjectsOnTable"]);
const formattedAllItems = computed(() => store.getters["customerPortal/formattedAllItems"]);
const getFormattedProjects = computed(() => store.getters["projects/getFormattedProjects"]);
const isMyPortal = computed(() => store.getters["customerPortal/getIsMyPortal"]);
const userCompany = computed(() => store.getters["user/getCompanyName"]);
const hasCustomerPortalAccess = computed(() => store.getters["user/hasCustomerPortalAccess"]);
const upDownloading = computed(() => store.getters["customerPortal/getUpDownloading"]);
const downloading = computed(() => store.getters["projects/getDownloading"]);
const rootPath = computed(() => store.getters["customerPortal/getRootPath"]);

// const currentPath = computed(() => store.getters["customerPortal/getCurrentPath"]);

// methods
const getCustomerPortalProjects= () => {
  store.commit("customerPortal/SET_IS_ADMIN", router.currentRoute.value.name === "admin");
  let path = "";
  if (storeIsAdmin.value) {
    store.commit("customerPortal/SET_IS_MY_PORTAL", false);
    store.commit("customerPortal/SET_ROOT_PATH", process.env.VUE_APP_S3_CUSTOMER_PORTAL_FOLDER);
    path = `${process.env.VUE_APP_S3_CUSTOMER_PORTAL_FOLDER}${router.currentRoute.value.params.tab[0].split("/").slice(1).join("/")}`;
    if (path !== storeRootPath.value) {
      path += "/";
    }
    store.dispatch("customerPortal/getItems", { folderName: path, resetSelected: false });
  } else {
    let timer = setInterval(async () => {
      if (userCompany.value) {
        clearInterval(timer);
        store.commit("customerPortal/SET_ROOT_PATH", process.env.VUE_APP_S3_CUSTOMER_PORTAL_FOLDER + userCompany.value + "/");
        path = `${process.env.VUE_APP_S3_CUSTOMER_PORTAL_FOLDER}${userCompany.value}/`;
        if (router.currentRoute.value.params.directory) {
          path += `${router.currentRoute.value.params.directory}`;
        }
        if (path !== storeRootPath.value) {
          path += "/";
        }
        await store.dispatch("customerPortal/getItems", { folderName: path, resetSelected: false });
      }
    }, 5)
  }
}
const formattedHasCustomerPortalAccess = computed(() => {
  if (storeIsAdmin.value || isMyPortal.value) {
    return true;
  }
  return hasCustomerPortalAccess.value;
})

const totalNumberOfItemsAndProjectsOnTable = computed(() => {
  if (isMyPortal.value) {
    return totalNumberOfProjectsOnTable.value;
  }
  return totalNumberOfItemsOnTable.value;
})

// created
if (router.currentRoute.value.name === "admin") {
  store.commit("customerPortal/SET_IS_MY_PORTAL", false);
  if (formattedAllItems.value.length === 0) {
    getCustomerPortalProjects();
  }
} else {
  store.commit("customerPortal/SET_IS_MY_PORTAL", true);
  if (getFormattedProjects.value.length === 0) {
    store.dispatch("projects/getProjects");
  }
}
getCustomerPortalProjects();

watch(
  () => isMyPortal.value,
  (toggle) => {
    store.dispatch("customerPortal/unselectAndCancel");
    store.dispatch("projects/unselectAndCancel");
    store.commit("projects/RESET_PAGINATION");
    store.commit("customerPortal/RESET_PAGINATION");
    if (toggle) {
      if (formattedAllItems.value.length === 0) {
        getCustomerPortalProjects();
      }
    } else {
      if (getFormattedProjects.value.length === 0) {
        store.dispatch("projects/getProjects");
      }
    }
  }
)

watch(
  () => router.currentRoute.value.params.tab, newHref => {
    test.value = rootPath.value;
    if (newHref.length > 1) {
      test.value = rootPath.value;
      for (let i=0; i<newHref.slice(1).length; i++) {
        test.value += newHref[i+1] + '/';
      }
    }
    store.dispatch("customerPortal/getItems", {
      folderName: test.value
    })
  }
)

</script>

<style scoped lang="scss">
input[type=checkbox] {
  margin-left: 3ex;
}
.customer-portal {
  display: grid;
  grid-template-columns: 1fr 9fr;
}
.middle-body-element-list {
  position: relative;
  z-index: 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.customer-portal-disabled {
  opacity: 0.4;
  pointer-events: none;
}
.toggle-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3em 0 1em 0;
}
.toggle-text {
  margin: 0 1ex;
  font-size: 20px;
}
.form-switch .form-check-input:checked {
  background-color: rgb(255, 255, 255);
  border-color: rgb(0, 0, 0);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='black'/%3e%3c/svg%3e");
}
.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='black'/%3e%3c/svg%3e");
  margin-left: -2.3em;
  // margin-right: 2em;
}
.no-customer-portal-access {
  font-size: 20px;
  margin: 3em;
}
</style>