import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.js"
import { FontAwesomeIcon } from "./icons";

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from "vue-cookies";
import "./axiosInterceptor";
import "./styles.scss";

createApp(App).use(store).use(router).use(VueCookies).component(
  "icon", FontAwesomeIcon).mount('#app')