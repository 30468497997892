import axios from "axios";

const state = {
  users: [],
  hotbotNumberObject: null,
  dbUsers: [],
  currentDisplay: {},
  currentColor: {},
  currentProject: {},
  selectedUser: null,
  currentProcess: "",
  selectedUserDisplay: [],
  selectedUserColors: [],
  selectedUserProjects: [],
  selectedUserBespokeTemplateIds: [],
  selectingBespoke: false,
  newlySelectedBespokeTemplateIds: []
}

const getters = {
  formattedUsers: state => {
    let braintree_array = {};
    let hasCustomerPortalAccessObjects = {};
    state.dbUsers.forEach(user => {
      braintree_array[user.username] = user.braintree_id;
      hasCustomerPortalAccessObjects[user.username] = user.hasCustomerPortalAccess;
    });
    let users = [];
    state.users.map(user => {
      const obj = {};
      for (let i = 0; i < user.Attributes.length; i++) {
        switch (user.Attributes[i].Name) {
          case "custom:company":
            obj.company = user.Attributes[i].Value;
            break;
          case "phone_number":
            obj.phone = user.Attributes[i].Value;
            break;
          case "email":
            obj.email = user.Attributes[i].Value;
            break;
          case "given_name":
            obj.given_name = user.Attributes[i].Value;
            break;
          case "family_name":
            obj.family_name = user.Attributes[i].Value;
            break;
          case "email_verified":
            obj.emailVerified = user.Attributes[i].Value;
            break;
          case "custom:CE_user_id":
            obj.ceID = user.Attributes[i].Value;
            break;
          case "custom:role":
            obj.role = user.Attributes[i].Value;
            break;
          default:
        }
      }
      obj.username = user.Username;
      obj.userstatus = user.UserStatus;
      obj.created = user.UserCreateDate;
      obj.name = obj.given_name + " " + obj.family_name;
      obj.enable = user.Enabled;
      obj.braintree_id = braintree_array[user.Username];
      obj.hasCustomerPortalAccess = hasCustomerPortalAccessObjects[user.Username];
      // obj.subscription = subscribed_array[user.Username];
      users.push(obj);
    })
    return users;
  },
  getSelectedUser: state => {
    return state.selectedUser;
  },
  getSelectedUserDisplays: state => {
    return state.selectedUserDisplay;
  },
  getSelectedUserColors: state => {
    let colors = [];
    state.selectedUserColors.forEach(color => {
      let tempColor = {...color};
      tempColor.hexCode = tempColor.hex_code;
      delete tempColor.hex_code;
      colors.push(tempColor);
    })
    return colors;
  },
  getSelectedUserProjects: state => {
    return state.selectedUserProjects;
  },
  getSelectedUserBespokeTemplateIds: state => {
    return state.selectedUserBespokeTemplateIds;
  },
  getSelectedUserBespokeTemplates: (state, getters, rootState, rootGetters) => {
    return rootGetters["adminTemplates/filterTemplates"]('Published').filter(template => {
      return state.selectedUserBespokeTemplateIds.includes(template.id);
    });
  },
  getAllTemplatesExceptFree: (state, getters, rootState, rootGetters) => {
    return rootGetters["adminTemplates/filterTemplates"]('Published').filter(template => {
      return !rootState.templates.freeTemplateIds.includes(template.id);
    })
  },
  getAllTemplatesExceptPopular: (state, getters, rootState, rootGetters) => {
    return rootGetters["adminTemplates/filterTemplates"]('Published').filter(template => {
      return !rootState.adminPopularSeasonalVideos.popularVideoIds.includes(template.id);
    })
  },
  getAllTemplatesExceptSeasonal: (state, getters, rootState, rootGetters) => {
    return rootGetters["adminTemplates/filterTemplates"]('Published').filter(template => {
      return !rootState.adminPopularSeasonalVideos.seasonalVideoIds.includes(template.id);
    })
  },
  getAllTemplatesExceptBespoke: (state, getters, rootState, rootGetters) => {
    return rootGetters["adminTemplates/filterTemplates"]('Published').filter(template => {
      return !state.selectedUserBespokeTemplateIds.includes(template.id);
    })
  },
  getSelectingBespokeTemplates: state => {
    return state.selectingBespoke;
  },
  getNewlySelectedBespokeTemplateIds: state => {
    return state.newlySelectedBespokeTemplateIds;
  },
  currentDisplayNameExists: state => {
    for (let i=0; i<state.selectedUserDisplay.length; i++) {
      if (state.currentDisplay.name === state.selectedUserDisplay[i].name) {
        return true;
      }
    }
    return false;
  },
  currentDisplayResolutionExists: state => {
    let temp = [...state.selectedUserDisplay];
    if (state.currentProcess === 'update') {
      temp = temp.filter(x => x.id !== state.currentDisplay.id);
    }
    for (let i=0; i<temp.length; i++) {
      if (parseInt(state.currentDisplay.width) === parseInt(temp[i].width) && parseInt(state.currentDisplay.height) === parseInt(temp[i].height)) {
        return true;
      }
    }
    return false;
  },
  getCurrentProject: state => {
    return state.currentProject;
  },
  getHotbotNumberObject: state => {
    return state.hotbotNumberObject;
  }
}

const mutations = {
  SET_USERS(state, users) {
    state.users = users;
  },
  SET_DB_USERS(state, users) {
    state.dbUsers = users;
  },
  SET_SELECTED_USER(state, user) {
    state.selectedUser = user;
  },
  SET_CURRENT_DISPLAY(state, display) {
    state.currentDisplay = display;
  },
  SET_CURRENT_PROJECT(state, project) {
    state.currentProject = project;
  },
  SET_SELECTED_USER_DISPLAYS(state, displays) {
    state.selectedUserDisplay = displays;
  },
  SET_SELECTED_USER_COLORS(state, colors) {
    state.selectedUserColors = colors;
  },
  SET_SELECTED_USER_PROJECTS(state, projects) {
    state.selectedUserProjects = projects;
  },
  SET_CURRENT_PROCESS(state, process) {
    state.currentProcess = process;
  },
  CLEAR_CURRENT_DISPLAY(state) {
    state.currentDisplay = {
      name: "",
      width: 0,
      height: 0,
      id: "",
      user_id: "",
      isMagicinfo: 0
    };
  },
  SET_HOTBOT_NUMBER_OBJECT(state, hotbotNumberObject) {
    state.hotbotNumberObject = hotbotNumberObject;
  },
  UPDATE_COLORS(state, updatedColors) {
    state.selectedUserColors = state.selectedUserColors.map(color => {
      return color.id === updatedColors.id
      ? { id: color.id, hexCode: updatedColors.hexCode }
      : color;
    });
  },
  SET_SELECTED_USER_BESPOKE_TEMPLATE_IDS(state, ids) {
    state.selectedUserBespokeTemplateIds = ids;
  },
  SET_SELECTING_BESPOKE_TEMPLATES(state, boolean) {
    state.selectingBespoke = boolean;
  },
  SET_NEWLY_SELECTED_BESPOKE_TEMPLATE_IDS(state, newlySelectedBespokeTemplateIds) {
    state.newlySelectedBespokeTemplateIds = newlySelectedBespokeTemplateIds;
  }
}

const actions = {
  async generateKey({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.post(`${process.env.VUE_APP_SERVER_URL}/api/admin-users/generate-keys`, {
        keyCount: context.keyCount,
        durationPeriod: context.durationPeriod,
        durationUnit: context.durationUnit,
        plan: context.plan,
        email: context.email
      }, {
        withCredentials: true
      })
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully generated key."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async getUsers({ commit, dispatch }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/admin-users/user-list`, {
        withCredentials: true
      });
      commit("SET_USERS", response.data);
      await dispatch("getDBUsers");
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async getDBUsers({ commit }) {
    try {
      const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/admin-users/all-DB-Users`, {
        withCredentials: true
      });
      commit("SET_DB_USERS", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getUserPreferences({ commit, dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/admin-users/user-preferences-and-projects?id=` + context.id, {
        withCredentials: true
      })
      commit("SET_SELECTED_USER_DISPLAYS", response.data.displays);
      commit("SET_SELECTED_USER_COLORS", response.data.colors);
      commit("SET_SELECTED_USER_PROJECTS", response.data.projects[0].reverse());
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async getHotbotNumberObject({ commit, dispatch }) {
    try {
      const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/admin-users/get-hotbot-number`, {
        withCredentials: true
      });
      commit("SET_HOTBOT_NUMBER_OBJECT", response.data[0][0]);
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true });
    }
  },
  async updateHotbotNumber({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.put(`${process.env.VUE_APP_SERVER_URL}/api/admin-users/update-hotbot-number`, {
        hotbotNumber: context.hotbotNumber
      }, {
        withCredentials: true
      })
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully updated user."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async updateHasCustomerPortal({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.put(`${process.env.VUE_APP_SERVER_URL}/api/admin-users/update-customer-portal-access`, {
        userId: context.userId,
        hasCustomerPortalAccess: context.hasCustomerPortalAccess,
      }, {
        withCredentials: true
      });
      await dispatch("getDBUsers");
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully updated user."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async updateUser({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.put(`${process.env.VUE_APP_SERVER_URL}/api/admin-users/admin-update-user-attribute`, {
        userId: context.userId,
        username: context.username,
        given_name: context.given_name,
        family_name: context.family_name,
        phone: context.phone,
        company: context.company
      }, {
        withCredentials: true
      })
      await dispatch("getUsers");
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully updated user."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async addDisplay({ state, dispatch }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.post(`${process.env.VUE_APP_SERVER_URL}/api/admin-users/add-display`, state.currentDisplay, {
        withCredentials: true
      })
      await dispatch("getUserPreferences", {
        id: state.selectedUser.ceID
      });
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully created display."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async updateDisplay({ state, dispatch }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.put(`${process.env.VUE_APP_SERVER_URL}/api/admin-users/edit-display`, state.currentDisplay, {
        withCredentials: true
      })
      await dispatch("getUserPreferences", {
        id: state.selectedUser.ceID
      });
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully updated display."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async deleteDisplay({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.delete(`${process.env.VUE_APP_SERVER_URL}/api/admin-users/delete-display`, {
        data: { id: context.id, user_id: context.user_id },
        withCredentials: true
      });
      await dispatch("getUserPreferences", {
        id: state.selectedUser.ceID
      });
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully deleted display."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async addColor({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.post(`${process.env.VUE_APP_SERVER_URL}/api/admin-users/add-color`, {
        userId: context.userId,
        hexCode: context.hexCode
      }, {
        withCredentials: true
      });
      await dispatch("getUserPreferences", {
        id: state.selectedUser.ceID
      });
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully added color."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async updateColor({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.put(`${process.env.VUE_APP_SERVER_URL}/api/admin-users/edit-color`, {
        id: context.id,
        hexCode: context.hexCode
      }, {
        withCredentials: true
      });
      await dispatch("getUserPreferences", {
        id: state.selectedUser.ceID
      });
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully updated color."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async deleteColor({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      for (let i=0; i<context.colorIds.length; i++) {
        await axios.delete(`${process.env.VUE_APP_SERVER_URL}/api/admin-users/delete-color`, {
          data: {id: context.colorIds[i]},
          withCredentials: true
        });
      }
      await dispatch("getUserPreferences", {
        id: state.selectedUser.ceID
      });
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully deleted color."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async getBespokeTemplatesByUserId({ commit }, context) {
    try {
      const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/admin-users/bespoke-videos-by-user-id`, {
        params: {
          userId: context.userId
        },
        withCredentials: true
      });
      let ids = response.data.map(bespoke => {
        return bespoke.template_id
      })
      commit("SET_SELECTED_USER_BESPOKE_TEMPLATE_IDS", ids);
    } catch (error) {
      console.log(error);
    }
  },
  async setTemplatesToUserBespoke({ dispatch } ) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      let allBespokeTemplates = state.newlySelectedBespokeTemplateIds;
      console.log(allBespokeTemplates);
      await axios.put(`${process.env.VUE_APP_SERVER_URL}/api/admin-users/set-bespoke-templates`, {
        templates: allBespokeTemplates,
        userId: state.selectedUser.ceID
      }, {
        withCredentials: true
      })
      await dispatch("adminTemplates/getTemplates", {}, { root: true });
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully updated free templates."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("alert/stopLoading", {}, { root: true });
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}