<template>
  <div class="card-container card mt-2 mb-3">
    <img id="template-video-thumbnail" :src="getVideoThumbnail" alt="no image" />
    <div class="template-video-name">
      {{ props.video.name }}
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, computed } from "vue";

const props = defineProps({
  video: Object
})
const videoType = ref("");

if (Object.keys(props.video).includes("aep")) {
  videoType.value = "templates";
} else {
  videoType.value = "stock";
}

const getVideoThumbnail = computed(() => {
  return (
    `${process.env.VUE_APP_SERVER_URL}/api/files/` +
    videoType.value +
    "/" +
    props.video.s3_id +
    "/poster/" +
    "poster.jpg"
  );
})

</script>

<style scoped lang="scss">
.card-container {
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s ease;
  &:hover {
    transform: scale(1.04);
  }
}
#template-video-thumbnail {
  border-radius: 10px;
}
.template-video-name {
  #x-mark {
    position: absolute;
    right: 0;
  }
}
</style>