import axios from "axios";

const state = {
  startDate: null,
  endDate: null,
  jsEndDate: null,
  message: "",
  isActive: false
}

const getters = {
  maintenanceActive: state => {
    return state.isActive;
  },
  getMaintenanceMessage: state => {
    return state.message;
  },
  getMaintenanceStatus: state => {
    if (state.isActive) {
      return `Maintenance is scheduled between ${state.startDate} and ${state.endDate}.`;
    }
    return "No Maintenance Scheduled."
  }
}
const mutations = {
  SET_START_DATE(state, startDate) {
    state.startDate = startDate;
  },
  SET_END_DATE(state, endDate) {
    state.endDate = endDate;
  },
  SET_MESSAGE(state, message) {
    state.message = message;
  },
  SET_IS_ACTIVE(state, active) {
    state.isActive = active;
  }
}
const actions = {
  async getMaintenance({ commit, dispatch }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      let response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/admin/maintenance-schedule`, {
        withCredentials: true
      });
      commit("SET_START_DATE", new Date(response.data.startDate));
      commit("SET_END_DATE", new Date(response.data.endDate));
      let active = new Date() > new Date(response.data.startDate) && new Date(response.data.endDate) > new Date();
      commit("SET_IS_ACTIVE", active);
      commit("SET_MESSAGE", response.data.message);
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async updateMaintenance({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.put(`${process.env.VUE_APP_SERVER_URL}/api/admin/update-maintenance`, {
        start_date: context.startDate,
        end_date: context.endDate,
        message: context.message
      }, {
        withCredentials: true
      })
      await dispatch("getMaintenance");
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Maintenance posted."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}