<template>
  <div class="customer-portal-large-icon-list">
    <div class="my-row">
      <div class="my-col"
        v-for="(item, index) in formattedAllItems" :key="index"
        :class="{'object-selected': selectedItems[item.name]}"
      >
        <icon v-show="item.type === 'video'" icon="circle-play" class="play-circle"/>
        <img
          v-if="item.type !== 'video'"
          class="object-image"
          :class="{'object-image-selected': selectedItems[item.name]}"
          :src="getItemImageSrc(item)"
          :alt="item.name"
          @click="clickItem(item)"
        />
        <video
          v-else
          allowfullscreen
          class="object-image"
          :class="{'object-image-selected': selectedItems[item.name]}"
          type="video" control
          preload="none"
          autoplay
          :poster="getItemImageSrc(item)"
          @click="clickItem(item)"
        >
          <source type="vieo/mp4">
          <source type="vieo/webm">
        </video>
        <!-- <icon v-show="selecting" class="select-circle" :icon="selectedItems[item.name] ? 'circle' : 'fa-regular fa-circle'" /> -->
        <span v-if="item.name.length < 15"> {{ item.name }} </span>
        <span v-else>{{ item.name.slice(0, 15) }}...</span>
      </div>
    </div>
    <div v-if="formattedAllItems.length === 0" class="center-item">
      <h3><i>Empty</i></h3>
    </div>
  </div>
</template>

<script setup>
import FolderImage from "@/assets/folder.png";
import FileImage from "@/assets/fileImage.png";
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
// setup
const store = useStore();
const router = useRouter();
// states
const rootPath = computed(() => store.state.customerPortal.rootPath);
const thumbnailRootPath = computed(() => store.state.customerPortal.thumbnailRootPath);
const selecting = computed(() => store.state.customerPortal.selecting);
const selectedItems = computed(() => store.state.customerPortal.selectedItems);
const isAdmin = computed(() => store.state.customerPortal.isAdmin);
const currentPath = computed(() => store.state.customerPortal.currentPath);
// mutations
const formattedAllItems = computed(() => store.getters["customerPortal/formattedAllItems"]);
// mutations
const getItemImageSrc = item => {
  let imageSrc = FolderImage;
  if (item.type === "file") {
    imageSrc = FileImage;
  } else if (item.type === "image") {
    imageSrc = `${process.env.VUE_APP_SERVER_URL}/api/customer-portal/${item.path}${item.name}`;
  } else if (item.type === "video") {
    imageSrc = `${process.env.VUE_APP_SERVER_URL}/api/customer-portal/${thumbnailRootPath.value}${item.path.slice(rootPath.value.length)}${item.name.split('.').slice(0, -1).join('.')+'.jpg'}`;
  }
  return imageSrc;
}
const clickItem = async item => {
  if (selecting.value) {
    let tempSelectedItems = JSON.parse(JSON.stringify(selectedItems.value));
    tempSelectedItems[item.name] ? 
    delete tempSelectedItems[item.name] : 
    tempSelectedItems[item.name] = item;
    store.commit("customerPortal/SET_SELECTED_ITEMS", tempSelectedItems);
    return;
  }
  if (item.type === "folder") {
    if (isAdmin.value) {
      router.push(`/admin/customer-portal/${item.path.slice(rootPath.value.length)}${item.name}`);
    } else {
      router.push(`/customer-portal/${item.path.slice(rootPath.value.split("/")[0].length + 1)}${item.name}`);
    }
    await store.dispatch("customerPortal/getItems", { folderName: `${item.path}${item.name}`, resetSelected: false })
    store.commit("customerPortal/SET_CURRENT_PAGE", 1);
    return;
  }
  let file = {
    name: item.name,
    path: item.path,
    type: item.type,
    media: `${process.env.VUE_APP_SERVER_URL}/api/customer-portal/${currentPath.value}${item.name}`
  }
  store.commit("customerPortal/SET_CURRENT_FILE", file);
  store.dispatch("modal/showModal", {
     modalTitle: file.name, 
     modalName: "CustomerPortalFileDetail"
    }
  )
}


</script>

<style scoped lang="scss">
.my-row {
  display: grid;
  grid-template-columns: minmax(150px, max-content) repeat(auto-fill, 150px);
}
.my-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1ex;
}
.customer-portal-large-icon-list {
  min-height: 35ex;
}
.select-circle {
  position: absolute;
}
.play-circle {
  position: absolute;
  color: white;
  transform: translate(0%, 130%);
  font-size: 2em;
}
.center-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.object {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1ex 0;
}
.object-selected {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
}
.object-image {
  width: 15ex;
  height: 12ex;
  cursor: pointer;
  flex-basis: fit-content;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  }
}
.object-image-selected {
  padding: 2ex;
}
</style>