<template>
  <div class="container-fluid">
    <div class="header">
      <p>Enter 6-digit code from your two factor authenticator App.</p>
    </div>
    <div class="mfa-number">
      <!-- <input v-for="input in 6" :key="input" :class="`mfa-input input-${input}`" type="text" 
        maxlength="1" @keydown="gotoNextInput($event, input)" v-model="inputs.mfaCode[input-1]"/> -->
      <input type="text" v-model="inputs.mfaCode" class="input-box-1" maxlength="6" placeholder="MFA Code..."/>
    </div>
    <span>{{ getCountDownTime }}</span>
    <div v-show="inputs.countDown === 0" class="mfa-expired-message">
      Your MFA code has expired.
    </div>
    <span v-show="inputs.countDown === 0" style="color: black" class="resendButton" @click="generateAndSendCode">Resend</span>
    <div class="buttons">
      <button type="button" class="modified-button" id="cancel-button" @click="goBack">
        Cancel
      </button>
      <button type="button" class="modified-button" id="authenticate-button" @click="authenticate">
        Authenticate
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();
// states
const currentUserId = computed(() => store.state.user.currentUser.id);
// getters
const mfaCode = computed(() => store.getters["user/getMFACode"]);
const credentials = computed(() => store.getters["user/getCredentials"]);
const mfaCountDownTime = computed(() => store.getters["user/getMFACountDownTime"]);
const gotoAfterLoginRouter = computed(() => store.getters["alert/getGotoAfterLoginRouter"]);

let inter;
let countDownInter;

const inputs = ref({
  countDown: mfaCountDownTime.value,
  randomCode: "",
  mfaCode: ""
})

// methods
const authenticate = async () => {
  if (mfaCode.value === parseInt(inputs.value.mfaCode)) {
    await store.dispatch("user/login", credentials.value);
    if (currentUserId.value) {
      router.push(`${gotoAfterLoginRouter.value}`);
    }
  } else {
    store.dispatch("alert/getAlert", {
      alert: "danger", message: "Incorrect MFA Code. Please try again."
    })
  }
}
const generateAndSendCode = async () => {
  inputs.value.countDown = mfaCountDownTime.value;
  inputs.value.randomCode = Math.floor(100000 + Math.random() * 900000);
  store.commit("user/SET_MFA_CODE", inputs.value.randomCode);
  await store.dispatch("user/sendMFA");
  inter = setInterval(() => {
    inputs.value.randomCode = "";
    store.commit("user/SET_MFA_CODE", "");
    clearInterval(inter);
  }, mfaCountDownTime.value * 1000)
  countDownInter = setInterval(() => {
    inputs.value.countDown -= 1;
    if (inputs.value.countDown === 0) {
      clearInterval(countDownInter);
    }
  }, 1000)
}
const goBack = () => {
  store.commit("user/SET_NEED_MFA", false);
}

// computed
const getCountDownTime = computed(() => {
  let mins = Math.floor(inputs.value.countDown / 60).toString();
  let secs = (inputs.value.countDown % 60).toString();
  mins = mins.length === 1 ? '0' + mins : mins;
  secs = secs.length === 1 ? '0' + secs : secs;
  return `${mins}:${secs}`
})


onMounted(async () => {
  // document.getElementsByClassName("input-1")[0].focus();
  generateAndSendCode();
})

onBeforeUnmount(() => {
  clearInterval(inter);
  clearInterval(countDownInter);
})
</script>


<style scoped lang="scss">
.mfa-number {
  width: 80%;
  margin: auto;
}
.mfa-input {
  width: 50px;
  height: 50px;
  margin: 1ex;
  text-align: center;
  font-size: 20px;
}
.mfa-expired-message {
  color: red;
}
.resendButton {
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid black;
  }
}
.modified-button {
  width: 35%;
  margin: 1em;
}
#cancel-button {
  &:hover {
    background-color: var(--ce-cancel-button-color);
  }
}
#authenticate-button {
  &:hover {
    background-color: var(--ce-submit-button-color);
  }
}
</style>