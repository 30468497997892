import axios from "axios";
import store from "./store";
import router from './router'

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response.data.url === "/MagicInfo/restapi/v2.0/auth" && error.response.data.errorCode === "401010") {
      store.commit("magicinfo/SET_NEED_MAGICINFO_MFA", true);
      return;
    }
    const isLoggedIn = !!store.state.user.currentUser.id;
    let errorStatus;
    if (error.response) {
      if (error.response.status) {
        errorStatus = error.response.status;
      }
    }

    const statusCodesToLogout = [401, 403, 419];
    if (
      isLoggedIn &&
      statusCodesToLogout.includes(errorStatus)
    ) {
      store.dispatch("user/logout");
      store.dispatch("alert/getAlert", {
        alert: "danger",
        message: "Your session has timed out. Please log in again."
      })
      store.dispatch("alert/stopLoading");
      router.push("/login");
    }
    return Promise.reject(error);
  }
);
