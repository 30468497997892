<template>
  <div class="container-fluid">
    <div class="add-display-body">
      <div class="display-name">
        <label class="label">Display Name:</label>
        <input class="input" type="text" placeholder="Display name..." v-model="inputs.name" @change="setCurrentInputValue('name', $event)"/>
        <label class="label">Magicinfo:</label>
        <input class="checkbox" type="checkbox" :checked="inputs.isMagicinfo" :value="inputs.isMagicinfo" @change="setCurrentInputValue('isMagicinfo', $event)"/>
      </div>
      <div class="display-resolution">
        <span>Resolution (pixels)</span>
        <label class="label" id="width">Width:</label>
        <input class="input" type="number" placeholder="Width..." v-model="inputs.width" @change="setCurrentInputValue('width', $event)"/>
        <label class="label" id="height">Height:</label>
        <input class="input" type="number" placeholder="Height..." v-model="inputs.height" @change="setCurrentInputValue('height', $event)"/>
      </div>
    </div>
    <div class="add-display-footer">
      <button type="button" class="modified-button"
        id="add-display-button"
        @click="addUpdateDisplay"
      >
        <span v-if="currentProcess === 'new'">Add</span>
        <span v-else-if="currentProcess === 'update'">Update</span>
      </button>
      <button type="button" class="modified-button" 
        id="cancel-display-button"
        @click="closeModal"
      >
        Cancel
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

// getters
const currentUser = computed(() => store.getters["user/getCurrentUser"]);
const selectedUser = computed(() => store.getters["adminUsers/getSelectedUser"]);
const currentDisplay = computed(() => store.getters["userPreferences/getCurrentDisplay"]);
const currentProcess = computed(() => store.getters["userPreferences/getCurrentProcess"]);
const currentDisplayNameExists = computed(() => store.getters["userPreferences/currentDisplayNameExists"]);
const currentDisplayNameExistsAdmin = computed(() => store.getters["adminUsers/currentDisplayNameExists"]);
const currentDisplayResolutionExists = computed(() => store.getters["userPreferences/currentDisplayResolutionExists"]);
const currentDisplayResolutionExistsAdmin = computed(() => store.getters["adminUsers/currentDisplayResolutionExists"]);
const currentInputs = computed(() => store.getters["checkError/getCurrentInputs"]);
const errorNumbers = computed(() => store.getters["checkError/getErrorNumber"]);

const adminEditing = ref(false);
const inputs = ref({
  name: currentDisplay.value.name,
  width: currentDisplay.value.width,
  height: currentDisplay.value.height,
  id: currentDisplay.value.id,
  user_id: selectedUser.value ? selectedUser.value.ceID : currentUser.value.id,
  isMagicinfo: currentDisplay.value.isMagicinfo
})

// methods
const setCurrentInputValue = (key, e) => {
  let value = e.target.value;
  if (key === "isMagicinfo") {
    inputs.value.isMagicinfo = !inputs.value.isMagicinfo;
  }
  store.commit("checkError/SET_CURRENT_INPUT_VALUE", {
    key: key,
    value: value
  });
}
const addUpdateDisplay = async () => {
  store.commit("checkError/SET_ERROR_NUMBER", 0);
  for (let i=0; i<Object.keys(currentInputs.value).length; i++) {
    let input = currentInputs.value[Object.keys(currentInputs.value)[i]];
    for (let j=0; j<input.checkErrors.length; j++) {
      let checkError = input.checkErrors[j];
      store.dispatch(`checkError/check${checkError}`, {
        ...currentInputs.value[Object.keys(currentInputs.value)[i]]
      });
    }
    if (errorNumbers.value > 0) {
      break;
    }
  }
  if (errorNumbers.value === 0) {
    if (adminEditing.value) {
      store.commit("adminUsers/SET_CURRENT_DISPLAY", inputs.value);
    } else {
      store.commit("userPreferences/SET_CURRENT_DISPLAY", inputs.value);
    }
    if (checkDisplayResolution.value) {
      store.dispatch("alert/getAlert", {
        alert: "danger",
        message: `Display "${inputs.value.width} x ${inputs.value.height}" already exists. Please use different width and height.`
      });
      return;
    }
    if (currentProcess.value === "new") {
      if (checkDisplayName.value) {
        store.dispatch("alert/getAlert", {
          alert: "danger",
          message: `Display "${inputs.value.name}" already exists. Please use a different name.`
        });
        return;
      }
      if (adminEditing.value) {
        await store.dispatch("adminUsers/addDisplay");
      } else {
        await store.dispatch("userPreferences/addDisplay");
      }
    } else if (currentProcess.value === "update") {
      if (adminEditing.value) {
        await store.dispatch("adminUsers/updateDisplay");
      } else {
        await store.dispatch("userPreferences/updateDisplay");
      }
    }
  }
  closeModal();
}
// computed
const checkDisplayName = computed(() => {
  if (adminEditing.value) {
    return currentDisplayNameExistsAdmin.value;
  }
  return currentDisplayNameExists.value;
})
const checkDisplayResolution = computed(() => {
  if (adminEditing.value) {
    return currentDisplayResolutionExistsAdmin.value;
  }
  return currentDisplayResolutionExists.value;
})
const closeModal = () => {
  store.dispatch("modal/closeModal");
}

// created
store.commit("checkError/SET_CURRENT_INPUTS", {
  name: {
    input: currentDisplay.value.name,
    inputName: "Display Name",
    checkErrors: ["CannotBeEmpty"]
  },
  width: {
    input: currentDisplay.value.width,
    inputName: "Display Width",
    checkErrors: ["ShouldBeNumber", "CannotBeZero"]
  },
  height: {
    input: currentDisplay.value.height,
    inputName: "Display Height",
    checkErrors: ["ShouldBeNumber", "CannotBeZero"]
  },
  isMagicinfo: {
    input: currentDisplay.value.isMagicinfo,
    inputName: "Magicinfo Checkbox",
    checkErrors: []
  }
})

adminEditing.value = selectedUser.value !== null ? true : false;

console.log(adminEditing.value);

</script>

<style scoped lang="scss">
.container-fluid {
  width: 60%;

}
.add-display-footer {
  // display: flex;
  // flex-direction: row-reverse;
  margin: 2em 0 1em 0;
}
.display-name, .display-resolution {
  display: flex;
  flex-direction: column;
  margin: 2em 0;
  span {
    display: flex;
    margin-left: 1em;
    font-weight: bold;
  }
  .label {
    display: flex;
    font-weight: bold;
    margin-left: 1em;
  }
  .input {
    width: 90%;
    margin: 1em auto;
    border: none;
    border-bottom: 0.5px solid grey;
    &:focus {
      outline: none;
    }
  }
  #width, #height {
    margin-left: 2em;
    font-style: italic;
  }
}
#add-display-button {
  width: 40%;
  margin: 0 1em;
  &:hover {
    background-color: var(--ce-add-button-color);
  }
}
#cancel-display-button {
  width: 40%;
  &:hover {
    background-color: var(--ce-cancel-button-color);
  }
}
</style>
