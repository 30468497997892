<template>
  <div class="home">
    <div class="top-section">
      <video autoplay loop muted class="video" :poster="PureBlack">
        <source :src="getHomepageVideo" />
      </video>
      <div class="top-section-container">
        <h3>CREATE CUSTOM LED CONTENT IN MINUTES!</h3>
        <button type="button" class="modified-button" @click="browseMoreVideos">Go to Library</button>
      </div>
    </div>
    <div class="middle-section-container">
      <p style="margin: 2em">How to create your video</p>
      <StepsHomeComponent />
      <button type="button" class="btn btn-dark btn-sm button"
        @click="browseMoreVideos"
      >
        GET STARTED
      </button>
    </div>
    <div class="bottom-section-container">
      <div class="popular-videos" v-show="popularVideos.length !== 0">
        <p style="margin: 2em">Popular Videos</p>
        <PopularTemplates />
      </div>
      <div class="seasonal-videos" v-show="seasonalVideos.length !== 0">
        <p style="margin: 2em">Seasonal Videos</p>
        <SeasonalTemplates />
      </div>
      <button type="button" class="btn btn-dark btn-sm button"
        @click="browseMoreVideos">
        BROWSE MORE VIDEOS
      </button>
    </div>
  </div>
</template>

<script setup>
import PureBlack from "@/assets/PureBlack.jpg";
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import StepsHomeComponent from "@/components/Home/StepsHomeComponent.vue";
import PopularTemplates from "@/components/Home/PopularTemplates.vue";
import SeasonalTemplates from "@/components/Home/SeasonalTemplates.vue";

const router = useRouter();
const store = useStore();
const popularVideos = computed(() => store.getters["adminPopularSeasonalVideos/getPopularVideos"]);
const seasonalVideos = computed(() => store.getters["adminPopularSeasonalVideos/getSeasonalVideos"]);

// methods
const browseMoreVideos = () => {
  router.push("/library");
}

// computed
const getHomepageVideo = computed(() => {
  return `${process.env.VUE_APP_SERVER_URL}/api/files/assets/may-homepage-video.mp4`;
})

</script>

<style scoped lang="scss">
* {
  font-weight: bold;
  font-size: 30px;
}
.video {
  height: auto;
  vertical-align: middle;
  width: 100%;
}
.top-section-container {
  position: absolute;
  left: 50%;
  top: 70%;
  z-index: 1;
  transform: translate(-50%, 50%);
  color: white;
  display: flex;
  flex-direction: column;
  .modified-button {
    background: none;
    width: 50%;
    font-size: 15px;
    color: white;
    margin: 1em auto;
    border: 1px solid white;
    &:hover {
      background-color: white;
      color: black;
    }
  }

}
.button {
  font-size: 13px;
  border-radius: 30px;
  padding: 13px;
  margin: 13px;
}
@media only screen and (max-width: 1300px) {
  .top-section-container {
    display: none;
  }
}
</style>
