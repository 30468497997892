import axios from "axios";
import jsFileDownLoad from 'js-file-download';

const state = {
  projects: [],
  inProgress: {},
  statusListObject: {
    "queued": "Processing",
    "rendered": "Completed",
    "draft": "Draft",
    "failed": "Error"
  },
  imageTypes: ["jpg", "jpeg", "png"],
  videoTypes: ["mp4", "wmv", "avi"],
  currentFilters: [],
  itemsPerPage: 20,
  currentPage: 1,
  currentFile: {},
  selectedProjects: {},
  selecting: false,
  downloading: false,
  downloadingProgress: 0,
  downloadingSize: 0,
  totalNumberOfProjectsOnTable: 0,
  search: "",
  copiedProjectId: null
}

const getters = {
  getFormattedProjects: (state) => {
    function getType(type) {
      if (state.imageTypes.includes(type)) {
        return "image";
      }
      if (state.videoTypes.includes(type)) {
        return "video";
      }
      return "file";
    }
    let projects = [];
    state.projects.forEach(project => {
      if (state.statusListObject[project.status]) {
        projects.push({
          id: project.id,
          date: project.date,
          name: project.name,
          type: getType(project.data_clay.fileFormat),
          format: project.data_clay.fileFormat,
          matrix: project.data_clay.matrix,
          status: state.statusListObject[project.status],
          selected: state.selectedProjects[project.id] ? true : false
        });
      }
    })
    if (state.currentFilters.length !== 0) {
      projects = projects.filter(item => state.currentFilters.includes(item.type));
    }
    if (state.search) {
      return projects.filter(
        item => item.name.toLowerCase().includes(state.search.toLowerCase())
      )
    }
    let currentPageFirstItem = (state.currentPage - 1) * state.itemsPerPage;
    return projects.slice(currentPageFirstItem, currentPageFirstItem + state.itemsPerPage);
  },
  getProjectDataClay: state => {
    for (let i=0; i<state.projects.length; i++) {
      if (state.projects[i].id === state.copiedProjectId) {
        return { 
          dataClay: state.projects[i].data_clay,
          templateId: state.projects[i].template_id
        }
      }
    }
    return null;
  },
  getNumberOfProjectsOnTable: state => {
    function getType(type) {
      if (state.imageTypes.includes(type)) {
        return "image";
      }
      if (state.videoTypes.includes(type)) {
        return "video";
      }
      return "file";
    }
    let projects = [];
    state.projects.forEach(project => {
      if (state.statusListObject[project.status]) {
        projects.push({
          name: project.name,
          type: getType(project.data_clay.fileFormat)
        });
      }
    })
    if (state.currentFilters.length !== 0) {
      projects = projects.filter(item => state.currentFilters.includes(item.type));
    }
    if (state.search) {
      return projects.filter(
        item => item.name.toLowerCase().includes(state.search.toLowerCase())
      )
    }
    return projects.length;
  },
  getSelectedProjects: state => {
    return state.selectedProjects;
  },
  getCurrentFile: state => {
    return state.currentFile;
  },
  someItemsSelected: state => {
    return Object.keys(state.selectedProjects).length > 0;
  },
  getTotalNumberOfPaginatioinNumber: (state, getters) => {
    return Math.ceil(getters.getNumberOfProjectsOnTable / state.itemsPerPage)
  },
  getSearch: state => {
    return state.search;
  },
  getDownloading: state => {
    return state.downloading;
  },
  getDownloadingProgress: state => {
    return state.downloadingProgress;
  }
}

const mutations = {
  SET_PROJECTS(state, projects) {
    state.projects = projects;
  },
  SET_COPIED_PROJECT_ID(state, id) {
    state.copiedProjectId = id;
  },
  SET_DELETED_PROJECTS(state) {
    let ids = Object.values(state.selectedProjects).map(project => {
      return project.id;
    })
    for (let i=0; i<state.projects.length; i++) {
      if (ids.includes(state.projects[i].id)) {
        state.projects[i].status = "deleted";
      }
    }
  },
  TOGGLE_CURRENT_FILTERS(state, filter) {
    let formattedFilter = filter.toLowerCase().slice(0, -1);
    if (state.currentFilters.includes(formattedFilter)) {
      state.currentFilters.splice(state.currentFilters.indexOf(formattedFilter), 1);
      return;
    }
    state.currentFilters.push(formattedFilter);
  },
  SET_SELECTED_PROJECTS(state, selectedProjects) {
    state.selectedProjects = selectedProjects;
  },
  SET_SELECTING(state, selecting) {
    state.selecting = selecting;
  },
  SET_CURRENT_FILE(state, currentFile) {
    state.currentFile = currentFile;
  },
  SET_ITEMS_PER_PAGE(state, itemsPerPage) {
    state.itemsPerPage = itemsPerPage;
  },
  SET_TOTAL_NUMBER_OF_PROJECTS_ON_TABLE(state, totalNumberOfProjectsOnTable) {
    state.totalNumberOfProjectsOnTable = totalNumberOfProjectsOnTable;
  },
  SET_CURRENT_PAGE(state, page) {
    state.currentPage = page;
  },
  SET_DOWNLOADING(state, bool) {
    state.downloading = bool;
  },
  SET_DOWNLOADING_SIZE(state, size) {
    state.downloadingSize = size;
  },
  SET_DOWNLOADING_PROGRESS(state, progress) {
    state.downloadingProgress = progress;
  },
  SET_SEARCH(state, search) {
    state.search = search;
  },
  RESET_CURRENT_FILTERS(state) {
    state.currentFilters = [];
  },
  RESET_PAGINATION(state) {
    state.currentPage = 1;
    state.itemsPerPage = 20;
  }
}

const actions = {
  async getProjects({ commit, dispatch }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      const projects = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/projects`, {
        withCredentials: true
      });
      await dispatch("userPreferences/getUserPreferences", {}, { root: true });
      commit("SET_PROJECTS", projects.data);
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async insertProject({ dispatch, rootState }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      let targets = [];
      let matrixes = [];
      
      for (let t = 0; t < rootState.dataClay.target.length; t++) {
        targets.push(rootState.dataClay.target[t]);
        matrixes.push(rootState.dataClay.matrix[t]);
      }

      const primaryAepPath = rootState.dataClay.aep.slice(0, -4);
      const newAepPath = target => {
        return primaryAepPath + target + ".aep";
      };

      let aeps = [];
      for (let a = 0; a < targets.length; a++) {
        const target = targets[a];
        const xIndex = target.indexOf("x");
        const targetHeight = target.slice(xIndex + 1, target.length);
        if (target.includes("432")) {
          aeps.push(newAepPath(targetHeight));
        } else {
          switch (targetHeight) {
            case "1920":
              aeps.push(newAepPath("432"));
              break;
            case "1440":
              aeps.push(newAepPath("324"));
              break;
            case "1080":
              aeps.push(newAepPath("1080"));
              break;
            case "960":
              aeps.push(newAepPath("216"));
              break;
            case "818":
              aeps.push(newAepPath("184"));
              break;
            case "710":
              aeps.push(newAepPath("160"));
              break;
            case "568":
              aeps.push(newAepPath("128"));
              break;
            case "480":
              aeps.push(newAepPath("108"));
              break;
            case "356":
              aeps.push(newAepPath("80"));
              break;
            default:
              break;
          }
        }
      }

      for (let i = 0; i < targets.length; i++) {
        const data = {};
        data.name = rootState.dataClay.projectName;
        data.templateId = rootState.templates.currentVideo.id;
        data.wizardProgress = JSON.stringify(rootState.wizardProgress);
        data.dataClay = Object.assign({}, rootState.dataClay);
        data.dataClay.target = targets[i];
        data.dataClay.isMagicinfo = matrixes[i].isMagicinfo;
        if (!data.dataClay.isMagicinfo) {
          data.dataClay.magicinfoToken = "";
          data.dataClay.magicinfoServer = "";
          data.dataClay.magicinfoOrganizationId = null;
        }
        // After Effects crashes on odd sized displays, but we do not
        // want to force the user's UI to show display size that is
        // not their own. So, we increase odd heights and widths by 1
        // to make them even, and then on the Account/Project page we
        // account for this by doing the same with displays so that we
        // can show them their Compatible Displays for a project
        // let [width, height] = matrixes[i].resolution.split("x");
        let [width, height] = [matrixes[i].width, matrixes[i].height];
        height = height % 2 !== 0 ? parseInt(height) + 1 : height;
        width = width % 2 !== 0 ? parseInt(width) + 1 : width;
        data.dataClay.matrix = width + "x" + height;
        data.dataClay.aep = aeps[i];
        data.dataClay = JSON.stringify(data.dataClay);
        // data.renderStartTime = rootState.canvasEditor.renderStartTime;
        let response = await axios.post(`${process.env.VUE_APP_SERVER_URL}/api/projects/add`, data, {
          withCredentials: true
        });
        if (rootState.user.guestEmail) {
          dispatch("createGuestEmailAndLinkProject", {
            guestEmail: rootState.user.guestEmail,
            projectId: response.data[0][0].projectID,
            optIn: 0
          })
        }
      }
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully sent project. An email will be sent when your project is ready."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async createGuestEmailAndLinkProject({ dispatch }, data) {
    try {
      await axios.post(
        `${process.env.VUE_APP_SERVER_URL}/api/projects/create-guest-user-and-link-project`,
        data
      );
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true });
    }
  },
  async downloadProjects({ state, commit, dispatch, rootState }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      commit("SET_DOWNLOADING", true);
      let numberOfFiles = Object.values(state.selectedProjects).length;
      commit("SET_DOWNLOADING_SIZE", numberOfFiles);
      let totalComplete = 0;
      for (let item of Object.values(state.selectedProjects)) {
        let currentFileSize = 0;
        let response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/files/projects/${encodeURIComponent(rootState.user.currentUser.username)}/${item.id}.${item.format}`, {
          responseType: "blob",
          onDownloadProgress: progressEvent => {
            currentFileSize = totalComplete;
            const percentage = Math.round(
              (currentFileSize * 100) / state.downloadingSize
            );
            console.log("percentage: ", percentage);
            commit("SET_DOWNLOADING_PROGRESS", percentage);
            if (progressEvent.loaded >= progressEvent.total) {
              totalComplete += 1;
              numberOfFiles -= 1;
              if (numberOfFiles === 0) {
                commit("SET_DOWNLOADING_PROGRESS", 100);
                setTimeout(() => {
                  commit("SET_DOWNLOADING", false);
                  commit("SET_DOWNLOADING_PROGRESS", 0)
                }, 500)
              }
            }
          },
          withCredentials: true
        })
        jsFileDownLoad(response.data, `${item.name}.${item.format}`);
      }
      dispatch("unselectAndCancel");
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully downloaded files."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async deleteProjects({ state, commit, dispatch }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.delete(`${process.env.VUE_APP_SERVER_URL}/api/projects/delete-project`, {
        data: { files: Object.values(state.selectedProjects)},
        withCredentials: true
      });
      await commit("SET_DELETED_PROJECTS", state.selectedProjects);
      dispatch("unselectAndCancel");
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully deleted selected files."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  unselectAndCancel({ commit }) {
    commit("SET_SELECTING", false);
    commit("SET_SELECTED_PROJECTS", {});
    commit("RESET_CURRENT_FILTERS");
    commit("SET_SEARCH", "");
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}