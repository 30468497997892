<template>
  <div class="container-fluid">
    <div class="maintenance-input-group">
      <label>Start Time: </label>
      <input class="input-box-1 input" type="datetime-local" v-model="inputs.startDate" @change="setCurrentInputValue('startDate', $event)"/>
    </div>
    <div class="maintenance-input-group">
      <label>End Time: </label>
      <input class="input-box-1 input" type="datetime-local" v-model="inputs.endDate" @change="setCurrentInputValue('endDate', $event)"/>
    </div>
    <div class="maintenance-input-message">
      <label>Message: </label>
      <textarea class="textarea" v-model="inputs.message" @change="setCurrentInputValue('message', $event)"/>
    </div>
    <div class="maintenance-status">
      {{ maintenanceStatus }}
    </div>
    <button v-show="isActive" class="modified-button" id="clear-button" type="button" @click="clearMaintenance">Clear Maintenance</button>
    <div class="maintenance-input-buttons">
      <button class="modified-button" id="cancel-button" type="button" @click="emit('cancel')">Cancel</button>
      <button class="modified-button" id="submit-button" type="button" @click="submit">Submit</button>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, ref, computed } from 'vue';
import { useStore } from "vuex";

const emit = defineEmits(['cancel']);

const store = useStore();

const inputs = ref({
  startDate: null,
  endDate: null,
  message: ""
});

// getters
const currentInputs = computed(() => store.getters["checkError/getCurrentInputs"]);
const errorNumbers = computed(() => store.getters["checkError/getErrorNumber"]);
const maintenanceStatus = computed(() => store.getters["maintenance/getMaintenanceStatus"]);
const isActive = computed(() => store.getters["maintenance/maintenanceActive"]);

// methods
const setCurrentInputValue = (key, e) => {
  let value = e.target.value;
  store.commit("checkError/SET_CURRENT_INPUT_VALUE", {
    key: key,
    value: value
  });
}
const clearMaintenance = () => {
  store.dispatch("maintenance/updateMaintenance", {
    startDate: null,
    endDate: null,
    message: ""
  })
}
const submit = async () => {
  store.commit("checkError/SET_ERROR_NUMBER", 0);
  for (let i=0; i<Object.keys(currentInputs.value).length; i++) {
    let input = currentInputs.value[Object.keys(currentInputs.value)[i]];
    for (let j=0; j<input.checkErrors.length; j++) {
      let checkError = input.checkErrors[j];
      store.dispatch(`checkError/check${checkError}`, {
        ...currentInputs.value[Object.keys(currentInputs.value)[i]]
      });
    }
    if (errorNumbers.value > 0) {
      break;
    }
  }
  if (errorNumbers.value === 0) {
    console.log(inputs.value);
    await store.dispatch("maintenance/updateMaintenance", {
      startDate: inputs.value.startDate,
      endDate: inputs.value.endDate,
      message: inputs.value.message
    });
    inputs.value = {
      startDate: null,
      endDate: null,
      message: ""
    }
  }
}

store.commit("checkError/SET_CURRENT_INPUTS", {
  startDate: {
    input: "",
    inputName: "Start Date",
    checkErrors: ["CannotBeNull"]
  },
  endDate: {
    input: "",
    inputName: "End Date",
    checkErrors: ["CannotBeNull"]
  },
  message: {
    input: "",
    inputName: "Maintenance Message",
    checkErrors: ["CannotBeEmpty"]
  }
})




</script>

<style scoped lang="scss">
label {
  margin-right: 1em;
}
.maintenance-input-group {
  margin: 1em 0;
}
.input {
  width: 30em;
}
.textarea {
  border: none;
  width: 30em;
  height: 15em;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.maintenance-input-message {
  display: flex;
  justify-content: center;
  align-items: center;
}
.maintenance-input-buttons {
  .modified-button {
    width: 30%;
    margin: 2em;
  }
}
.maintenance-status {
  margin: 1em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
#submit-button {
  &:hover {
    background-color: var(--ce-submit-button-color);
  }
}
#cancel-button {
  &:hover {
    background-color: var(--ce-cancel-button-color);
  }
}
#clear-button {
  width: 25%;
  &:hover {
    background-color: var(--ce-cancel-button-color);
  }
}

</style>