import axios from "axios";

const state = {

}

const getters = {
  
}

const mutations = {

}

const actions = {
  async sendMessage({ dispatch }, context) {
    let sent = false;
    dispatch("alert/startLoading", {}, { root: true });
    let response = await axios.post(`${process.env.VUE_APP_SERVER_URL}/api/contact/submit`, {
      message: context
    });
    if (response.data.includes("sent")) {
      dispatch("alert/getAlert", {
        alert: "success",
        message: "We've received your message. Thank you for your patience while we prepare our response."
      }, { root: true });
      sent = true;
    } else {
      dispatch("alert/getAlert", {
        alert: "danger", message: "There was a problem sending message. Please try again later." }, {
          root: true 
        }
      );
    }
    dispatch("alert/stopLoading", {}, { root: true });
    return sent ? true : false;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}