<template>
  <nav aria-label="Page navigation example">
    <ul class="pagination justify-content-center">
      <li class="page-item">
        <a class="page-link" @click="setCurrentPage(1)" aria-label="First">
          <span aria-hidden="true">First</span>
        </a>
      </li>
      <li class="page-item">
        <a class="page-link" @click="setCurrentPage(getCurrentPage === 1 ? 1 : getCurrentPage - 1)" 
          aria-label="Previous">
          <span aria-hidden="true">Prev</span>
        </a>
      </li>
      <li class="page-item" v-if="getTotalNumberOfPaginationNumber > 3 && getCurrentPage > 2">
        <a class="page-link" href="#" aria-label="Previous-hidden">
          <span aria-hidden="true">...</span>
        </a>
      </li>
      <li class="page-item" v-for="(page, index) in formatPaginationMiddleNumber" :key="index">
        <a class="page-link" :class="[getCurrentPage === page ? 'active' : '']"
          @click="setCurrentPage(page)">
          {{ page }}
        </a>
      </li>
      <li class="page-item" v-if="getTotalNumberOfPaginationNumber > 3 && getCurrentPage <= getTotalNumberOfPaginationNumber - 2">
        <a class="page-link" href="#" aria-label="Next-hidden">
          <span aria-hidden="true">...</span>
        </a>
      </li>
      <li class="page-item">
        <a class="page-link" 
        @click="setCurrentPage(getCurrentPage === getTotalNumberOfPaginationNumber ? 
        getTotalNumberOfPaginationNumber : getCurrentPage + 1)" aria-label="Next">
          <span aria-hidden="true">Next</span>
        </a>
      </li>
      <li class="page-item">
        <a class="page-link" @click="setCurrentPage(getTotalNumberOfPaginationNumber)" aria-label="Last">
          <span aria-hidden="true">Last</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

// states
const currentPage = computed(() => store.state.customerPortal.currentPage);
const currentPageProjects = computed(() => store.state.projects.currentPage);
// getters
const totalNumberOfPaginationNumber = computed(() => store.getters["customerPortal/getTotalNumberOfPaginatioinNumber"]);
const totalNumberOfPaginationNumberProjects = computed(() => store.getters["projects/getTotalNumberOfPaginatioinNumber"]);
const isMyPortal = computed(() => store.getters["customerPortal/getIsMyPortal"]);
// mutations

// methods
const getCurrentPage = computed(() => {
  if (!isMyPortal.value) {
    return currentPage.value;
  }
  return currentPageProjects.value;
})
const getTotalNumberOfPaginationNumber = computed(() => {
  if (!isMyPortal.value) {
    return totalNumberOfPaginationNumber.value;
  }
  return totalNumberOfPaginationNumberProjects.value;
})
const setCurrentPage = index => {
  if (!isMyPortal.value) {
    store.commit("customerPortal/SET_CURRENT_PAGE", index);
    return;
  }
  store.commit("projects/SET_CURRENT_PAGE", index);
}
const formatPaginationMiddleNumber = computed(() => {
  if (getTotalNumberOfPaginationNumber.value < 4) {
    let arr = [];
    for (let i=1; i<=getTotalNumberOfPaginationNumber.value; i++) {
      arr.push(i);
    }
    return arr;
  }
  if (getCurrentPage.value < 3) {
    return [1, 2, 3];
  }
  if (getCurrentPage.value >= getTotalNumberOfPaginationNumber.value - 1) {
    return [getTotalNumberOfPaginationNumber.value - 2, getTotalNumberOfPaginationNumber.value - 1, getTotalNumberOfPaginationNumber.value]
  }
  return [getCurrentPage.value - 1, getCurrentPage.value, getCurrentPage.value + 1];
});

</script>

<style scoped lang="scss">
.page-link {
  cursor: pointer;
  border: none;
  border-radius: 50%;
  color: black;
}
.active {
  color: white;
  background-color: black;
}
</style>