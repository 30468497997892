
const state = {
  active: false,
  modalTitle: "",
  modalName: "",
  notificationMessage: "",
  currentlyUpdaing: null
}

const getters = {
  getModalTitle: state => {
    return state.modalTitle;
  },
  getNotificationMessage: state => {
    return state.notificationMessage;
  },
  getCurrentlyUpdating: state => {
    return state.currentlyUpdaing;
  }
}

const mutations = {
  SET_ACTIVE(state, active) {
    state.active = active;
  },
  SET_MODAL_TITLE(state, title) {
    state.modalTitle = title;
  },
  SET_MODAL_NAME(state, modal) {
    state.modalName = modal;
  },
  SET_NOTIFICATION_MESSAGE(state, message) {
    state.notificationMessage = message;
  },
  SET_CURRENTLY_UPDATING(state, updating) {
    state.currentlyUpdaing = updating;
  }
}

const actions = {
  showModal({ commit }, context) {
    commit("SET_ACTIVE", true);
    commit("SET_MODAL_TITLE", context.modalTitle);
    commit("SET_MODAL_NAME", context.modalName);
  },
  closeModal({ commit }) {
    commit("SET_ACTIVE", false);
    commit("SET_MODAL_TITLE", "");
    commit("SET_MODAL_NAME", "");
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}