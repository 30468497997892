<template>
  <div class="container">
    <div class="canvas-image" v-show="dataClayImage[0] !== null">
      <canvas
        id="myCanvas"
        ref="upload-canvas"
        @mousedown.prevent="canvasClick"
        style="border:1px solid #d3d3d3; margin-bottom:-6px"
      ></canvas>
    </div>
    <div class="buttons">
      <div class="canvas-buttons">
        <button type="button" class="modified-button canvas-button" 
          v-for="(canvasButton, index) in canvasButtons" :key="index"
          @click="modifyCanvas(canvasButton)"
        >
          {{ canvasButton }}
        </button>
      </div>
      <hr />
      <div class="modal-buttons">
        <button type="button" class="modified-button" id="cancel-button" @click="closeModal">Cancel</button>
        <button type="button" class="modified-button" id="upload-button" @click="upload">Upload</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const aspectRatio = computed(() => store.getters["templates/getAspectRatio"]);
const dataClayImage = computed(() => store.state.dataClay.images);
const currentImages = computed(() => store.getters["wizard/getCurrentImages"]);
const currentImageIndex = computed(() => store.getters["wizard/getCurrentImageIndex"]);

const canvasButtons = ref([
  "+",
  "-",
  "Fit Vertical",
  "Fit Horizontal"
])
const canvasObj = ref({
  fullSizeCanvas: null,
  fullSizeCtx: null,
  canvas: null,
  ctx: null,
  isMouseDown: false,
  image: null,
  pointerXCoordinate: 0,
  pointerYCoordinate: 0,
  drawX: 0,
  drawY: 0,
  offsetX: 0,
  offsetY: 0,
  imageXCenter: 0.5,
  imageYCenter: 0.5,
  viewPortWidth: null,
  viewPortHeight: null,
  zoomBy: 0.05,
  screenAR: 1,
  aspectRatio: 1.78
})


// methods
const modifyCanvas = canvasButton => {
  if (canvasButton === "+") {
    zoomIn();
    return;
  }
  if (canvasButton === "-") {
    zoomOut();
    return;
  }
  if (canvasButton === "Fit Vertical") {
    fitVertical();
    return;
  }
  if (canvasButton === "Fit Horizontal") {
    fitHorizontal();
    return;
  }
}
const canvasClick = event => {
  canvasObj.value.isMouseDown = true;
  canvasObj.value.pointerXCoordinate = event.offsetX;
  canvasObj.value.pointerYCoordinate = event.offsetY;
  canvasObj.value.offsetX =
    event.offsetX / canvasObj.value.canvas.width -
    canvasObj.value.drawX / canvasObj.value.fullSizeCanvas.width;
  canvasObj.value.offsetY =
    event.offsetY / canvasObj.value.canvas.height -
    canvasObj.value.drawY / canvasObj.value.fullSizeCanvas.height;
}
const mouseUp = () => {
  canvasObj.value.isMouseDown = false;
}
const mouseMove = event => {
  if (canvasObj.value.isMouseDown) {
    canvasObj.value.pointerXCoordinate += event.movementX;
    canvasObj.value.pointerYCoordinate += event.movementY;
    let pointerX = canvasObj.value.pointerXCoordinate / canvasObj.value.canvas.width;
    let pointerY = canvasObj.value.pointerYCoordinate / canvasObj.value.canvas.height;

    canvasObj.value.drawX = (pointerX - canvasObj.value.offsetX) * canvasObj.value.fullSizeCanvas.width;
    canvasObj.value.drawY = (pointerY - canvasObj.value.offsetY) * canvasObj.value.fullSizeCanvas.height;

    canvasObj.value.imageXCenter =
      (canvasObj.value.drawX + canvasObj.value.image.width / 2) / canvasObj.value.fullSizeCanvas.width;
    canvasObj.value.imageYCenter =
      (canvasObj.value.drawY + canvasObj.value.image.height / 2) / canvasObj.value.fullSizeCanvas.height;

    canvasObj.value.fullSizeCtx.clearRect(
      0,
      0,
      canvasObj.value.fullSizeCanvas.width,
      canvasObj.value.fullSizeCanvas.height
    );
    canvasObj.value.fullSizeCtx.drawImage(
      canvasObj.value.image,
      canvasObj.value.drawX,
      canvasObj.value.drawY,
      canvasObj.value.image.width,
      canvasObj.value.image.height
    );

    canvasObj.value.ctx.clearRect(0, 0, canvasObj.value.canvas.width, canvasObj.value.canvas.height);
    canvasObj.value.ctx.drawImage(
      canvasObj.value.fullSizeCanvas,
      0,
      0,
      canvasObj.value.canvas.width,
      canvasObj.value.canvas.height
    );
  }
}
const zoomIn = () => {
  canvasObj.value.fullSizeCanvas.width -= canvasObj.value.zoomBy * canvasObj.value.fullSizeCanvas.width;
  canvasObj.value.fullSizeCanvas.height -= canvasObj.value.zoomBy * canvasObj.value.fullSizeCanvas.height;

  canvasObj.value.drawX =
    canvasObj.value.fullSizeCanvas.width * canvasObj.value.imageXCenter - canvasObj.value.image.width / 2;
  canvasObj.value.drawY =
    canvasObj.value.fullSizeCanvas.height * canvasObj.value.imageYCenter - canvasObj.value.image.height / 2;

  canvasObj.value.fullSizeCtx.clearRect(
    0,
    0,
    canvasObj.value.fullSizeCanvas.width,
    canvasObj.value.fullSizeCanvas.height
  );
  canvasObj.value.fullSizeCtx.drawImage(canvasObj.value.image, canvasObj.value.drawX, canvasObj.value.drawY);

  canvasObj.value.ctx.clearRect(0, 0, canvasObj.value.canvas.width, canvasObj.value.canvas.height);
  canvasObj.value.ctx.drawImage(
    canvasObj.value.fullSizeCanvas,
    0,
    0,
    canvasObj.value.canvas.width,
    canvasObj.value.canvas.height
  );
}
const zoomOut = () => {
  canvasObj.value.fullSizeCanvas.width += canvasObj.value.zoomBy * canvasObj.value.fullSizeCanvas.width;
  canvasObj.value.fullSizeCanvas.height += canvasObj.value.zoomBy * canvasObj.value.fullSizeCanvas.height;

  canvasObj.value.drawX =
    canvasObj.value.fullSizeCanvas.width * canvasObj.value.imageXCenter - canvasObj.value.image.width / 2;
  canvasObj.value.drawY =
    canvasObj.value.fullSizeCanvas.height * canvasObj.value.imageYCenter - canvasObj.value.image.height / 2;

  canvasObj.value.fullSizeCtx.clearRect(
    0,
    0,
    canvasObj.value.fullSizeCanvas.width,
    canvasObj.value.fullSizeCanvas.height
  );
  canvasObj.value.fullSizeCtx.drawImage(canvasObj.value.image, canvasObj.value.drawX, canvasObj.value.drawY);

  canvasObj.value.ctx.clearRect(0, 0, canvasObj.value.canvas.width, canvasObj.value.canvas.height);
  canvasObj.value.ctx.drawImage(
    canvasObj.value.fullSizeCanvas,
    0,
    0,
    canvasObj.value.canvas.width,
    canvasObj.value.canvas.height
  );
}
const fitVertical = () => {
  canvasObj.value.imageXCenter = 0.5;
  canvasObj.value.imageYCenter = 0.5;

  canvasObj.value.fullSizeCanvas.width = canvasObj.value.image.height * aspectRatio.value;
  canvasObj.value.fullSizeCanvas.height = canvasObj.value.image.height;

  canvasObj.value.drawX = canvasObj.value.fullSizeCanvas.width / 2 - canvasObj.value.image.width / 2;
  canvasObj.value.drawY = canvasObj.value.fullSizeCanvas.height / 2 - canvasObj.value.image.height / 2;

  canvasObj.value.fullSizeCtx.clearRect(
    0,
    0,
    canvasObj.value.fullSizeCanvas.width,
    canvasObj.value.fullSizeCanvas.height
  );
  canvasObj.value.fullSizeCtx.drawImage(
    canvasObj.value.image,
    canvasObj.value.drawX,
    canvasObj.value.drawY,
    canvasObj.value.image.width,
    canvasObj.value.image.height
  );

  canvasObj.value.ctx.clearRect(0, 0, canvasObj.value.canvas.width, canvasObj.value.canvas.height);
  canvasObj.value.ctx.drawImage(
    canvasObj.value.fullSizeCanvas,
    0,
    0,
    canvasObj.value.canvas.width,
    canvasObj.value.canvas.height
  );
}
const fitHorizontal = () => {
  canvasObj.value.imageXCenter = 0.5;
  canvasObj.value.imageYCenter = 0.5;

  canvasObj.value.fullSizeCanvas.width = canvasObj.value.image.width;
  canvasObj.value.fullSizeCanvas.height = canvasObj.value.image.width / aspectRatio.value;

  canvasObj.value.drawX = canvasObj.value.fullSizeCanvas.width / 2 - canvasObj.value.image.width / 2;
  canvasObj.value.drawY = canvasObj.value.fullSizeCanvas.height / 2 - canvasObj.value.image.height / 2;

  canvasObj.value.fullSizeCtx.clearRect(
    0,
    0,
    canvasObj.value.fullSizeCanvas.width,
    canvasObj.value.fullSizeCanvas.height
  );
  canvasObj.value.fullSizeCtx.drawImage(
    canvasObj.value.image,
    canvasObj.value.drawX,
    canvasObj.value.drawY,
    canvasObj.value.image.width,
    canvasObj.value.image.height
  );

  canvasObj.value.ctx.clearRect(0, 0, canvasObj.value.canvas.width, canvasObj.value.canvas.height);
  canvasObj.value.ctx.drawImage(
    canvasObj.value.fullSizeCanvas,
    0,
    0,
    canvasObj.value.canvas.width,
    canvasObj.value.canvas.height
  );
}
const setCanvasSize = () => {
  canvasObj.value.viewPortWidth = window.innerWidth;
  canvasObj.value.viewPortHeight = window.innerHeight;
  canvasObj.value.screenAR = canvasObj.value.viewPortWidth / canvasObj.value.viewPortHeight;
  if (canvasObj.value.screenAR < aspectRatio.value) {
    canvasObj.value.canvas.width = canvasObj.value.viewPortWidth * 0.65;
    canvasObj.value.canvas.height = canvasObj.value.canvas.width / aspectRatio.value;
  } else {
    canvasObj.value.canvas.height = canvasObj.value.viewPortHeight * 0.65;
    canvasObj.value.canvas.width = canvasObj.value.canvas.height * aspectRatio.value;
  }
}
const setImage = () => {
  canvasObj.value.imageXCenter = 0.5;
  canvasObj.value.imageYCenter = 0.5;

  canvasObj.value.fullSizeCanvas.width = canvasObj.value.image.width;
  canvasObj.value.fullSizeCanvas.height = canvasObj.value.image.width / aspectRatio.value;

  canvasObj.value.drawX = canvasObj.value.fullSizeCanvas.width / 2 - canvasObj.value.image.width / 2;
  canvasObj.value.drawY = canvasObj.value.fullSizeCanvas.height / 2 - canvasObj.value.image.height / 2;

  canvasObj.value.fullSizeCtx.clearRect(
    0,
    0,
    canvasObj.value.fullSizeCanvas.width,
    canvasObj.value.fullSizeCanvas.height
  );
  canvasObj.value.fullSizeCtx.drawImage(
    canvasObj.value.image,
    canvasObj.value.drawX,
    canvasObj.value.drawY,
    canvasObj.value.image.width,
    canvasObj.value.image.height
  );
  canvasObj.value.ctx.clearRect(0, 0, canvasObj.value.canvas.width, canvasObj.value.canvas.height);
  canvasObj.value.ctx.drawImage(
    canvasObj.value.fullSizeCanvas,
    0,
    0,
    canvasObj.value.canvas.width,
    canvasObj.value.canvas.height
  );
  if (parseInt(canvasObj.value.image.width) < parseInt(canvasObj.value.image.height)) {
    fitVertical();
  } else {
    fitHorizontal();
  }
}
const upload = () => {
  canvasObj.value.fullSizeCanvas.toBlob(blob => {
    createFile(blob);
  });
}
const closeModal = () => {
  store.dispatch("modal/closeModal");
}
const createFile = async blob => {
  let fileName = currentImages.value[currentImageIndex.value].name.replace(/[^A-Z0-9.]/gi, "");
  fileName = fileName.substr(0, fileName.lastIndexOf(".")) + ".png";
  let tempFile = new File([blob], fileName, { type: "image/png" });
  await store.dispatch("wizard/uploadImage", tempFile);
  store.commit("wizard/SET_CANVAS_IMAGE_UPLOADED", true);
  store.dispatch("modal/closeModal");
}


// vue
onMounted(() => {
  store.commit("wizard/SET_CANVAS_IMAGE_UPLOADED", false);
  canvasObj.value.canvas = document.getElementById("myCanvas");
  setCanvasSize();
  canvasObj.value.ctx = canvasObj.value.canvas.getContext("2d");

  canvasObj.value.fullSizeCanvas = document.createElement("canvas");
  canvasObj.value.fullSizeCtx = canvasObj.value.fullSizeCanvas.getContext("2d");

  canvasObj.value.image = document.createElement("img");
  canvasObj.value.image.crossOrigin = "anonymous";
  canvasObj.value.image.src = dataClayImage.value[parseInt(currentImageIndex.value)];
  setTimeout(() => {
    canvasObj.value.image.onload = setImage();
  }, 2000);
  window.addEventListener("mousemove", mouseMove);
  window.addEventListener("mouseup", mouseUp);
  window.addEventListener("resize", setCanvasSize);
})
</script>

<style scoped lang="scss">
.canvas-image {
  background-image: linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}
#myCanvas {
  width: 100%;
  height: 100%;
}
.canvas-button {
  border-radius: 0;
  padding: 1ex 1em;
  margin: 1ex;
}
.modal-buttons {
  margin: 1em;
  #upload-button {
    margin: 1ex;
    width: 30%;
    &:hover {
      background-color: var(--ce-submit-button-color);
    }
  }
  #cancel-button {
    margin: 1ex;
    width: 30%;
    &:hover {
      background-color: var(--ce-cancel-button-color);
    }
  }
}
</style>