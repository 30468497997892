<template>
  <div class="generate-key-container">
    <div class="key-input-group">
      <label>Duration Unit:</label>
      <input class="input-box-1 input" type="number" v-model="inputs.durationUnit" @change="setCurrentInputValue('durationUnit', $event)" />
    </div>
    <div class="key-input-group">
      <label>Duration Period:</label>
      <select class="input-box-1 select-box" v-model="inputs.durationPeriod" @change="setCurrentInputValue('durationPeriod', $event)">
        <option>Month</option>
        <option>Year</option>
      </select>
    </div>
    <div class="key-input-group">
      <label>Key Count:</label>
      <input class="input-box-1 input" type="number" v-model="inputs.keyCount" @change="setCurrentInputValue('keyCount', $event)" />
    </div>
    <div class="key-input-group">
      <label for="plans">Plan:</label>
      <select class="input-box-1 select-box" v-model="inputs.plan" @change="setCurrentInputValue('plan', $event)">
        <option v-for="(subscription, index) in allSubscriptions" :key="index">
          {{ subscription.name }}
        </option>
      </select>
    </div>
    <div class="key-input-group">
      <label>Email:</label>
      <input class="input-box-1 input" type="email" v-model="inputs.email" @change="setCurrentInputValue('email', $event)" />
    </div>
    <div class="key-input-buttons">
      <button class="modified-button" id="cancel-button" type="button" @click="emit('cancel')">
        Cancel
      </button>
      <button class="modified-button" id="submit-button" type="button"
        @click="submit">
        Submit
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, ref, computed } from 'vue';
import { useStore } from "vuex";

const store = useStore();
// emit
const emit = defineEmits(['cancel']);
// getters
const allSubscriptions = computed(() => store.getters["billing/allSubscriptions"]);
const currentInputs = computed(() => store.getters["checkError/getCurrentInputs"]);
const errorNumbers = computed(() => store.getters["checkError/getErrorNumber"]);

const inputs = ref({
  durationUnit: "",
  durationPeriod: "",
  keyCount: 0,
  plan: "",
  email: ""
})

// methods
const setCurrentInputValue = (key, e) => {
  let value = e.target.value;
  store.commit("checkError/SET_CURRENT_INPUT_VALUE", {
    key: key,
    value: value
  });
}
const submit = async () => {
  store.commit("checkError/SET_ERROR_NUMBER", 0);
  for (let i=0; i<Object.keys(currentInputs.value).length; i++) {
    let input = currentInputs.value[Object.keys(currentInputs.value)[i]];
    for (let j=0; j<input.checkErrors.length; j++) {
      let checkError = input.checkErrors[j];
      store.dispatch(`checkError/check${checkError}`, {
        ...currentInputs.value[Object.keys(currentInputs.value)[i]]
      });
    }
    if (errorNumbers.value > 0) {
      break;
    }
  }
  if (errorNumbers.value === 0) {
    await store.dispatch("adminUsers/generateKey", {
      keyCount: inputs.value.keyCount,
      durationPeriod: inputs.value.durationPeriod,
      durationUnit: inputs.value.durationUnit,
      plan: inputs.value.plan,
      email: inputs.value.email
    });
    inputs.value = {
      durationUnit: "",
      durationPeriod: "",
      keyCount: 0,
      plan: "",
      email: ""
    }
  }
}


if (allSubscriptions.value.length === 0) {
  store.dispatch("billing/getAllSubscriptions");
}

store.commit("checkError/SET_CURRENT_INPUTS", {
  durationUnit: {
    input: "",
    inputName: "Duration Unit",
    checkErrors: ["CannotBeEmpty"]
  },
  durationPeriod: {
    input: "",
    inputName: "Duration Period",
    checkErrors: ["CannotBeEmpty"]
  },
  keyCount: {
    input: "",
    inputName: "Key Count",
    checkErrors: ["CannotBeEmpty"]
  },
  plan: {
    input: "",
    inputName: "Plan",
    checkErrors: ["CannotBeEmpty"]
  },
  email: {
    input: "",
    inputName: "Email",
    checkErrors: ["CannotBeEmpty"]
  }
})

</script>

<style scoped lang="scss">
.input-box-1 {
  width: 50%;
}
.generate-key-container {
  .key-input-group {
    width: 50%;
    text-align: left;
    display: flex;
    justify-content: center;
    margin: auto;
    align-items: center;
    label {
      width: 20%;
    }
  }
}
.key-input-buttons {
  display: flex;
  justify-content: center;
  margin-top: 2em;
  .modified-button {
    width: 30%;
    margin: 1em;
  }
}
.select-box {
  background-color: white;
}
#submit-button {
  &:hover {
    background-color: var(--ce-submit-button-color);
  }
}
#cancel-button {
  &:hover {
    background-color: var(--ce-cancel-button-color);
  }
}
</style>