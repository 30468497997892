<template>
  <div class="container-fluid">
    <div class="buttons">
      <button type="button" class="btn btn-outline-success" @click="createNewTemplate">New Templates</button>
      <button type="button" class="btn btn-outline-success" @click="router.push('/admin/managetemplate')">Manage Free Templates</button>
    </div>
    <div class="filter-bar">
      <button v-for="(filter, index) in tableFilter" :key="index" 
        :class="[stateFilterStatus.includes(filter) ? 'btn btn-secondary btn-sm' : 'btn btn-outline-secondary btn-sm']"
        type="button" @click="toggleFilter(filter)"
      >
        {{ filter }}
      </button>
    </div>
    <table class="table table-hover table-striped table-bordered">
      <thead>
        <tr>
          <th scope="col" v-for="(head, index) in tableHead" :key="index">
            {{ head }}
            <span v-if="head !== 'Action'" id="sorting-icon"  @click="sort(head)">
              <icon v-if="sorting.name === head && sorting.sort === 'up'" class="icon" icon="sort-up" />
              <icon v-else-if="sorting.name === head && sorting.sort === 'down'" class="icon" icon="sort-down" />
              <icon v-else class="icon" icon="sort" />
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data, index) in sortedTemplates" :key="index">
          <td v-for="(head, index_y) in tableHead" :key="index_y">
            <span v-if="head === 'Action'" class="action-column">
              <icon class="icon" icon="pen" v-if="data['status'] === 'Testing' || data['status'] === 'Published'" @click="updateTemplate(data)"/>
              <icon class="icon" icon="ellipsis-v" @click="updateTemplateStatus(data)" />
              <icon class="icon" icon="hat-wizard" @click="startWizard(data)" />
            </span>
            <span v-else>{{ data[head.toLowerCase()] }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="empty" v-if="templates.length === 0">Empty</div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const router = useRouter();
const store = useStore();
const tableHead = ref(["ID", "Name", "Status", "Action"]);
const tableFilter = ref(["Testing", "Published", "Retired", "Deleted", "Convention"]);
const sorting = ref({
  name: "",
  sort: ""
});

// states
const stateTemplates = computed(() => store.state.adminTemplates.templates);
const stateFilterStatus = computed(() => store.state.adminTemplates.filterStatus);

// computed
const templates = computed(() => 
  store.getters["adminTemplates/getTemplatesItemsOrganized"]
);
const sortedTemplates = computed(() => {
  let temp = [...templates.value];
  if (sorting.value.name !== "") {
    temp.sort((a,b) => {
      if (sorting.value.sort === "up") {
        if (sorting.value.name.toLowerCase() === "id") {
          return a[sorting.value.name.toLowerCase()] - b[sorting.value.name.toLowerCase()];
        }
        return a[sorting.value.name.toLowerCase()].toLowerCase() > b[sorting.value.name.toLowerCase()].toLowerCase() ? 1 : -1;
      }
      if (sorting.value.name.toLowerCase() === "id") {
        return b[sorting.value.name.toLowerCase()] - a[sorting.value.name.toLowerCase()];
      }
      return b[sorting.value.name.toLowerCase()].toLowerCase() > a[sorting.value.name.toLowerCase()].toLowerCase() ? 1 : -1;
    })
  }
  return temp;
})
// methods 
const toggleFilter = filter => {
  store.commit("adminTemplates/TOGGLE_FILTER", filter);
}
const createNewTemplate = () => {
  store.commit("adminTemplates/SET_SELECTED_TEMPLATE", {});
  store.commit("adminTemplates/SET_CURRENT_PROCESS", "new");
  store.commit("adminTemplates/RESET_NEW_UPDATE_TEMPLATE");
  store.dispatch("adminTemplates/generateNewTemplateS3Id");
  router.push("/admin/updatetemplate");
}
const updateTemplate = async data => {
  let fileInS3 = {
    parent: "templates",
    s3Id: data.s3_id + "/thumbnails"
  }
  await store.dispatch("adminTemplates/getFilesFromS3", fileInS3);
  store.commit("adminTemplates/SET_SELECTED_TEMPLATE", data);
  store.commit("adminTemplates/SET_NEW_UPDATE_TEMPLATE", data);
  store.commit("adminTemplates/SET_CURRENT_PROCESS", "update");
  router.push('/admin/updatetemplate');
}
const updateTemplateStatus = (template) => {
  store.commit("adminTemplates/SET_SELECTED_TEMPLATE", template);
  store.commit("modal/SET_CURRENTLY_UPDATING", 'template');
  store.dispatch("modal/showModal", {
    modalTitle: "Update Template Status",
    modalName: "UpdateStatus"
  })
}
const startWizard = async (template) => {
  store.commit("templates/SET_CURRENT_VIDEO", template);
  store.commit("dataClay/SET_INITIAL_DATACLAY", { 
    user: store.state.user.currentUser,
    template: template
  });
  router.push("/wizard/fileStep");
}
const sort = head => {
  if (sorting.value.name === "" || sorting.value.name !== head) {
    sorting.value = {
      name: head,
      sort: "up"
    }
    return;
  }
  if (sorting.value.sort === "up") {
    sorting.value.sort = "down";
    return;
  }
  sorting.value = {
    name: "",
    sort: ""
  };
}


// created
if (stateTemplates.value.length === 0) {
  store.dispatch("adminTemplates/getTemplates");
}
store.commit("adminPopularSeasonalVideos/SET_CURRENT_PAGE", "free");

</script>

<style scoped>
.icon {
  cursor: pointer;
}
input {
  margin-right: 1ex;
}
.filter-selected {
  border: none;

}
.buttons {
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
}
.filter-bar {
  float: right;
  margin-bottom: 1em;
}
/* .filter-group:not(:last-child) {
  margin-right: 1em;
} */
.action-column {
  display: flex;
  justify-content: space-evenly;
}
#sorting-icon {
  float: right
}

</style>