<template>
  <section class="container-fluid">
    <div class="row">
      <StepHomeComponent v-for="step in steps" :key="step.id" :step="step" />
    </div>
  </section>
</template>

<script setup>
import StepHomeComponent from "@/components/Home/StepHomeComponent.vue";
import templateIcon from "../../assets/Home/template-black.png";
import customizeIcon from "../../assets/Home/customize-black.png";
import downloadIcon from "../../assets/Home/download-black.png";
import { ref } from "vue";
const steps = ref([
  {
    id: 1,
    title: "Choose template",
    subtext: "Search through our growing library",
    icon: templateIcon,
    backgroundColor: "rgb(240, 240, 240)"
  },
  {
    id: 2,
    title: "Customize",
    subtext: "Choose your message, image, and colors",
    icon: customizeIcon,
    backgroundColor: "rgb(220, 220, 220)"
  },
  {
    id: 3,
    title: "Get your video",
    subtext: "We'll email your video once it's ready",
    icon: downloadIcon,
    backgroundColor: "rgb(200, 200, 200)"
  }
])
  
</script>

<style scoped>
.container-fluid {
  margin: 0;
  padding: 0;
}
section {
  margin: 0 auto;
}
.row {
  margin: 0;
}
</style>
