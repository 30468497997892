<template>
  <div class="move-file-container">
    <div class="container-header">
      <span class="path-folder" v-for="(folder, index) in formatCurrentPath.path.split('/').slice(0,-1)" :key="index">
        <button
          class="path-folder-button"
          @click="gotoFolder({
            path: currentPathForMovingObjects.split('/').slice(0,index).length === 0 ? 
              '' : currentPathForMovingObjects.split('/').slice(0,index).join('/')+'/', 
            name: folder === 'Home' ? rootPath : folder+'/'
          })"
        > 
          {{ folder }}
        </button>
      </span>
    </div>
    <div class="container-body">
      <span class="body-moving-folder"
        :class="{'disable-pointer': selectedItems[folder.name]}" v-for="(folder, index) in folderItemsForMovingObjects" :key="index"
      >
        <img class="body-moving-folder-image" :src="FolderImage" alt="" 
          @click="gotoFolder(folder)" />
        <span v-if="folder.name.length <= 13">{{ folder.name }}</span>
        <span v-else>{{ folder.name.slice(0, 10) }}...</span>
      </span>
    </div>
    <div class="container-footer">
      <button type="button" class="modified-button container-footer-button" 
        :class="{ 'disable-pointer': currentPath === currentPathForMovingObjects }" 
        id="container-footer-button-move"
        @click="startMoving"
      >
        Move Here
      </button>
      <button type="button" class="modified-button container-footer-button" 
        id="container-footer-button-cancel" @click="cancelMovingOjects">
        Cancel
      </button>
    </div>
  </div>
</template>

<script setup>
import FolderImage from "@/assets/folder.png";
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

// states
const currentPathForMovingObjects = computed(() => store.state.customerPortal.currentPathForMovingObjects);
const selectedItems = computed(() => store.state.customerPortal.selectedItems);
const folderItemsForMovingObjects = computed(() => store.state.customerPortal.folderItemsForMovingObjects);
const currentPath = computed(() => store.state.customerPortal.currentPath);
const rootPath = computed(() => store.state.customerPortal.rootPath)

// methods 
const cancelMovingOjects = () => {
  store.dispatch("customerPortal/unselectAndCancel");
}
const gotoFolder = async folder => {
  await store.dispatch("customerPortal/getFolderItemsForMovingObjects", {folderName: `${folder.path}${folder.name}`, purpose: "MOVE_FROM"});
}
const startMoving = () => {
  store.dispatch("customerPortal/moveItems");
}

// computed
const formatCurrentPath = computed(() => {
  let path = currentPathForMovingObjects.value.split("/").slice(0, -1);
  path[0] = "Home";
  let newPath = {
    path: path.join("/")+"/",
    name: path.at(-1)+"/"
  }
  return newPath;
})

// created
store.commit("customerPortal/SET_CURRENT_PATH_FOR_MOVING_OBJECTS", currentPath.value);
store.dispatch("customerPortal/getFolderItemsForMovingObjects", {purpose: "MOVE_FROM"});
</script>

<style scoped lang="scss">
.container-header {
  border-bottom: 1px solid grey;
  display: flex;
  align-items: center;
  margin-left: 2ex;
}
.move-file-container {
  background: white;
  border-radius: 5%;
  position: absolute;
  z-index: 1;
  left: 25%;
  top: 25%;
  width: 1000px;
  height: 500px;
  border: 1px solid black;
  display: grid;
  grid-template-rows: 1fr 8fr 1fr;
}
.path-folder {
  background-color: transparent;
  &:after {
    content: ">";
  }
}
.path-folder-button {
  font-size: 1em;
  border: none;
  background-color: white;
}
.container-body {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  padding: 1em;
  height: fit-content;
}
.disable-pointer {
  opacity: 0.3;
  pointer-events: none;
}
.body-moving-folder {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.body-moving-folder-image {
  width: 45px;
  height: auto;
  margin: 0 2em;
}
.container-footer {
  border-top: 1px solid grey;
  margin-right: 2em;
}
.container-footer-button {
  float: right;
  margin: 1ex 0 1ex 1ex;
  border-radius: 50px;
  width: 40%;
}
#container-footer-button-move {
  &:hover {
    background-color: var(--ce-add-button-color);
  }

}
#container-footer-button-cancel {
  &:hover {
    background-color: var(--ce-cancel-button-color);
  }
}
</style>