import axios from "axios";

const state = {
  popularVideos: [],
  seasonalVideos: [],
  popularVideoIds: [],
  seasonalVideoIds: [],
  currentPage: "",
  selectingTemplates: false,
  newlySelectedTemplateIds: []
}

const getters = {
  getPopularVideos: state => {
    return state.popularVideos;
  },
  getSeasonalVideos: state => {
    return state.seasonalVideos;
  },
  getPopularVideoIds: state => {
    return state.popularVideoIds;
  },
  getSeasonalVideoIds: state => {
    return state.seasonalVideoIds;
  },
  getCurrentPage: state => {
    return state.currentPage;
  },
  getSelectingTemplates: state => {
    return state.selectingTemplates;
  },
  getNewlySelectedTemplateIds: state => {
    return state.newlySelectedTemplateIds;
  }
}

const mutations = {
  SET_POPULAR_VIDEOS(state, videos) {
    state.popularVideos = videos;
  },
  SET_SEASONAL_VIDEOS(state, videos) {
    state.seasonalVideos = videos;
  },
  SET_POPULAR_VIDEO_IDS(state, popularVideoIds) {
    state.popularVideoIds = popularVideoIds;
  },
  SET_SEASONAL_VIDEO_IDS(state, seasonalVideoIds) {
    state.seasonalVideoIds = seasonalVideoIds;
  },
  SET_CURRENT_PAGE(state, currentPage) {
    state.currentPage = currentPage;
  },
  SET_SELECTING_TEMPLATES(state, selectingTemplates) {
    state.selectingTemplates = selectingTemplates;
  },
  SET_NEWLY_SELECTED_TEMPLATE_IDS(state, newlySelectedTemplateIds) {
    state.newlySelectedTemplateIds = newlySelectedTemplateIds;
  }
}

const actions = {
  async fetchPopularVideos({ commit, dispatch }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      let response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/admin-homepage/get-popular-templates`, {
        withCredentials: true
      });
      let popularVideoIds = [];
      response.data.forEach(template => {
        popularVideoIds.push(template.id);
      })
      commit("SET_POPULAR_VIDEO_IDS", popularVideoIds);
      commit("SET_POPULAR_VIDEOS", response.data);
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async fetchSeasonalVideos({ commit, dispatch }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      let response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/admin-homepage/get-seasonal-templates`, {
        withCredentials: true
      });
      let seasonalVideoIds = [];
      response.data.forEach(template => {
        seasonalVideoIds.push(template.id);
      })
      commit("SET_SEASONAL_VIDEO_IDS", seasonalVideoIds);
      commit("SET_SEASONAL_VIDEOS", response.data);
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async setTemplatesToPopular({ commit, dispatch }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.post(
        `${process.env.VUE_APP_SERVER_URL}/api/admin-homepage/add-popular-templates`, {
          selectedPopularTemplates: state.newlySelectedTemplateIds
        }, {
          withCredentials: true
        }
      );
      await dispatch("fetchPopularVideos");
      commit("SET_SELECTING_TEMPLATES", false);
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully updated popular templates."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async setTemplatesToSeasonal({ commit, dispatch }) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.post(
        `${process.env.VUE_APP_SERVER_URL}/api/admin-homepage/add-seasonal-templates`, {
          selectedSeasonalTemplates: state.newlySelectedTemplateIds
        }, {
          withCredentials: true
        }
      );
      await dispatch("fetchSeasonalVideos");
      commit("SET_SELECTING_TEMPLATES", false);
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully updated seasonal templates."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Something went wrong. Please try again later."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}