<template>
  <div id="media-container">
    <MediaGrid :videos="popularVideos" :type="'popular'"/>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import MediaGrid from "@/components/MediaGrid.vue";

const store = useStore();

const popularVideos = computed(() => store.getters["adminPopularSeasonalVideos/getPopularVideos"]);

if (popularVideos.value.length === 0) {
  store.dispatch("adminPopularSeasonalVideos/fetchPopularVideos");
}

</script>

<style scoped>
</style>
