<template>
  <div class="container-fluid">
    <div class="buttons">
      <button type="button" class="btn btn-outline-success" @click="createNewStock">New Stock</button>
    </div>
    <div class="filter-bar">
      <button v-for="(filter, index) in tableFilter" :key="index" 
        :class="[stateFilterStatus.includes(filter) ? 'btn btn-secondary btn-sm' : 'btn btn-outline-secondary btn-sm']"
        type="button" @click="toggleFilter(filter)"
      >
        {{ filter }}
      </button>
    </div>
    <table class="table table-hover table-striped table-bordered">
      <thead>
        <tr>
          <th scope="col" v-for="(head, index) in tableHead" :key="index">
            {{ head }}
            <span v-if="head !== 'Action'" id="sorting-icon"  @click="sort(head)">
              <icon v-if="sorting.name === head && sorting.sort === 'up'" class="icon" icon="sort-up" />
              <icon v-else-if="sorting.name === head && sorting.sort === 'down'" class="icon" icon="sort-down" />
              <icon v-else class="icon" icon="sort" />
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data, index) in sortedStocks" :key="index">
          <td v-for="(head, index_y) in tableHead" :key="index_y">
            <span v-if="head === 'Action'" class="action-column">
              <icon class="icon" icon="pen" @click="updateStock(data)" />
              <icon class="icon" icon="ellipsis-v" @click="updateStockStatus(data)"/>
            </span>
            <span v-else>{{ data[head.toLowerCase()] }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="empty" v-if="getterFilterStocks.length === 0">Empty</div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
const tableHead = ref(["ID", "Name", "Renders", "Status", "Action"]);
const tableFilter = ref(["Testing", "Published"]);
const sorting = ref({
  name: "",
  sort: ""
});

const store = useStore();
const router = useRouter();

// states
const stateStocks = computed(() => store.state.adminStocks.stocks);
const stateFilterStatus = computed(() => store.state.adminStocks.filterStatus);

// getters
const getterFilterStocks = computed(() => store.getters["adminStocks/filterStocks"])

// computed
const sortedStocks = computed(() => {
  let temp = [...getterFilterStocks.value];
  if (sorting.value.name !== "") {
    temp.sort((a,b) => {
      if (sorting.value.sort === "up") {
        if (sorting.value.name.toLowerCase() === "id") {
          return a[sorting.value.name.toLowerCase()] - b[sorting.value.name.toLowerCase()];
        }
        return a[sorting.value.name.toLowerCase()].toLowerCase() > b[sorting.value.name.toLowerCase()].toLowerCase() ? 1 : -1;
      }
      if (sorting.value.name.toLowerCase() === "id") {
        return b[sorting.value.name.toLowerCase()] - a[sorting.value.name.toLowerCase()];
      }
      return b[sorting.value.name.toLowerCase()].toLowerCase() > a[sorting.value.name.toLowerCase()].toLowerCase() ? 1 : -1;
    })
  }
  return temp;
})

// methods
const createNewStock = async () => {
  store.commit("adminStocks/SET_CURRENT_PROCESS", "new");
  store.commit("adminStocks/RESET_SELECTED_STOCK");
  await store.dispatch("adminStocks/generateNewStockS3Id");
  router.push("/admin/managestock");
}
const toggleFilter = filter => {
  store.commit("adminStocks/TOGGLE_FILTER", filter);
}
const updateStock = data => {
  store.commit("adminStocks/SET_CURRENT_PROCESS", "update");
  store.commit("adminStocks/SET_SELECTED_STOCK", data);
  router.push("/admin/managestock");
}
const updateStockStatus = data => {
  store.commit("adminStocks/SET_SELECTED_STOCK", data);
  store.commit("modal/SET_CURRENTLY_UPDATING", 'stock');
  store.dispatch("modal/showModal", {
    modalTitle: "Update Stock Status",
    modalName: "UpdateStatus"
  })
}
const sort = head => {
  if (sorting.value.name === "" || sorting.value.name !== head) {
    sorting.value = {
      name: head,
      sort: "up"
    }
    return;
  }
  if (sorting.value.sort === "up") {
    sorting.value.sort = "down";
    return;
  }
  sorting.value = {
    name: "",
    sort: ""
  };
}

// created
if (stateStocks.value.length === 0) {
  store.dispatch("adminStocks/getStocks");
}

</script>

<style scoped>
.icon {
  cursor: pointer;
}
input {
  margin-right: 1ex;
}
.buttons {
  float: left;
  margin-bottom: 1em;
}
.filter-bar {
  float: right;
  margin-bottom: 1em;
}
.filter-group:not(:last-child) {
  margin-right: 1em;
}
.action-column {
  display: flex;
  justify-content: space-evenly;
}
#sorting-icon {
  float: right
}
</style>