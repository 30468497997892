<template>
  <div class="container-fluid">
    <form @submit.prevent="authenticate" class="authenticate-form">
      <div class="mfa-form" v-if="needMagicinfoMFA">
        <div class="header">
          <p>Enter 6-digit code from your two factor authenticator App.</p>
        </div>
        <div class="mfa-number">
          <input v-for="input in 6" :key="input" :class="`mfa-input input-${input}`" type="text" 
            maxlength="1" @keydown="gotoNextInput($event, input)" v-model="inputs.mfaCode[input-1]"/>
        </div>
      </div>
      <div class="login-form" v-else>
        <div class="input-element">
          <label>MagicINFO Server*</label>
          <input type="text" class="input-box-1" v-model="inputs.magicinfoServer" placeholder="MagicINFO Server..." @change="setCurrentInputValue('magicinfoServer', $event)" />
        </div>
        <div class="input-element">
          <label>MagicINFO Username</label>
          <input type="text" class="input-box-1" v-model="inputs.magicinfoUsername" placeholder="MagicINFO Username..." @change="setCurrentInputValue('magicinfoUsername', $event)" />
        </div>
        <div class="input-element">
          <label>MagicINFO Password</label>
          <input type="password" class="input-box-1" v-model="inputs.magicinfoPassword" placeholder="MagicINFO Password..." @change="setCurrentInputValue('magicinfoPassword', $event)" />
        </div>
      </div>
      <div class="buttons">
        <button type="button" class="modified-button" id="cancel-button" @click="closeModal">Go Back</button>
        <button type="submit" class="modified-button" id="authenticate-button">Authenticate</button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { decryptMagicinfoPassword } from "@/utils/magicinfoEncrypt";

const store = useStore();

// getters
const needMagicinfoMFA = computed(() => store.getters["magicinfo/getNeedMagicinfoMFA"]);
const isMagicinfoAuthenticated = computed(() => store.getters["magicinfo/isAuthenticated"]);
const currentInputs = computed(() => store.getters["checkError/getCurrentInputs"]);
const errorNumbers = computed(() => store.getters["checkError/getErrorNumber"]);
const tempCredentials = computed(() => store.getters["magicinfo/getTempCredentials"]);
const dataClay = computed(() => store.getters["dataClay/getDataClay"]);

const inputs = ref({
  magicinfoServer: "",
  magicinfoUsername: "",
  magicinfoPassword: "",
  mfaCode: ["", "", "", "", "", ""]
})

// methods
const setCurrentInputValue = (key, e) => {
  let value = e.target.value;
  store.commit("checkError/SET_CURRENT_INPUT_VALUE", {
    key: key,
    value: value
  });
}
const authenticate = async () => {
  store.commit("checkError/SET_ERROR_NUMBER", 0);
  for (let i=0; i<Object.keys(currentInputs.value).length; i++) {
    let input = currentInputs.value[Object.keys(currentInputs.value)[i]];
    for (let j=0; j<input.checkErrors.length; j++) {
      let checkError = input.checkErrors[j];
      store.dispatch(`checkError/check${checkError}`, {
        ...currentInputs.value[Object.keys(currentInputs.value)[i]]
      });
    }
    if (errorNumbers.value > 0) {
      break;
    }
  }
  if (errorNumbers.value === 0) {
    let data = {
      server: inputs.value.magicinfoServer,
      username: inputs.value.magicinfoUsername,
      password: inputs.value.magicinfoPassword,
      mfaCode: needMagicinfoMFA.value ? inputs.value.mfaCode.join("") : null
    }
    if (tempCredentials.value) {
      data = {
        server: tempCredentials.value.server,
        username: tempCredentials.value.username,
        password: decryptMagicinfoPassword(tempCredentials.value.password),
        mfaCode: needMagicinfoMFA.value ? inputs.value.mfaCode.join("") : null
      }
    }
    await store.dispatch("magicinfo/authenticateMagicinfo", data);
    if (isMagicinfoAuthenticated.value) {
      closeModal();
    }
  }
}
// const authenticate = async () => {
//   store.commit("checkError/SET_ERROR_NUMBER", 0);
//   for (let i=0; i<Object.keys(currentInputs.value).length; i++) {
//     let input = currentInputs.value[Object.keys(currentInputs.value)[i]];
//     for (let j=0; j<input.checkErrors.length; j++) {
//       let checkError = input.checkErrors[j];
//       store.dispatch(`checkError/check${checkError}`, {
//         ...currentInputs.value[Object.keys(currentInputs.value)[i]]
//       });
//     }
//     if (errorNumbers.value > 0) {
//       break;
//     }
//   }
//   if (errorNumbers.value === 0) {
//     let data = {
//       server: inputs.value.magicinfoServer,
//       username: inputs.value.magicinfoUsername,
//       password: inputs.value.magicinfoPassword,
//       mfaCode: needMagicinfoMFA.value ? inputs.value.mfaCode.join("") : null
//     }
//     if (tempCredentials.value) {
//       data = {
//         server: tempCredentials.value.magicinfoServer,
//         username: tempCredentials.value.magicinfoUsername,
//         password: decryptMagicinfoPassword(tempCredentials.value.magicinfoPassword),
//         mfaCode: needMagicinfoMFA.value ? inputs.value.mfaCode.join("") : null
//       }
//     }
//     console.log(data);
//     await store.dispatch("magicinfo/authenticateMagicinfo", data);
//     if (isMagicinfoAuthenticated.value) {
//       closeModal();
//     }
//   }
// }
const gotoNextInput = (event, index) => {
  if (event.key === "Backspace") {
    if (index !== 1) {
      inputs.value.mfaCode[index - 1] = "";
      event.target.previousElementSibling.focus();
    }
    return;
  }
  if (inputs.value.mfaCode[index - 1] !== "") {
    return;
  }
  if (event.key.length === 1 && event.key !== " ") {
    inputs.value.mfaCode[index - 1] = event.key;
  }
  if (index < 6) {
    setTimeout(() => {
      event.target.nextElementSibling.focus();
    }, 3);
  }
}
const closeModal = () => {
  store.commit("checkError/SET_CURRENT_INPUTS", {
    projectName: {
      input: dataClay.value.projectName,
      inputName: "Project Name",
      checkErrors: ["CannotBeEmpty"]
    },
    fileFormat: {
      input: dataClay.value.fileFormat,
      inputName: "File Format",
      checkErrors: ["CannotBeEmpty"]
    },
    matrix: {
      input: [],
      inputName: "Displays",
      checkErrors: ["CannotBeEmpty"]
    }
  })
  store.commit("magicinfo/SET_NEED_MAGICINFO_MFA", false);
  store.dispatch("modal/closeModal");
}

store.commit("checkError/SET_CURRENT_INPUTS", {
  magicinfoServer: {
    input: tempCredentials.value ? tempCredentials.value.server : "",
    inputName: "Magicinfo Server",
    checkErrors: ["CannotBeEmpty"]
  },
  magicinfoUsername: {
    input: tempCredentials.value ? tempCredentials.value.username : "",
    inputName: "Magicinfo ID",
    checkErrors: ["CannotBeEmpty"]
  },
  magicinfoPassword: {
    input: tempCredentials.value ? tempCredentials.value.password : "",
    inputName: "Magicinfo Password",
    checkErrors: ["CannotBeEmpty"]
  }
})
</script>

<style scoped lang="scss">
.mfa-number {
  width: 80%;
  margin: auto;
}
.mfa-input {
  width: 50px;
  height: 50px;
  margin: 1ex;
  text-align: center;
  font-size: 20px;
}
.input-element {
  text-align: left;
}
.buttons {
  .modified-button {
    width: 40%;
    margin: 1em;
  }
}
#cancel-button {
  &:hover {
    background-color: var(--ce-cancel-button-color);
  }
}
#authenticate-button {
  &:hover {
    background-color: var(--ce-submit-button-color);
  }
}
</style>