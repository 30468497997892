import axios from "axios";
import {
  decryptMagicinfoServer,
  decryptMagicinfoToken,
  encryptMagicinfoServer,
  encryptMagicinfoToken,
  encryptMagicinfoPassword,
  decryptMagicinfoPassword
} from "@/utils/magicinfoEncrypt";
import router from '@/router'

const FormData = require("form-data");
const timer = 8 * 60 * 1000;

const state = {
  isAuthenticated: false,
  isAutologin: false,
  needMagicinfoMFA: false,
  magicinfoServer: null,
  magicinfoToken: "",
  magicinfoRefreshToken: "",
  magicinfoUsername: null,
  magicinfoAutoLogin: false,
  magicinfoTokenTimeInterval: null,
  magicinfoOrganizationId: null,
  allMagicinfoDisplays: [],
  magicinfoContentType: null,
  magicinfoContentUrl: null,
  tempCredentials: null,
  autologining: false
}
// [SET_MAGICINFO_AUTO_LOGIN](state, payload) {
//   state.magicinfoAutoLogin = payload;
// },
// [SET_ALL_DISPLAYS](state, payload) {
//   state.allMagicinfoDisplays = payload;
// },
const getters = {
  getMagicinfoInfo: state => {
    return {
      "server": state.magicinfoServer,
      "username": state.magicinfoUsername,
      "organization": state.magicinfoOrganizationId,
      "autologin": state.isAutologin
    }
  },
  getDataclayMagicinfo: state => {
    return {
      magicinfoToken: state.magicinfoToken,
      magicinfoServer: state.magicinfoServer,
      magicinfoUsername: state.magicinfoUsername,
      magicinfoOrganizationId: state.magicinfoOrganizationId
    }
  },
  isAuthenticated: state => {
    return state.isAuthenticated;
  },
  getMagicinfoServer: state => {
    return state.magicinfoServer;
  },
  getMagicinfoUsername: state => {
    return state.magicinfoUsername;
  },
  getMagicinfoOrganizationId: state => {
    return state.magicinfoOrganizationId;
  },
  getNeedMagicinfoMFA: state => {
    return state.needMagicinfoMFA;
  },
  getAutologin: state => {
    return state.isAutologin;
  },
  getTempCredentials: state => {
    return state.tempCredentials;
  },
  getAutologining: state => {
    return state.autologining;
  }
}

const mutations = {
  SET_AUTHENTICATED(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },
  SET_MAGICINFO_SERVER(state, server) {
    state.magicinfoServer = server;
  },
  SET_MAGICINFO_USERNAME(state, username) {
    state.magicinfoUsername = username;
  },
  SET_MAGICINFO_TOKEN(state, token) {
    state.magicinfoToken = token;
  },
  SET_MAGICINFO_REFRESH_TOKEN(state, refreshToken) {
    state.magicinfoRefreshToken = refreshToken;
  },
  SET_ORGANIZATION_ID(state, organization_id) {
    state.magicinfoOrganizationId = organization_id;
  },
  SET_NEED_MAGICINFO_MFA(state, needMagicinfoMFA) {
    state.needMagicinfoMFA = needMagicinfoMFA;
  },
  SET_MAGICINFO_TOKEN_TIME_INTERVAL(state, timeInterval) {
    state.magicinfoTokenTimeInterval = timeInterval;
  },
  SET_CONTENTTYPE(state, contentType) {
    state.magicinfoContentType = contentType;
  },
  SET_MAGICINFO_CONTENT_URL(state, contentUrl) {
    state.magicinfoContentUrl = contentUrl;
  },
  SET_AUTOLOGIN(state, autologin) {
    state.isAutologin = autologin;
  },
  SET_TEMP_CREDENTIALS(state, credentials) {
    state.tempCredentials = credentials;
  },
  SET_AUTOLOGINING(state, autologining) {
    state.autologining = autologining;
  }
}

const actions = {
  async autoLogin({ commit, dispatch }, context) {
    try {
      let username = context.username;
      let response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/magicinfo/get-magicinfo-credentials`, {
        params: {
          username: username
        },
        withCredentials: true
      })
      let tempCredentials = {
        server: response.data[0].magicinfo_server,
        username: response.data[0].magicinfo_username,
        password: decryptMagicinfoPassword(response.data[0].magicinfo_password)
      }
      commit("SET_TEMP_CREDENTIALS", {
        server: response.data[0].magicinfo_server,
        username: response.data[0].magicinfo_username,
        password: response.data[0].magicinfo_password
      });
      await dispatch("authenticateMagicinfo", tempCredentials);
    } catch (error) {
      console.log(error);
    }
  },

  async authenticateMagicinfo({ commit, dispatch, rootState }, data) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      const userInfo = {
        username: data.username,
        password: data.password,
        totp: data.mfaCode
      };
      const response = await axios.post(
        `${data.server}/restapi/v2.0/auth`,
        userInfo,
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      const token = response.data.token;
      const refreshToken = response.data.refreshToken;
      const encryptedToken = encryptMagicinfoToken(token);
      const encryptedRefreshToken = encryptMagicinfoToken(refreshToken);
      const encryptedServer = encryptMagicinfoServer(data.server);
      const encryptedPassword = encryptMagicinfoPassword(data.password);
      window.$cookies.set("magicinfoToken", encryptedToken);
      window.$cookies.set("magicinfoRefreshToken", encryptedRefreshToken);
      window.$cookies.set("magicinfoServer", encryptedServer);
      commit("SET_MAGICINFO_SERVER", encryptedServer);
      commit("SET_MAGICINFO_TOKEN", encryptedToken);
      commit("SET_MAGICINFO_REFRESH_TOKEN", encryptedRefreshToken);
      commit("SET_MAGICINFO_USERNAME", data.username);
      commit("SET_AUTHENTICATED", true);
      const credentials = {
        server: encryptedServer,
        username: data.username,
        password: encryptedPassword
      };
      if (state.autologining) {
        credentials["ceUsername"] = rootState.user.credentials.username
      }
      await axios.put(`${process.env.VUE_APP_SERVER_URL}/api/magicinfo/update-magicinfo`, credentials, {
        withCredentials: true
      });
      await dispatch("getMagicinfoInfo", {
        username: rootState.user.credentials.username
      });
      await dispatch("getOrganizationId");
      await dispatch("isMagicinfoAutologin", {
        username: rootState.user.credentials.username
      });
      dispatch("startMagicinfoTokenTimer");
      if (state.autologining) {
        await dispatch("user/login", {
          username: rootState.user.credentials.username,
          password: rootState.user.credentials.password
        }, { root: true })
        router.push("/");
        commit("SET_AUTOLOGINING", false);
        commit("SET_TEMP_CREDENTIALS", null);
      }
      commit("SET_NEED_MAGICINFO_MFA", false);
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully logged in to MagicINFO."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      dispatch("alert/stopLoading", {}, { root: true });
      const res = error.response;
      const errorCode = res.data.errorCode;
      let errorMessage = res.data.errorMessage;
      if (errorCode === "401010") {
        commit("SET_NEED_MAGICINFO_MFA", true);
      } else {
        if (errorCode === "401013") {
          errorMessage = "Wrong credentials. Please try again."
        }
        dispatch("alert/getAlert", {
          alert: "danger",
          message: errorMessage
        }, { root: true });
        return false;
      }
    }
  },

  async refreshMagicinfoToken({ state, commit }) {
    try {
      const params = {
        token: `${decryptMagicinfoToken(state.magicinfoToken)}`,
        refreshToken: `${decryptMagicinfoToken(state.magicinfoRefreshToken)}`,
        grantType: "refresh_token"
      };
      const response = await axios.post(
        `${decryptMagicinfoServer(state.magicinfoServer)}/restapi/v2.0/auth`,
        params
      );
      const newEncryptedToken = encryptMagicinfoToken(response.data.token);
      const newEncryptedRefreshToken = encryptMagicinfoToken(
        response.data.refreshToken
      );
      window.$cookies.set("magicinfoToken", newEncryptedToken);
      window.$cookies.set("magicinfoRefreshToken", newEncryptedRefreshToken);
      await axios.put(`${process.env.VUE_APP_SERVER_URL}/api/projects/update-project-magicinfo-token`, {
        magicinfo_server: state.magicinfoServer,
        magicinfo_username: state.magicinfoUsername,
        new_magicinfo_token: newEncryptedToken
      });
      commit("SET_MAGICINFO_TOKEN", newEncryptedToken);
      commit("SET_MAGICINFO_REFRESH_TOKEN", newEncryptedRefreshToken);
      return "Successful";
    } catch (error) {
      console.log(error);
    }
  },

  async startMagicinfoTokenTimer({ commit, dispatch }) {
    commit(
      "SET_MAGICINFO_TOKEN_TIME_INTERVAL",
      setInterval(() => {
        dispatch("refreshMagicinfoToken");
      }, timer)
    );
  },

  async stopMagicinfoTokenTimer({ commit }) {
    clearInterval(state.magicinfoTokenTimeInterval);
    commit("SET_MAGICINFO_TOKEN_TIME_INTERVAL", null);
  },

  async getMagicinfoInfo({ commit, dispatch }, context) {
    try {
      let username;
      if (context) {
        username = context.username;
      }
      const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/magicinfo`, {
        params: {
          username: username
        },
        withCredentials: true
      });
      const obj = response.data[0];
      commit("SET_MAGICINFO_SERVER", obj["magicinfo_server"]);
      commit("SET_MAGICINFO_USERNAME", obj["magicinfo_username"]);
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Failed to fetch magicinfo credentials. Please try again later."
      }, { root: true });
    }
  },

  async isMagicinfoAutologin({ commit, dispatch }, context) {
    try {
      let username;
      if (context) {
        username = context.username;
      }
      const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/magicinfo/is-magicinfo-auto-login`, {
        params: {
          username: username
        },
        withCredentials: true
      });
      if (response.data.alert === "warning") {
        dispatch("alert/getAlert", {
          alert: response.data.alert,
          message: response.data.message
        }, { root: true });
        return;
      }
      commit("SET_AUTOLOGIN", !!response.data[0]["magicinfo_auto_login"]);
    } catch (error) {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "Failed to fetch magicinfo auto-login. Please try again later."
      }, { root: true });
    }
  },

  async checkAuthentication({ commit, dispatch }) {
    if (
      window.$cookies.get("magicinfoToken") &&
      window.$cookies.get("magicinfoRefreshToken")
    ) {
      await commit("SET_MAGICINFO_TOKEN", window.$cookies.get("magicinfoToken"));
      await commit(
        "SET_MAGICINFO_SERVER",
        window.$cookies.get("magicinfoServer")
      );
      await commit(
        "SET_MAGICINFO_REFRESH_TOKEN",
        window.$cookies.get("magicinfoRefreshToken")
      );
      await dispatch("getMagicinfoInfo");
      await commit("SET_AUTHENTICATED", true);
      await dispatch("isMagicinfoAutologin");
      await dispatch("getOrganizationId");
      dispatch("startMagicinfoTokenTimer");
      return true;
    }
    commit("SET_AUTHENTICATED", false);
    return false;
  },

  async getOrganizationId({ state, commit, dispatch }) {
    if (state.isAuthenticated) {
      try {
        const params = {
          groupType: "MY_CONTENT_GROUP"
        };
        const response = await axios.get(
          `${decryptMagicinfoServer(
            state.magicinfoServer
          )}/restapi/v2.0/cms/contents/groups`,
          {
            headers: {
              accept: "application/json",
              api_key: decryptMagicinfoToken(state.magicinfoToken)
            },
            params: params
          }
        );
        commit("SET_ORGANIZATION_ID", response.data.items[0].organizationId);
      } catch (error) {
        dispatch("alert/getAlert", {
          alert: "danger",
          message: "Failed to retrieve MagicINFO Organization ID."
        }, { root: true });
      }
    } else {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "You are not authenticated to retrieve MagicINFO Organization ID."
      }, { root: true });
    }
  },

  async updateMagicinfoAutoLogin({ dispatch }, context) {
    if (state.isAuthenticated) {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.put(`${process.env.VUE_APP_SERVER_URL}/api/magicinfo/edit-magicinfo-auto-login`, {
        newToggle: context.newToggle
      }, {
        withCredentials: true
      });
      dispatch("alert/getAlert", {
        alert: "success",
        message: "Successfully updated MagicINFO Auto Login."
      }, { root: true });
      dispatch("alert/stopLoading", {}, { root: true });
    } else {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "You are not authenticated to update MaigcINFO auto login."
      }, { root: true });
    }
  },

  async updateMagicinfo({ dispatch }, context) {
    if (state.isAuthenticated) {
      try {
        dispatch("alert/startLoading", {}, { root: true });
        await axios.put(`${process.env.VUE_APP_SERVER_URL}/api/magicinfo/update-magicinfo`, {
          server: context.server,
          username: context.username,
          autoLogin: context.autologin
        });
        dispatch("alert/stopLoading", {}, { root: true });
      } catch (error) {
        dispatch("alert/getAlert", {
          alert: "danger",
          message: "Something went wrong while updating MagicINFO. Please try again later."
        }, { root: true });
      }
    } else {
      dispatch("alert/getAlert", {
        alert: "danger",
        message: "You are not authenticated to update MaigcINFO."
      }, { root: true });
    }
  },

  clearCookies() {
    window.$cookies.remove("magicinfoToken");
    window.$cookies.remove("magicinfoRefreshToken");
    window.$cookies.remove("magicinfoServer");
  },

  disconnectMagicinfo({ commit, dispatch }) {
    dispatch("alert/startLoading", {}, { root: true });
    dispatch("clearCookies");
    // commit(SET_ALL_DISPLAYS, []);
    commit("SET_ORGANIZATION_ID", null);
    commit("SET_CONTENTTYPE", null);
    commit("SET_MAGICINFO_TOKEN", "");
    commit("SET_MAGICINFO_REFRESH_TOKEN", "");
    commit("SET_MAGICINFO_SERVER", null);
    commit("SET_AUTHENTICATED", false);
    commit("SET_MAGICINFO_USERNAME", null);
    commit("SET_NEED_MAGICINFO_MFA", false);
    // commit(SET_MAGICINFO_AUTO_LOGIN, null);
    dispatch("stopMagicinfoTokenTimer");
    dispatch("alert/stopLoading", {}, { root: true });
  },

  async uploadStockToMagicinfo({ state, commit, dispatch, rootState }) {
    if (window.$cookies.get("magicinfoToken")) {
      try {
        dispatch("alert/startLoading", {}, { root: true });
        const response = await fetch(state.magicinfoContentUrl);
        const fileBits = await response.blob();
        const file = new File(
          [fileBits],
          rootState.templates.currentVideo.name + "." + state.magicinfoContentType,
          {
            type: "." + state.magicinfoContentType
          }
        );
        const headers = {
          accept: "application/json",
          api_key: decryptMagicinfoToken(state.magicinfoToken),
          "Content-Type": "multipart/form-data"
        };
        const newForm = new FormData();
        newForm.append("groupId", state.magicinfoOrganizationId);
        newForm.append("contentType", state.magicinfoContentType);
        newForm.append("files", file);
        commit("SET_CONTENTTYPE", null);
        commit("SET_MAGICINFO_CONTENT_URL", null);
        await axios({
          method: "post",
          url: `${
            state.magicinfoServer
          }/restapi/v2.0/cms/contents/files`,
          data: newForm,
          headers: headers
        });
        dispatch("alert/getAlert", {
          alert: "success",
          message: "Successfully uploaded stock to MagicINFO."
        }, { root: true });
        dispatch("alert/stopLoading", {}, { root: true });
      } catch (error) {
        dispatch("alert/getAlert", {
          alert: "danger",
          message: "Failed to upload to MagicINFO. Please try again later."
        }, { root: true });
        dispatch("alert/stopLoading", {}, { root: true });
      }
    }
  }

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}