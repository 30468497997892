<template>
  <div class="container-fluid">
    <p>
      <b>
        <router-link to="/library#stock" @click="closeModal">Stock Videos:</router-link>
      </b> Instantly downloadable videos and/or images that are not customizable.
    </p>
    <p>
      <b>
        <router-link to="/library/" @click="closeModal">Custom Videos:</router-link>
      </b> Customizable videos created from CreativeEngine Templates. Users can change text, colors, logos, and more to make the videos their own.
    </p>
    <p>
      <b>
        <a
          href="https://youtu.be/4dDCjL6KM7A"
          target="_blank"
          @click="closeModal"
        >Bespoke Animations:</a>
      </b> Ads created by the CreativeGroup to your exact specifications and directions. These are not based on templates but usually from the user's artwork and/or direction.
    </p>
    <p>
      <b>Custom Resolutions:</b> Allows users to have multiple sizes of signs.
    </p>
    <p>
      <b>Scheduling:</b> Requires the CreativeGroup to have online remote access to your displays in order to push updates.
    </p>
    <button type="button" class="modified-button" id="close-button" @click="closeModal">Close</button>
  </div>
</template>

<script setup>
import { useStore } from "vuex";

const store = useStore();

const closeModal = () => {
  store.dispatch("modal/closeModal");
}

</script>

<style scoped lang="scss">
.container-fluid {
  text-align: left;
}
#close-button {
  float: right;
  width: 40%;
  margin: 1em 0;
  &:hover {
    background-color: var(--ce-cancel-button-color);
  }
}
</style>