import { jsPDF } from "jspdf";

const formatTabText = (text) => {
  let all_words = text.split('-');
  if (all_words.length === 1) {
    return all_words[0][0].toUpperCase() + all_words[0].slice(1);
  }
  let formatted = all_words.reduce((x, b) => {
    return (x[0].toUpperCase() + x.slice(1)) + " " + b[0].toUpperCase() + b.slice(1); 
  })
  return formatted;
}

function valueOrNull(value) {
  return value !== null ? value : "N/A";
}

const generatePDFReceipt = (receipt) => {
  let x = 10;
  let xr = 200;
  let y = 20;
  let headerSize = 14, titleSize = 10, fontSize = 8;
  let timeZoneName = "UTC";
  let transactionOrigin = "E-Commerce";
  let pdf = new jsPDF();
  pdf.setLineWidth(0.1);

  pdf.setFontSize(headerSize);
  pdf.setFont("courier", "normal");
  pdf.text(`Transaction ${valueOrNull(receipt.id)}`, x, y);

  y += 20;
  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(titleSize);
  pdf.text("Merchant information", x, y);
  y += 10;
  
  pdf.setFont("times", "normal");
  pdf.setFontSize(fontSize);
  pdf.line(x,y-3,xr,y-3);
  pdf.text("Merchant", x, y);
  pdf.text(`${valueOrNull(receipt.merchantAccountId)}`, xr, y, "right");

  y += 20;
  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(titleSize);
  pdf.text("Transaction Information", x, y);
  y += 10;
  
  pdf.setFont("times", "normal");
  pdf.setFontSize(fontSize);
  pdf.line(x,y-3,xr,y-3);
  pdf.text("Type", x, y);
  pdf.text(`${valueOrNull(receipt.type)}`, xr, y, "right");
  y += 5;
  pdf.line(x,y-3,xr,y-3);
  pdf.text("Amount", x, y);
  pdf.text(`$${receipt.amount} USD`, xr, y, "right");
  y += 5;
  pdf.line(x,y-3,xr,y-3);
  pdf.text("Transaction Date", x, y);
  pdf.text(new Date(receipt.createdAt).toLocaleString('en-US', {timeZone: timeZoneName}) + ` ${timeZoneName}`, xr, y, "right");
  y += 5;
  pdf.line(x,y-3,xr,y-3);
  pdf.text("Approval Code", x, y);
  pdf.text(`${valueOrNull(receipt.processorAuthorizationCode)}`, xr, y, "right");
  y += 5;
  pdf.line(x,y-3,xr,y-3);
  pdf.text("Status", x, y);
  pdf.text(`${valueOrNull(receipt.status)}`, xr, y, "right");

  y += 20;
  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(titleSize);
  pdf.text("Payment Information", x, y);
  y += 10;
  pdf.setFont("times", "normal");
  pdf.setFontSize(fontSize);
  pdf.line(x,y-3,xr,y-3);
  pdf.text("Payment Type", x, y);
  pdf.text(`${valueOrNull(receipt.paymentInstrumentType)}`, xr, y, "right");
  y += 5;
  pdf.line(x,y-3,xr,y-3);
  pdf.text("Transaction Origin", x, y);
  pdf.text(transactionOrigin, xr, y, "right");
  y += 5;
  pdf.line(x,y-3,xr,y-3);
  pdf.text("Card Type", x, y);
  pdf.text(`${valueOrNull(receipt.cardType)}`, xr, y, "right");
  y += 5;
  pdf.line(x,y-3,xr,y-3);
  pdf.text("Cardholder Name", x, y);
  pdf.text(`${valueOrNull(receipt.cardholderName)}`, xr, y, "right");
  y += 5;
  pdf.line(x,y-3,xr,y-3);
  pdf.text("Credit Card Number", x, y);
  pdf.text(`************${receipt.last4}`, xr, y, "right");

  y += 20;
  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(titleSize);
  pdf.text("Customer Information", x, y);
  y += 10;
  pdf.setFont("times", "normal");
  pdf.setFontSize(fontSize);
  pdf.line(x,y-3,xr,y-3);
  pdf.text("Email", x, y);
  pdf.text(`${valueOrNull(receipt.email)}`, xr, y, "right");
  y += 5;
  pdf.line(x,y-3,xr,y-3);
  pdf.text("Billing Address", x, y);
  pdf.text(`${valueOrNull(receipt.postalCode)}`, xr, y, "right");

  let image = new Image();
  image.src = require("@/assets/SamsungLogoBlue.jpg");
  pdf.addImage(image, "PNG", 165, 15, 30, 10);
  pdf.save("Receipt.pdf");
}

export default {
  formatTabText,
  generatePDFReceipt
}
