<template>
  <div class="container-fluid">
    <div id="message">
      {{ message }}
    </div>
    <div class="buttons">
      <button type="button" class="modified-button" id="close-button" @click="closeModal">
        OK
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";

const store = useStore();

const message = computed(() => store.getters["maintenance/getMaintenanceMessage"]);

// methods
const closeModal = () => {
  store.dispatch("modal/closeModal");
}

onMounted(() => {
  let msg = document.getElementById("message");
  msg.innerHTML = message.value;
})

</script>

<style scoped lang="scss">
#message {
  margin: 1em;
}
#close-button {
  width: 40%;
  margin: 1em;
  &:hover {
    background-color: var(--ce-cancel-button-color);
  }
}
</style>