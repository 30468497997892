import axios from "axios";

const state = {
  keywords: [],
  selectedKeyword: null
}

const getters = {
  getKeywords: state => {
    return state.keywords;
  },
  getKeywordNames: state => {
    return state.keywords.map(keyword => {
      return keyword.keyword;
    })
  },
  getKeywordIdsByNames: state => keywordNames => {
    let ids = [];
    for (let i=0; i<keywordNames.length; i++) {
      for (let j=0; j<state.keywords.length; j++) {
        if (keywordNames[i] === state.keywords[j].keyword) {
          ids.push(state.keywords[j].id);
          break;
        }
      }
    }
    return ids;
  },
  getKeywordsWithPublishedCount: (state, getters, rootState) => {
    let keywords = [...state.keywords];
    keywords.forEach(item => {
      item.published = (rootState.templates.keywordCount[item.keyword] || 0) + (rootState.stocks.keywordCount[item.keyword] || 0);
    });
    return keywords;
  },
  getSelectedKeyword: state => {
    return state.selectedKeyword;
  }
}

const mutations = {
  SET_KEYWORDS(state, keywords) {
    state.keywords = keywords;
  },
  SET_SELECTED_KEYWORD(state, keyword) {
    state.selectedKeyword = keyword;
  }
}

const actions = {
  async getKeywords({ commit, dispatch }) {
    dispatch("alert/startLoading", {}, { root: true });
    const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/api/media/fetch-all-keywords`);
    commit("SET_KEYWORDS", response.data.keywords);
    dispatch("alert/stopLoading", {}, { root: true });
  },
  async addKeyword({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.post(`${process.env.VUE_APP_SERVER_URL}/api/admin/add-new-category-or-keyword`, {
        newKeyword: context.newKeyword
      }, {
        withCredentials: true
      });
      await dispatch("getKeywords");
      dispatch("alert/getAlert", {
        alert: "success",
        message: `Successfully added keyword '${context.newKeyword}'`
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("alert/getAlert", {
        alert: "danger",
        message: `Something went wrong. Please try again later.`
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async updateKeyword({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      await axios.post(`${process.env.VUE_APP_SERVER_URL}/api/media/edit-keyword`, {
        id: context.id,
        keyword: context.newKeyword
      }, {
        withCredentials: true
      })
      await dispatch("templates/getTemplates", {}, { root: true });
      await dispatch("stocks/getStocks", {}, { root: true });
      await dispatch("getKeywords");
      dispatch("alert/getAlert", {
        alert: "success",
        message: `Successfully udpated keyword to '${context.newKeyword}'`
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("alert/getAlert", {
        alert: "danger",
        message: `Something went wrong. Please try again later.`
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  },
  async deleteKeyword({ dispatch }, context) {
    try {
      dispatch("alert/startLoading", {}, { root: true });
      const response = await axios.post(`${process.env.VUE_APP_SERVER_URL}/api/media/delete-keyword`, {
        id: context.id
      }, {
        withCredentials: true
      });
      if (response.data === "deleted") {
        await dispatch("getKeywords");
      }
      dispatch("alert/getAlert", {
        alert: "success",
        message: `Successfully deleted`
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("alert/getAlert", {
        alert: "danger",
        message: `Something went wrong. Please try again later.`
      }, { root: true })
      dispatch("alert/stopLoading", {}, { root: true });
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}